export const uploadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0029 10.543V17.0878"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1044 15.1899C16.8137 14.8032 17.374 14.1914 17.6969 13.4509C18.0198 12.7104 18.0869 11.8835 17.8877 11.1006C17.6884 10.3177 17.2341 9.62352 16.5965 9.12752C15.9588 8.63152 15.1742 8.36198 14.3664 8.36145H13.4501C13.23 7.51006 12.8197 6.71966 12.2502 6.04965C11.6806 5.37965 10.9666 4.84748 10.1617 4.49315C9.35691 4.13883 8.48222 3.97157 7.60344 4.00395C6.72467 4.03632 5.86466 4.2675 5.08808 4.68009C4.31151 5.09268 3.63857 5.67595 3.11986 6.38605C2.60115 7.09615 2.25017 7.9146 2.09331 8.77988C1.93644 9.64515 1.97777 10.5347 2.21419 11.3817C2.45061 12.2287 2.87597 13.0111 3.45829 13.67"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.9124 12.9088L10.0035 10L7.09473 12.9088"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
