import { useHistory } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import feature from '../../../../assets/images/feature.png';
import { cardTypeIconHelper } from '../../../../helpers/cardTypeIconHelper';
import Button from '../../../../components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentTwoTone } from '@mui/icons-material';
import PaymentDetails from '../../../auth/payment/paymentDetails/PaymentDetails';
import { useEffect, useMemo } from 'react';
import {
  getPaymentDetailActionInBilling,
  getPaymentStatusActionInBilling,
  resetPaymentDetailAction,
} from '../../../../store/actions/accountSettingsActions/billingOverviewActions';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import Loader from '../../../../components/loader/Loader';
import { currencyConversion } from '../../../../helpers/currencyConversionHelper';
import { getCurrencyRateAction } from '../../../../store/actions/commonAction';
import { startGeneralLoaderOnRequest } from '../../../../store/actions/generalLoaderAction';

const PlanPaymentDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const { paymentDetail, planUpgradeDetails } = useSelector(
    ({ billingOverviewReducer }: any) => billingOverviewReducer ?? {}
  );

  const { currencyRate } = useSelector(
    ({ commonReducer }: any) => commonReducer ?? {}
  );

  const { AUD } = useMemo(() => currencyRate ?? {}, [currencyRate]);

  const { getUpgradPlanPaymentDetailsLoaderAction, payNowLoaderAction } =
    useSelector(({ generalLoaderReducer }: any) => generalLoaderReducer ?? {});

  const { cardDetails, planDetails } = useMemo(
    () => paymentDetail ?? {},
    [paymentDetail]
  );

  const { planId, cardId } = useMemo(
    () => planUpgradeDetails ?? {},
    [planUpgradeDetails]
  );

  const onPayNow = () => {
    if (!payNowLoaderAction) {
      dispatch(getPaymentStatusActionInBilling({ planId, cardId }, history));
    }
  };

  const totalCalculation = () => {
    const price = parseFloat(currencyConversion(planDetails?.price, AUD));
    const gstAmount = parseFloat(
      currencyConversion(planDetails?.gstAmount, AUD)
    );
    const discount = parseFloat(
      currencyConversion(planDetails?.planDiscount, AUD)
    );

    const sum = planDetails?.planDiscount
      ? price + gstAmount - discount
      : price + gstAmount;
    return sum.toFixed(2);
  };

  useEffect(() => {
    dispatch(getCurrencyRateAction());
    dispatch(getPaymentDetailActionInBilling({ cardId, planId }));
    return dispatch(resetPaymentDetailAction());
  }, []);

  return (
    <div className="view-page-container payment-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.CARD_LIST)}
          />
          <div className="form-title">Payment Details</div>
        </div>
      </div>
      {getUpgradPlanPaymentDetailsLoaderAction ? (
        <Loader />
      ) : (
        <div className="payment-details">
          <div className="payment-plan-header payment-plan">
            <div className="payment-plan-title">{planDetails?.name}</div>
            <div className="payment-plan-price">
              ${totalCalculation()}/{planDetails?.planType}
            </div>
          </div>

          <div className="plan-feature">
            <div>
              <div className="payment-features">
                {planDetails?.facilities?.map((name: string) => (
                  <div className="payment-features-list">
                    <img src={feature} alt="feature-icon" />
                    <div className="features-list-name">{name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="payment-card">
              <div>{cardTypeIconHelper(cardDetails?.cardType)}</div>
              <div>{cardDetails?.cardName}</div>
              <div>{`**** **** **** ${cardDetails?.cardNumber}`}</div>
              <div>{`Validity : ${cardDetails?.expiryDate.month}/${cardDetails?.expiryDate.year}`}</div>
            </div>
          </div>

          <div className="invoices-total-container payment-total-container">
            <div className="total-container">
              <div className="sub-total-container">
                <div>
                  <span>Plan Amount</span>
                  <span className="invoice-total-value">{`$${
                    AUD && currencyConversion(planDetails?.price, AUD)
                  }`}</span>
                </div>
                <div>
                  <span>GST</span>
                  <span className="invoice-total-value">{`$${
                    AUD && currencyConversion(planDetails?.gstAmount, AUD)
                  }`}</span>
                </div>
                {planDetails?.planDiscount && (
                  <div>
                    <span>Plan Discount</span>
                    <span className="invoice-total-value">{`$${currencyConversion(
                      planDetails?.planDiscount,
                      AUD
                    )}`}</span>
                  </div>
                )}
              </div>
              <div>
                <span>Total</span>
                <span className="invoice-total-value">{`$${totalCalculation()}/ ${
                  planDetails?.planType
                }`}</span>
              </div>
            </div>
          </div>
          <div className="pay-now-button-container">
            <Button
              className="auth-button plan-confirm-button"
              variant="contained"
              color="primary"
              loader={payNowLoaderAction}
              onClick={onPayNow}
            >
              Pay now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanPaymentDetails;
