import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes/Routes';
import { store, persistStoreData } from './store/redux/store';

const App = () => {
  const [isImageLoading, setIsImageLoading] = useState(false);

  setTimeout(() => setIsImageLoading(true), 100);
  const stripe_key: string = process.env.REACT_APP_STRIPE_KEY || '';
  const stripePromise = loadStripe(stripe_key);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistStoreData}>
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>
      </PersistGate>
    </Provider>
  );
};

export default App;
