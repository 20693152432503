import { Add, DeleteRounded } from '@mui/icons-material';
import { Radio } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../components/button/Button';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import Input from '../../../components/input/Input';
import Loader from '../../../components/loader/Loader';
import { errorNotification } from '../../../components/notifyHelper';
import { color_palette } from '../../../constants/colorPalette';
import { EMAIL_ADDRESS_REGEX } from '../../../constants/regexConstants';
import { cardTypeIconHelper } from '../../../helpers/cardTypeIconHelper';
import { dateCalculationHelper } from '../../../helpers/dateCalculationHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  cancelCurrentPlanAction,
  changeDefalutCardAction,
  deleteCardAction,
  getBillingOverviewDetailsAction,
  onChangeAccountInfoAction,
  onChangePaymentInfoAction,
  updateAccountInfoAction,
} from '../../../store/actions/accountSettingsActions/billingOverviewActions';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import {
  accountInfoType,
  cardType,
} from '../../../store/reducers/accountSettingsReducer/billingOverviewReducer';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

const BillingOverview = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const [editDetails, setEditDetails] = useState<boolean>(false);
  const [card, setCard] = useState<cardType>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCancelPlanModal, setOpenCancelPlanModal] = useState(false);
  const [defaultCardId, setDefaultcardId] = useState('');
  const [selectedCardId, setselectedCardId] = useState('');

  const { accountInfo, paymentInfo, subscriptionInfo } = useSelector(
    ({ billingOverviewReducer }: Record<string, any>) =>
      billingOverviewReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const {
    cancelCurrentPlanLoaderAction,
    updateAccountInfoLoaderAction,
    billingOverviewDetailsLoaderAction,
    deleteCardLoaderAction,
    changeDefaultcardLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, Record<string, boolean>>) =>
      generalLoaderReducer ?? {}
  );

  const date = dateCalculationHelper(
    subscriptionInfo?.endDate,
    profileDetail?.timeZoneOffSet
  );
  const billingDate = dayjs(date).format('ddd MMM DD, YYYY');

  let validated = true;
  const errors = accountInfo?.errors || {};

  let errorMessages: Record<string, string> = {
    accountHolderName: 'enter account holder name',
    address: 'enter billing address',
    email: 'enter email',
  };

  const checkValidation = (field: string, value: string) => {
    switch (field) {
      case 'email':
        if (
          !EMAIL_ADDRESS_REGEX.test(value) ||
          !value?.toString()?.trim()?.length
        ) {
          validated = false;
          errors.email = `Please ${errorMessages[field]}`;
        } else {
          delete errors.email;
        }
        break;

      case 'address':
      case 'accountHolderName':
        if (!value?.toString()?.trim()?.length) {
          validated = false;
          errors[field] = `Please ${errorMessages[field]}`;
        } else {
          delete errors[field];
        }
        break;
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(
      onChangeAccountInfoAction(
        name,
        name === 'email' ? value.toLocaleLowerCase() : value
      )
    );
    checkValidation(name, value);
    dispatch(onChangeAccountInfoAction('errors', errors));
  };

  const onBlur = (field: string) => {
    checkValidation(field, accountInfo[field]);
    dispatch(onChangeAccountInfoAction('errors', errors));
  };

  const onClickSaveOrEdit = async () => {
    if (editDetails) {
      for (const key in accountInfo) {
        const value = accountInfo[key];
        checkValidation(key, value);
      }
      dispatch(onChangeAccountInfoAction('errors', errors));
      if (validated) {
        const { accountHolderName, address, email } = accountInfo;
        const finalData: accountInfoType = {
          accountHolderName,
          address,
          email,
        };
        const success = await dispatch(updateAccountInfoAction(finalData));
        if (success) {
          setEditDetails(!editDetails);
          dispatch(getBillingOverviewDetailsAction());
        }
      }
    } else {
      setEditDetails(!editDetails);
    }
  };

  const accountInfoCancel = () => {
    setEditDetails(!editDetails);
    dispatch(getBillingOverviewDetailsAction());
  };

  const onSaveChanges = async () => {
    const cardsInfo = paymentInfo.filter((card: cardType) => card.isSelected);
    const success = await dispatch(changeDefalutCardAction(cardsInfo?.[0]._id));
    if (success) dispatch(getBillingOverviewDetailsAction());
  };

  const onDeleteCard = async () => {
    if (card) {
      const success = await dispatch(deleteCardAction(card._id));
      if (success) {
        setOpenDeleteModal(false);
        dispatch(getBillingOverviewDetailsAction());
      }
    }
  };

  const onSelectCard = (cardId: string) => {
    setselectedCardId(cardId);
    const updatedPaymentInfo = paymentInfo.map((card: cardType) =>
      card?._id === cardId
        ? { ...card, isSelected: true }
        : { ...card, isSelected: false }
    );
    dispatch(onChangePaymentInfoAction(updatedPaymentInfo));
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => handleClose(),
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onDeleteCard,
      color: 'primary',
      loader: deleteCardLoaderAction,
    },
  ];

  const cancelPlanModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => setOpenCancelPlanModal(false),
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: () =>
        dispatch(cancelCurrentPlanAction(afterSuccessfulCancellation)),
      color: 'primary',
      loader: cancelCurrentPlanLoaderAction,
    },
  ];

  const handelDelete = (card: cardType) => {
    if (defaultCardId !== selectedCardId) {
      errorNotification(
        'Please save or discard your changes before deleting any card'
      );
      return;
    }
    setCard(card);
    setOpenDeleteModal(true);
  };

  const afterSuccessfulCancellation = () => {
    setOpenCancelPlanModal(false);
    dispatch(getBillingOverviewDetailsAction());
  };

  const onCancelPlan = async () => {
    setOpenCancelPlanModal(true);
  };

  const onCancel = () => {
    dispatch(getBillingOverviewDetailsAction());
  };

  useEffect(() => {
    const card = paymentInfo?.filter((card: cardType) => card.isSelected);
    setDefaultcardId(card?.[0]?._id);
    setselectedCardId(card?.[0]?._id);
  }, [subscriptionInfo]);

  useEffect(() => {
    dispatch(getBillingOverviewDetailsAction());
    dispatch(resetfilterOnModuleChange('billingOverview'));
  }, []);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="page-title">Billing Overview</div>
      </div>
      {billingOverviewDetailsLoaderAction ? (
        <Loader />
      ) : (
        <>
          <div className="main-details-container ">
            <div className="details-block-title">Subscription Info</div>
            <div className="view-details-white-container billing-overview-detail-block">
              <div>
                <div className="detail-label">Current Plan</div>
                <div className="detail-value break-word">
                  {subscriptionInfo?.planId?.name}
                </div>
              </div>
              <div>
                <div className="detail-label">New Billing Date</div>
                <div className="detail-value">{`${billingDate}`}</div>
              </div>

              <div className="subscriptionInfo-buttons">
                {!subscriptionInfo?.isPlanCancelled && (
                  <Button
                    color="primary"
                    variant="outlined"
                    className="outlined-primary-button"
                    onClick={() => onCancelPlan()}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="outlined"
                  className="outlined-primary-button"
                  onClick={() =>
                    history.push(ROUTE_CONSTANTS_VARIABLE.CHANGE_PLAN)
                  }
                >
                  Upgrade
                </Button>
              </div>
            </div>
          </div>
          <div className="main-details-container ">
            <div className="details-block-title">Account Info</div>
            <div className="view-details-white-container billing-overview-detail-block">
              <div className="account-details">
                <div>
                  <div
                    className={` ${
                      editDetails ? 'form-field-name' : 'detail-label'
                    }`}
                  >
                    Account Holder Name
                  </div>
                  {editDetails ? (
                    <Input
                      variant="filled"
                      placeholder="Enter Account Holder Name"
                      className={`input-field ${
                        accountInfo?.errors?.accountHolderName
                          ? 'input-with-error'
                          : ''
                      }`}
                      value={accountInfo?.accountHolderName}
                      name="accountHolderName"
                      type="text"
                      helperText={accountInfo?.errors?.accountHolderName}
                      onChange={onChangeInput}
                      onBlur={() => onBlur('accountHolderName')}
                      onKeyUp={(event) =>
                        enterKeyHelper(event, () => onClickSaveOrEdit())
                      }
                    />
                  ) : (
                    <div className="detail-value">
                      {accountInfo?.accountHolderName}
                    </div>
                  )}
                </div>
                <div>
                  <div
                    className={` ${
                      editDetails ? 'form-field-name' : 'detail-label'
                    }`}
                  >
                    Billing Address
                  </div>
                  {editDetails ? (
                    <Input
                      variant="filled"
                      placeholder="Enter Billing Address"
                      className={`input-field ${
                        accountInfo?.errors?.address ? 'input-with-error' : ''
                      }`}
                      name="address"
                      type="text"
                      value={accountInfo?.address}
                      helperText={accountInfo?.errors?.address}
                      onChange={onChangeInput}
                      onBlur={() => onBlur('address')}
                      onKeyUp={(event) =>
                        enterKeyHelper(event, () => onClickSaveOrEdit())
                      }
                    />
                  ) : (
                    <div className="detail-value">{accountInfo?.address}</div>
                  )}
                </div>
                <div>
                  <div
                    className={` ${
                      editDetails ? 'form-field-name' : 'detail-label'
                    }`}
                  >
                    Invoices will be mailed to
                  </div>
                  {editDetails ? (
                    <Input
                      variant="filled"
                      placeholder="Enter Email"
                      className={`input-field ${
                        accountInfo?.errors?.email ? 'input-with-error' : ''
                      }`}
                      name="email"
                      type="text"
                      value={accountInfo?.email}
                      helperText={accountInfo?.errors?.email}
                      onChange={onChangeInput}
                      onBlur={() => onBlur('email')}
                      onKeyUp={(event) =>
                        enterKeyHelper(event, () => onClickSaveOrEdit())
                      }
                    />
                  ) : (
                    <div className="detail-value">{accountInfo?.email}</div>
                  )}
                </div>
              </div>
              <div className="accountInfo-buttons">
                <Button
                  color="primary"
                  variant="outlined"
                  className="outlined-primary-button"
                  loader={updateAccountInfoLoaderAction}
                  onClick={onClickSaveOrEdit}
                >
                  {editDetails ? 'Save' : 'Edit Details'}
                </Button>
                {editDetails ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    className="outlined-primary-button"
                    onClick={accountInfoCancel}
                  >
                    Cancel
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="main-details-container">
            <div className="paymentInfo-title-row">
              <div className="details-block-title">Payment Info</div>
              <Button
                className="desktop-button"
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(ROUTE_CONSTANTS_VARIABLE.ADD_NEW_CARD)
                }
              >
                Add New Card
              </Button>
              <div className="mobile-button-container">
                <Button
                  className="form-icon-button"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    history.push(ROUTE_CONSTANTS_VARIABLE.ADD_NEW_CARD)
                  }
                >
                  <Add />
                </Button>
              </div>
            </div>
            <div className="payment-row-container">
              {paymentInfo?.length > 0 &&
                paymentInfo?.map((info: cardType) => (
                  <div className="payment-info-row">
                    <div>
                      <Radio
                        id={info._id}
                        onChange={() => onSelectCard(info._id)}
                        value={info._id}
                        checked={info.isSelected}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                        sx={{
                          color: color_palette.primary,
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                          },
                          '&.Mui-checked': {
                            color: color_palette.primary,
                          },
                        }}
                      />
                      {cardTypeIconHelper(info?.cardType)}
                    </div>
                    <span>{info?.cardName}</span>
                    <span>{`**** **** **** ${info?.cardNumber}`}</span>
                    <span>{`Validity : ${info?.expiryDate?.month}/${info?.expiryDate?.year}`}</span>

                    <span className="action-cell">
                      {!info.isSelected && (
                        <DeleteRounded onClick={() => handelDelete(info)} />
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div className="form-button-row">
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            {defaultCardId !== selectedCardId && (
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveChanges}
                loader={changeDefaultcardLoaderAction}
              >
                Save Changes
              </Button>
            )}
          </div>
        </>
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Card"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
      {openCancelPlanModal && (
        <CustomModal
          className="delete-modal"
          header="Cancel Current Plan"
          buttons={cancelPlanModalButton}
          hideModal={() => setOpenCancelPlanModal(false)}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            are you sure you want to cancel your plan?
          </span>
        </CustomModal>
      )}
    </div>
  );
};
export default BillingOverview;
