import { Api } from '@mui/icons-material';
import { DASHBOARD_URL } from '../../constants/urlConstants';
import ApiService from '../apiService/apiService';

export const dashboardApiServices = {
  getPurchasePendingInvoices: () =>
    ApiService.getData(
      DASHBOARD_URL.PURCHASE_CHART_URLS.GET_PURCHASE_PENDING_INVOICE_LIST
    ),
  getPurchaseFoodAndBeveragesDetails: (params?: {
    category: string | undefined;
  }) =>
    ApiService.getData(
      DASHBOARD_URL.PURCHASE_CHART_URLS.GET_PURCHASE_FOOD_AND_BEVERAGES_DETAILS,
      { params }
    ),
  getPurchaseFoodAndBeveragesChart: (params?: {
    category: string | undefined;
  }) =>
    ApiService.getData(
      DASHBOARD_URL.PURCHASE_CHART_URLS.GET_PURCHASE_FOOD_AND_BEVERAGES_CHART,
      { params }
    ),
  getPurchaseTopTenOrders: (params: {
    category: string | undefined;
    duration: string;
  }) =>
    ApiService.getData(
      DASHBOARD_URL.PURCHASE_CHART_URLS.GET_PURCHASE_TOP_TEN_ORDERS,
      { params }
    ),
  getPurchaseTopTenSuppliers: (params: {
    duration: string;
    category: string | undefined;
  }) =>
    ApiService.getData(
      DASHBOARD_URL.PURCHASE_CHART_URLS.GET_PURCHASE_TOP_SUPPLIERS_DETAILS,
      { params }
    ),
  getPurchasePriceChangeDetails: (params: { category: string | undefined }) =>
    ApiService.getData(
      DASHBOARD_URL.PURCHASE_CHART_URLS
        .GET_PURCHASE_RECENT_PRICE_CHANGE_DETAILS,
      { params }
    ),
  getSaleFoodAndBeveragesDetails : (params?: Record<string,any>) => 
  ApiService.getData(
    DASHBOARD_URL.SALES_CHART_URLS.GET_SALE_FOOD_AND_BEVERAGES_DETAIL,{params}
  ),
  getSaleFoodAndBeveragesChart : (params?: Record<string,any>) => 
  ApiService.getData(
    DASHBOARD_URL.SALES_CHART_URLS.GET_SALE_FOOD_AND_BEVERAES_CHART,{params}
  ),
  getSaleSpendPerHeadDetails : (params?:Record<string,any>) => 
  ApiService.getData(
    DASHBOARD_URL.SALES_CHART_URLS.GET_SPEND_PER_HEAD_DETAIL,{params}
  ),
  getDailySaleDetails : (params?: Record<string,any>) => 
  ApiService.getData(
    DASHBOARD_URL.SALES_CHART_URLS.GET_DAILY_SALE_DETAIL,{params}
  )  
};
