import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';
import { NOTIFICATION_REDUX_CONSTANTS } from '../reduxConstants/notificationReduxConstants';

interface notificationProps {
  notificationList: Record<string, string>[];
}

const initialNotificationReducer: notificationProps = {
  notificationList: [],
};

export const notificationReducer = (
  state = initialNotificationReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case NOTIFICATION_REDUX_CONSTANTS.GET_ALL_NITIFICATION_LIST:
      return { ...state, notificationList: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialNotificationReducer,
      };

    default:
      return state;
  }
};
