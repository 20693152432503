import { Delete } from '@mui/icons-material';
import { errorNotification } from '../../../components/notifyHelper';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { filterIcon } from '../../../assets/images/filter';
import Button from '../../../components/button/Button';
import FilterModal from '../../../components/filterModal/FilterModal';
import CustomMenu from '../../../components/menu/Menu';
import {
  exportCreditNoteListExcelSheet,
  exportCreditNoteListPdf,
  getCreditNoteListAction,
  getInvoiceDropDownList,
  getModifiedUploadedCreditNoteAction,
  getUploadedCreditNoteResponse,
  modifyUploadedCreditNoteAction,
  getSupplierListInCreditnote,
} from '../../../store/actions/invoiceActions/creditNotesActions';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import Loader from '../../../components/loader/Loader';
import CustomTable from '../../../components/customTable/CustomTable';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import { requestCredit } from '../../../assets/images/requesCredit';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import { Autocomplete } from '@mui/material';
import Input from '../../../components/input/Input';
import { useHistory } from 'react-router';
import { INVOICE_URLS } from '../../../constants/urlConstants';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import _ from 'lodash';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';


const CreditNotes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState<SetStateAction<any>>(null);

  const {
    creditNotesList,
    invoiceDropdownList,
    uploadedCreditNoteDetails,
    supplierList,
  } = useSelector(
    ({ creditNotesReducer }: Record<string, any>) => creditNotesReducer ?? {}
  );
  const { creditNotesListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );
  const { creditNotes } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => creditNotesList ?? {},
    [creditNotesList]
  );

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const mobileHeaders = ['creditId', 'supplier'];

  const exportToExcel = () => {
    dispatch(exportCreditNoteListExcelSheet({ ...filters }));
  };

  const exportToPdf = () => {
    dispatch(exportCreditNoteListPdf({ ...filters, userDate: new Date() }));
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
  ];

  const filterFields = [
    {
      name: 'supplierId',
      label: 'Supplier',
      placeholder: 'Select Supplier',
      type: 'autocomplete',
      options: supplierList,
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Select status',
      type: 'autocomplete',
      options: ['Pending', 'Approved', 'Rejected'],
      className: 'filter-field-gap',
    },
  ];

  const onPageChange = (page: any) => {
    dispatch(getCreditNoteListAction({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getCreditNoteListAction({ page: 1, limit: value, ...filters }));
  };

  const afterFileUploadSuccessfully = () => {
    uploadedCreditNoteDetails?.creditId
      ? history.push('/credit-note/generateCreditNote')
      : history.push(ROUTE_CONSTANTS_VARIABLE.CREDIT_NOTE);
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getCreditNoteListAction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'creditNotes',
      () => setOpenFilterModal(false),
      [getSupplierListInCreditnote]
    );
  };

  const filters = {
    supplierId: creditNotes?.supplierId?._id,
    status: creditNotes?.status,
  };

  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: creditNotes?.limit ?? limit ?? paramLimit ?? 15,
    supplierId: creditNotes?.supplierId?._id,
    status: creditNotes?.status,
  });

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getSupplierListInCreditnote());
    dispatch(getCreditNoteListAction({ ...params }));
    dispatch(resetfilterOnModuleChange('creditNotes'));
    dispatch(getInvoiceDropDownList());
  }, []);

  const invoiceModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenInvoiceModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: () => {
        if (invoiceData?._id) {
          history.push(`/invoices/generateCreditNote/${invoiceData?._id}`);
        } else {
          errorNotification('Please Select Invoice Number');
        }
      },
      color: 'primary',
    },
  ];

  const editCreditNote = (creditNote: Record<string, any>) => {
    history.push(`/credit-note/editCreditNote/${creditNote?._id}`);
  };

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Credit Notes</div>
        <div className="button-container">
          {!_.isEmpty(creditNotes) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(creditNotes) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}

          {/* Mobile Buttons end here */}
          <Button
            variant="contained"
            className="desktop-button"
            color="primary"
            onClick={() => setOpenInvoiceModal((e) => !e)}
          >
            Request Credit
          </Button>
          {/* mobile button starts here */}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => setOpenInvoiceModal((e) => !e)}
            >
              {requestCredit}
            </Button>
          </div>
          {/* mobile button ends here */}
          <Button
            className="list-icon-button"
            color="primary"
            variant="outlined"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(creditNotes)}
            filterData={creditNotes}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="credit-notes-action"
          />
        </div>
      </div>
      {creditNotesListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={mobileHeaders}
          data={docs}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          isAction
          isDeleteAction={false}
          editTableRow={editCreditNote}
          viewRecord={(creditNote: Record<string, any>) => {
            history.push(`/credit-note/view/${creditNote._id}`);
          }}
          primaryTextField="creditId"
          listName="creditNote"
        />
      )}
      {openFilterModal && (
        <FilterModal
          requestFrom="creditNotes"
          defaultParams={{ page: 1, limit: limit ?? paramLimit ?? 15 }}
          closeModal={() => setOpenFilterModal(false)}
          filterFields={filterFields}
          updateListAction={getCreditNoteListAction}
          filterAction={[getSupplierListInCreditnote]}
        />
      )}

      {openInvoiceModal && (
        <CustomModal
          header="Select Invoice"
          buttons={invoiceModalButton}
          className=" confirmation-modal Invoice-modal"
          headerClassName="Invoice-modal-header"
          bodyClassName="Invoice-modal-body"
          footerClassName="Invoice-modal-footer"
          hideModal={() => setOpenInvoiceModal(false)}
        >
          <span>Select Invoice Number</span>
          <Autocomplete
            key={'invoice'}
            className="autocomplete"
            options={invoiceDropdownList}
            getOptionLabel={(option: Record<string, string>) =>
              option?.invoiceNumber ?? option ?? ''
            }
            value={invoiceData}
            onChange={(event, value) => setInvoiceData(value)}
            renderInput={(params) => (
              <Input
                variant="filled"
                {...params}
                placeholder="select Invoice number"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default CreditNotes;
