import _ from 'lodash';
import { successNotification } from '../../../components/notifyHelper';
import {
  FLOAT_NUMBER_REGEX,
  NUMBER_REGEX,
} from '../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  addRecipe,
  onChangeRecipeData,
  saveAsDraftAction,
  updateDtaftRecipeAction,
  updateSelectedRecipeDetails,
} from '../../../store/actions/recipeActions';

export const addRecipeValidations = async (
  dispatch: any,
  recipeDetail: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  action: string,
  setIsSubmittedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>,
  history: any
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    recipeCategoryId: 'select category',
    supplierId: 'select supplier',
    name: 'enter recipe name',
    products: 'select Products',
    numberOfPortions: 'enter number of portions',
    foodCostPercentage: 'enter food cost percentage',
    coverPhotoURL: 'choose cover photo',
    instructions: 'enter recipe instructions',
  };

  const mandatoryFields = [
    'recipeCategoryId',
    'name',
    'products',
    'numberOfPortions',
    'foodCostPercentage',
    'coverPhotoURL',
    'instructions',
  ];

  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'recipeCategoryId':
      case 'name':
      case 'coverPhotoURL':
      case 'instructions':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null ||
          _.isEmpty(value)
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;

      case 'products':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else if (value?.toString()?.trim()?.length) {
          delete errors[field];
          const finalProducts = value.map((item: Record<string, any>) =>
            item.weight === ''
              ? { ...item, isError: true }
              : { ...item, isError: false }
          );

          const error = finalProducts.filter(
            (item: Record<string, any>) => item.isError
          );
          error.length > 0 && (validated = false);

          dispatch(onChangeRecipeData('products', finalProducts));
        }
        break;

      case 'numberOfPortions':
        if (!FLOAT_NUMBER_REGEX.test(value)) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
      case 'foodCostPercentage':
        if (!NUMBER_REGEX.test(value)) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
    }
  };

  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key of mandatoryFields) {
        const value = recipeDetail[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, recipeDetail[fieldName]);
    }
    dispatch(onChangeRecipeData('errors', errors));
  }

  if (validated && (requestFrom === 'onSubmit' || requestFrom === 'onDraft')) {
    const finalData = { ...recipeDetail };
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'recipeCategoryId':
            finalData[key] = finalData[key]._id;
            break;

          case 'products':
          case 'additionalPhotoURLs':
            finalData[key] = finalData[key];
            break;

          case 'selectedRecipeIds':
            finalData[key] = finalData[key].map((recipe: any) => recipe._id);
            break;

          case 'foodCostPercentage':
          case 'recipeCost':
          case 'portionCost':
          case 'portionCostPercentage':
          case 'profitGrossMargin':
          case 'salePrice':
          case 'numberOfPortions':
            finalData[key] = parseFloat(finalData[key] ?? 0);
            break;

          case 'status':
            finalData[key] = action === 'draft' ? 'Draft' : 'Published';
            break;

          default:
            finalData[key] = finalData[key]?.toString()?.trim();
            break;
        }
      }
    }
    delete recipeDetail?.errors;
    if (action === 'add') {
      const isAddedSuccessFully = await dispatch(addRecipe(finalData));

      if (isAddedSuccessFully) {
        setIsSubmittedSuccessfully(true);
      }
    } else if (action === 'draft') {
      const isAddedSuccessFully = await dispatch(saveAsDraftAction(finalData));

      if (isAddedSuccessFully) {
        successNotification('Recipe Added Successfully');
        history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
      }
    } else if (action === 'editDraft') {
      const updateUrl = () => history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
      dispatch(updateDtaftRecipeAction(finalData, updateUrl));
    } else {
      const updateUrl = () => history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
      dispatch(
        updateSelectedRecipeDetails(finalData, recipeDetail._id, updateUrl)
      );
    }
  }
};
