import { Delete } from '@mui/icons-material';
import { FormControl } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import CustomTable from '../../../../components/customTable/CustomTable';
import Input from '../../../../components/input/Input';
import Loader from '../../../../components/loader/Loader';
import { errorNotification } from '../../../../components/notifyHelper';
import {
  invoiceTableProductsHeaderConstants,
  invoiceTableProductsMobileHeader,
} from '../../../../constants/invoiceconstants/invoiceConstants';
import { audConverter } from '../../../../constants/regexConstants';
import { dateCalculationHelper } from '../../../../helpers/dateCalculationHelper';
import {
  changeCreditNoteStatus,
  cancelCreditNote,
  generateCreditAction,
  getCreditNoteDetails,
  onChangeCreditNoteData,
  resetCreditNoteData,
  uploadPhotoForCreditAction,
  editCreditNoteAction,
  saveCreditNoteAction,
} from '../../../../store/actions/invoiceActions/creditNotesActions';
import {
  getInvoiceDetailsByIdAction,
  getInvoiceDetailsByOrderId,
  resetInvoiceDetails,
} from '../../../../store/actions/invoiceActions/invoiceActions';
import dayjs from 'dayjs';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

const ViewCreditNote = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { invoiceId, orderId, action, creditId } =
    useParams<Record<string, string>>();
  const [checkedIds, setCheckedIds] = useState<Array<string>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const { creditNoteDetail, viewCreditNoteDetails, uploadedCreditNoteDetails } =
    useSelector(
      ({ creditNotesReducer }: Record<string, any>) => creditNotesReducer ?? {}
    );

  const { invoiceDetails, updateStatus } = useSelector(
    ({ invoiceReducer }: Record<string, any>) => invoiceReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const {
    invoiceDetailsLoaderAction,
    invoiceDetailsFromOrderLoaderAction,
    getCreditNoteDetailsLoaderAction,
    generateCreditNoteLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const fileExtension = ['jpeg', 'jpg', 'png'];
  const mimeType = ['image/jpeg', 'image/jpg', 'image/png'];

  const handlePhotoChange = async (event: any) => {
    event.persist();
    if (event?.target?.files && event.target.files.length > 0) {
      const checkExtension =
        fileExtension.indexOf(
          event.target.files[0].name.split('.').splice(-1)[0]
        ) !== -1;
      const checkMimeTypes =
        mimeType.indexOf(event.target.files[0].type) !== -1;
      const checkFileSize = event.target.files[0].size > 4194304;

      if (!(checkExtension || checkMimeTypes)) {
        errorNotification('That file type is not accepted');
      } else if (checkFileSize) {
        errorNotification('File size should be less than 4 mb');
      } else {
        if (event?.target?.files?.[0]) {
          const formData = new FormData();
          formData.append('credit-note-image', event.target.files[0]);
          const config: Record<string, Record<string, string>> = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };

          await dispatch(
            uploadPhotoForCreditAction(
              formData,
              config,
              creditNoteDetail?.pictureURLs
            )
          );
        }
      }
    }
  };

  const deleteFromOptional = (url: any) => {
    const data = creditNoteDetail?.pictureURLs?.filter(
      (photo: any) => photo !== url
    );
    dispatch(onChangeCreditNoteData('pictureURLs', data));
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    value.length > 0 && setError(undefined);
    dispatch(onChangeCreditNoteData(name, value));
  };

  const onGenerateCredit = () => {
    if (!creditNoteDetail?.products?.length) {
      errorNotification('Please Select at least one product');
    } else if (!creditNoteDetail?.reasonForCredit.length) {
      setError('Please Enter reason for generating credit note');
    } else {
      if (creditNoteDetail?.pictureURLs?.length === 0) {
        dispatch(onChangeCreditNoteData('pictureURLs', null));
        delete creditNoteDetail?.pictureURLs;
      }
      const finalData = {
        ...creditNoteDetail,
        invoiceId: invoiceDetails?._id || uploadedCreditNoteDetails?.invoiceId,
        invoiceStatus:
          invoiceDetails?.status || uploadedCreditNoteDetails?.invoiceStatus,
        supplierId:
          invoiceDetails?.supplierId || uploadedCreditNoteDetails?.supplierId,
        invoiceNumber:
          invoiceDetails?.invoiceNumber ||
          uploadedCreditNoteDetails?.invoiceNumber,
      };
      dispatch(generateCreditAction(finalData, history));
    }
  };

  const onSaveCreditNote = () => {
    if (!creditNoteDetail?.products?.length) {
      errorNotification('Please Select at least one product');
    } else if (!creditNoteDetail?.reasonForCredit.length) {
      setError('Please Enter reason for generating credit note');
    } else {
      if (creditNoteDetail?.pictureURLs?.length === 0) {
        dispatch(onChangeCreditNoteData('pictureURLs', null));
        delete creditNoteDetail?.pictureURLs;
      }
      const finalData = {
        ...uploadedCreditNoteDetails,
        invoiceId: uploadedCreditNoteDetails?.invoiceId,
        supplierId: uploadedCreditNoteDetails?.supplierId,
      };
      dispatch(saveCreditNoteAction(finalData, history));
    }
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);

      if (invoiceDetails?.products?.length > 0) {
        const selectedProducts = invoiceDetails?.products?.filter(
          (product: Record<string, string>) => newArr.includes(product?._id)
        );

        dispatch(onChangeCreditNoteData('products', selectedProducts));
      } else {
        const selectedProducts = uploadedCreditNoteDetails?.products?.filter(
          (product: Record<string, any>) => newArr.includes(product?._id)
        );
        dispatch(onChangeCreditNoteData('products', selectedProducts));
      }
    }
  };

  const selectAllCheckBox = () => {
    const allIds: string[] = [];
    const selectedProducts: any = [];
    if (invoiceDetails?.products?.length > 0) {
      invoiceDetails?.products?.forEach((data: Record<string, any>) => {
        selectedProducts.push(data);
        allIds.push(data._id);
      });
    } else {
      uploadedCreditNoteDetails?.products?.forEach(
        (data: Record<string, any>) => {
          selectedProducts.push(data);
          allIds.push(data?._id);
        }
      );
    }

    if (
      invoiceDetails?.products?.length !== 0 ||
      uploadedCreditNoteDetails?.product?.length !== 0
    ) {
      if (
        checkedIds?.length === uploadedCreditNoteDetails?.products?.length ||
        checkedIds?.length === invoiceDetails?.products?.length
      ) {
        setCheckedIds([]);
        dispatch(onChangeCreditNoteData('products', null));
      } else {
        setCheckedIds(allIds);
        dispatch(onChangeCreditNoteData('products', selectedProducts));
      }
    }
  };

  const onCancelCreditNote = () => {
    const data = {
      pictureURLS: creditNoteDetail?.pictureURLs,
      fileURL:
        viewCreditNoteDetails?.fileURL || uploadedCreditNoteDetails?.fileURL,
      documentId:
        viewCreditNoteDetails?.documentId ||
        uploadedCreditNoteDetails?.documentId,
    };
    if (
      creditNoteDetail?.pictureURLs.length ||
      viewCreditNoteDetails?.fileURL
    ) {
      dispatch(cancelCreditNote(data, history));
    } else {
      history.goBack();
    }
  };

  const changeStatus = (status: string) => {
    if (viewCreditNoteDetails?._id) {
      dispatch(
        changeCreditNoteStatus(
          { status: status },
          viewCreditNoteDetails?._id,
          history
        )
      );
    }
  };

  useEffect((): any => {
    if (action === 'generateCreditNote') {
      if (orderId) {
        dispatch(getInvoiceDetailsByOrderId(orderId));
      } else if (invoiceId) {
        dispatch(getInvoiceDetailsByIdAction(invoiceId));
      }
    } else if (action === 'editCreditNote') {
      dispatch(editCreditNoteAction(creditId));
    } else {
      dispatch(getCreditNoteDetails(creditId));
    }
    return () => {
      dispatch(resetCreditNoteData());
      dispatch(resetInvoiceDetails());
    };
  }, []);

  useEffect(() => {
    const isExistingProducts = uploadedCreditNoteDetails?.products?.filter(
      (product: any) => product.productAlreadyExistsInCreditNote === true
    );
    const ids = isExistingProducts?.map((e: any) => e._id);
    ids?.length && setCheckedIds([...ids]);
    dispatch(onChangeCreditNoteData('products', isExistingProducts));
  }, [uploadedCreditNoteDetails]);

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton onClick={() => history.goBack()}></BackButton>
          <div className="form-title">Credit Note</div>
        </div>
        {(viewCreditNoteDetails?.fileURL ||
          uploadedCreditNoteDetails?.fileURL) && (
          <div className="button-container">
            <Button
              variant="contained"
              color="primary"
              className="desktop-button"
              onClick={() => {
                window.open(
                  viewCreditNoteDetails?.fileURL ||
                    uploadedCreditNoteDetails?.fileURL,
                  '_blank'
                );
              }}
            >
              View Actual Credit-Note
            </Button>
            <Button onClick={() => {}}>Modify</Button>
          </div>
        )}
      </div>
      {invoiceDetailsLoaderAction ||
      invoiceDetailsFromOrderLoaderAction ||
      getCreditNoteDetailsLoaderAction ? (
        <Loader />
      ) : (
        <div className="list-container">
          <div>
            <div className="details-block-title">Credit Details</div>
            <div className="view-details-white-container details-grid details-grid-three-columns shadow-margin">
              <div>
                <div className="detail-label">Supplier</div>
                <div className="detail-value">
                  {invoiceDetails?.supplierId?.supplierName ||
                    viewCreditNoteDetails?.supplierId?.supplierName ||
                    uploadedCreditNoteDetails?.supplierId?.supplierName ||
                    '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Email</div>
                <div className="detail-value">
                  {invoiceDetails?.supplierId?.supplierEmail ||
                    viewCreditNoteDetails?.supplierId?.supplierEmail ||
                    uploadedCreditNoteDetails?.supplierId?.supplierEmail ||
                    '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Invoice Status</div>
                <div className="detail-value">
                  <div
                    className={`status-tag ${
                      invoiceDetails?.status?.toLowerCase() ||
                      viewCreditNoteDetails?.invoiceId?.status?.toLowerCase() ||
                      uploadedCreditNoteDetails?.invoiceStatus?.toLowerCase()
                    }`}
                  >
                    {invoiceDetails?.status ||
                      viewCreditNoteDetails?.invoiceId?.status ||
                      uploadedCreditNoteDetails?.invoiceId.status ||
                      '-'}
                  </div>
                </div>
              </div>
              <div>
                <div className="detail-label">Credit Requested By</div>
                <div className="detail-value">
                  {invoiceDetails.checkedBy ||
                    viewCreditNoteDetails?.requestedBy ||
                    uploadedCreditNoteDetails?.requestedBy ||
                    '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Order Date</div>
                <div className="detail-value">
                  {invoiceDetails?.orderId?.orderDate ||
                  viewCreditNoteDetails?.orderId?.orderDate ||
                  uploadedCreditNoteDetails?.orderDate
                    ? dayjs(
                        dateCalculationHelper(
                          invoiceDetails?.orderId?.orderDate ||
                            uploadedCreditNoteDetails?.orderDate ||
                            viewCreditNoteDetails?.orderId?.orderDate,
                          profileDetail.timeZoneOffSet
                        )
                      ).format('DD MMM YYYY')
                    : '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Credit ID</div>
                <div className="detail-value">
                  {viewCreditNoteDetails?.creditId ||
                    uploadedCreditNoteDetails?.creditId ||
                    '-'}
                </div>
              </div>
            </div>

            <div className="main-details-container">
              <div className="details-block-title">Line items</div>
              {(invoiceDetails ||
                viewCreditNoteDetails ||
                uploadedCreditNoteDetails) && (
                <CustomTable
                  headers={invoiceTableProductsHeaderConstants}
                  mobileHeaders={invoiceTableProductsMobileHeader}
                  data={
                    invoiceDetails?.products ??
                    viewCreditNoteDetails?.products ??
                    uploadedCreditNoteDetails?.products ??
                    []
                  }
                  isPagination
                  isCheckbox={
                    action === 'generateCreditNote' ||
                    action === 'editCreditNote'
                  }
                  onChangeCheckBox={onChangeCheckBox}
                  checkedIds={checkedIds}
                  selectAllCheckBox={selectAllCheckBox}
                />
              )}
            </div>
            <div className="invoices-total-container shadow-margin">
              <div className="credit-total-container ">
                <span>Credit Total</span>
                {
                  <span className="invoice-total-value">
                    {action === 'view'
                      ? audConverter(viewCreditNoteDetails?.creditTotal)
                      : uploadedCreditNoteDetails?.creditTotal
                      ? audConverter(uploadedCreditNoteDetails?.creditTotal)
                      : audConverter(
                          creditNoteDetail?.products
                            ? creditNoteDetail?.products?.reduce(
                                (
                                  prevTotal: number,
                                  product: Record<string, string | number>
                                ) => {
                                  return prevTotal + +product?.total;
                                },
                                0
                              )
                            : 0
                        )}
                  </span>
                }
              </div>
            </div>
            {action === 'generateCreditNote' || action === 'editCreditNote' ? (
              <div>
                <div className="form-field-name instruction-title">
                  <div className="form-field-name">Reason For Credit</div>
                  <div className="required-sign">*</div>
                </div>
                <FormControl className="input-field">
                  <Input
                    variant="filled"
                    placeholder="Enter reason for credit"
                    className={`input-field shadow-margin ${
                      error ? 'input-with-error' : ''
                    }`}
                    name="reasonForCredit"
                    value={creditNoteDetail?.reasonForCredit}
                    type="text"
                    multiline
                    rows={3}
                    onKeyUp={(event) =>
                      enterKeyHelper(event, () =>
                        action === 'generateCreditNote'
                          ? onGenerateCredit()
                          : onSaveCreditNote()
                      )
                    }
                    onChange={onInputChange}
                    helperText={error}
                  />
                </FormControl>
              </div>
            ) : (
              <div className="credit-note-container">
                {viewCreditNoteDetails?.reasonForCredit && (
                  <div className="view-details-white-container creditnote-container">
                    <div className="detail-label">Reason For Credit</div>
                    <div className="detail-value details-instruction-container">
                      {viewCreditNoteDetails?.reasonForCredit ?? '-'}
                    </div>
                  </div>
                )}
              </div>
            )}
            {action === 'generateCreditNote' || action === 'editCreditNote' ? (
              <div className="upload-photo-block">
                <div>
                  <div className="form-field-name">Photo</div>
                  <div>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={`outlined-primary-button $`}
                      onClick={handleClick}
                    >
                      Upload Photo
                    </Button>
                    <input
                      type="file"
                      name="pictuteURL"
                      className="input-file-type"
                      onChange={handlePhotoChange}
                      ref={hiddenFileInput}
                    />
                  </div>
                </div>
                <div className="optionalPhotosContainer">
                  {creditNoteDetail?.pictureURLs?.length > 0 ? (
                    creditNoteDetail?.pictureURLs?.map((pictureURL: string) => {
                      return (
                        <div className="recipe-photo">
                          <img
                            alt="product-photo"
                            className="recipe-photo-container"
                            src={pictureURL}
                          />
                          {pictureURL && (
                            <Delete
                              className="delete-icon"
                              onClick={() => deleteFromOptional(pictureURL)}
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="photo-container">
                      <span className="material-icons-round">insert_photo</span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              viewCreditNoteDetails?.pictureURLs?.length > 0 && (
                <div className="view-photos-container">
                  <div className="form-field-name">Photos</div>
                  <div className="recipe-photos-container">
                    {viewCreditNoteDetails?.pictureURLs?.map(
                      (image: string) => (
                        <div className="recipe-photo">
                          <img src={image}></img>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )
            )}

            <div className="form-button-row credit-note-button-container">
              {(action === 'generateCreditNote' ||
                viewCreditNoteDetails?.status === 'Pending' ||
                action === 'editCreditNote') && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onCancelCreditNote}
                >
                  Cancel
                </Button>
              )}
              {action === 'generateCreditNote' && (
                <Button
                  color="primary"
                  variant="contained"
                  loader={generateCreditNoteLoaderAction}
                  onClick={onGenerateCredit}
                >
                  Generate Credit
                </Button>
              )}

              {action === 'editCreditNote' && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSaveCreditNote}
                >
                  Save
                </Button>
              )}
              {viewCreditNoteDetails?.status === 'Pending' &&
                action === 'view' && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => changeStatus('Rejected')}
                  >
                    Rejected
                  </Button>
                )}
              {viewCreditNoteDetails?.status === 'Pending' &&
                action === 'view' && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => changeStatus('Approved')}
                  >
                    Approved
                  </Button>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCreditNote;
