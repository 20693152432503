import { MobileDatePicker, DatePicker, TimePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Icon,
  InputAdornment,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import BackButton from '../../../components/backButton/BackButton';
import Input from '../../../components/input/Input';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { getSuppliers } from '../../../store/actions/commonAction';
import {
  createOrderQuantityChange,
  getAllProducts,
  getOrderDetailsById,
  onChangeOrderDetail,
  onChangeUnitAction,
  resetOrderDetails,
  onChangeProductQuantity,
  selectProduct,
  getPantryList,
  deleteProductFromDraftOrder,
  editProductNote,
  resetAllProducts,
  getSupplierListInOrderPage,
} from '../../../store/actions/orderAction';
import { checkOrderValidation } from './checkOrderValidations';
import CalenderIcon from '../../../assets/images/CalenderIcon';
import ClockIcon from '../../../assets/images/ClockIcon';
import {
  AddRounded,
  CheckRounded,
  DeleteRounded,
  Description,
  EditRounded,
  RemoveRounded,
  Search,
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CheckBox from '../../../components/checkbox/CheckBox';
import Button from '../../../components/button/Button';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { errorNotification } from '../../../components/notifyHelper';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import { ORDER_REDUX_CONSTANTS } from '../../../store/reduxConstants/orderReduxConstants';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

const CreateNewOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const noteRef = useRef<HTMLDivElement>(null);
  const { action, orderId } = useParams<Record<string, string>>();
  const onClickBackButton = () => {
    dispatch(resetOrderDetails());
    history.push(ROUTE_CONSTANTS_VARIABLE.ORDER);
  };
  const [activeTab, setActiveTab] = useState<string>('0');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [height, setHeight] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [checkedIds, setCheckedIds] = useState<Array<string>>([]);
  const [displayNote, setDisplayNote] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowId, setRowId] = useState<string>('');
  const [editNote, setEditNote] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);

  const { order, allProducts, pantryList, supplierList } = useSelector(
    ({ orderReducer }: Record<string, any>) => orderReducer ?? {}
  );

  const onChangeDetail = (name: string, value: any) => {
    if (name !== 'products') {
      dispatch(onChangeOrderDetail({ name, value }));
    } else {
      const isProductAlreadyExist = order?.products?.filter(
        (product: Record<string, Record<string, string>>) =>
          value[value.length - 1]?._id === product._id
      );
      if (value?.length && !isProductAlreadyExist?.length) {
        dispatch(onChangeOrderDetail({ name, value }));
      }
    }
    if (name === 'supplierId') {
      dispatch(onChangeOrderDetail({ name: 'products', value: null }));
      setCheckedIds([]);
      value?._id &&
        dispatch(
          getAllProducts({
            supplierId: value._id ?? '',
          })
        );
      value?._id && dispatch(getPantryList({ supplierId: value._id ?? '' }));
      if (!value) {
        dispatch(resetAllProducts());
      }
    }
    if (isSubmitted) {
      checkOrderValidation(
        'create',
        { name: value },
        dispatch,
        history,
        'onChange',
        action
      );
    }
  };

  const onChangeActiveTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const getListSize = () => {
    const newHeight = listRef.current?.clientHeight ?? 0;
    if (window.innerWidth <= 820) {
      setHeight(newHeight + 299);
    } else {
      setHeight(newHeight + 319);
    }
  };
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onSearchClick = () => {
    dispatch(
      getAllProducts({
        supplierId: order?.supplierId?._id,
        searchString: order?.search,
      })
    );
    dispatch(
      getPantryList({
        supplierId: order?.supplierId?._id,
        searchString: order?.search,
      })
    );
  };

  const onChangeUnit = (value: Record<string, any>, name: string) => {
    dispatch(onChangeUnitAction(value, name));
  };

  const onChangeCheckBox = (
    value: Record<string, any>,
    index?: number,
    name?: string
  ) => {
    if (name === 'allProducts' && index) {
      allProducts.docs[index].products.length === 1
        ? dispatch(onChangeUnitAction(value, name))
        : errorNotification('Please select UOM');
    } else if (name === 'pantryList' && index) {
      pantryList.docs[index].products.length === 1
        ? dispatch(onChangeUnitAction(value, name))
        : errorNotification('Please select UOM');
    }

    const id = value?._id;

    if (id) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === id);

      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(id);
      }
      dispatch(selectProduct(value));
      setCheckedIds(newArr);
    }
  };
  const onDeleteProduct = (product: Record<string, string>) => {
    setRowId(product._id);
    setOpenDeleteModal(true);
    dispatch({
      type: ORDER_REDUX_CONSTANTS.DELETE_PRODUCT,
      data: product.name,
    });
  };

  const addProductStyle = {
    overflow: 'scroll',
    height:
      window.innerHeight < 882
        ? '380px'
        : window.innerWidth < 820
        ? '380px '
        : height > 500
        ? 'calc(100vh - ' + height + 'px)'
        : '380px',
    marginBottom:
      window.innerHeight < 882
        ? window.innerWidth <= 820
          ? '0'
          : '57px'
        : '0',
  };

  const onIncrementalChange = (
    action: string,
    productName: string,
    listName: string
  ) => {
    dispatch(createOrderQuantityChange({ action, productName, listName }));
  };

  const selectedProductQuantityChange = (action: string, id: string) => {
    dispatch(onChangeProductQuantity({ action, id }));
  };

  const onClickDeleteConfirmation = () => {
    dispatch(deleteProductFromDraftOrder(rowId));
    setOpenDeleteModal(false);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];
  const handleClose = () => {
    setOpenDeleteModal(false);
    setRowId('');
  };

  const onPlaceOrder = () => {
    setIsSubmitted(true);
    checkOrderValidation(
      'create',
      order,
      dispatch,
      history,
      'onSubmit',
      action
    );
  };

  const onCancelOrder = () => {
    dispatch(resetOrderDetails());
    history.push(ROUTE_CONSTANTS_VARIABLE.ORDER);
  };

  const pageTitle: Record<string, string> = {
    create: 'Create New Order',
    edit: 'Edit Order',
    addOn: 'Order Add On',
  };

  const buttonText: Record<string, string> = {
    create: 'Place Order',
    edit: 'Save',
    addOn: 'Save',
  };

  const checkBoxCell = (product: any, index: number, type: string) => {
    const checked = () => {
      const data =
        product.products.length > 0
          ? order?.products?.filter(
              (item: Record<string, string>) =>
                item._id === product?.selectedProduct?._id
            )
          : order?.products?.filter(
              (item: Record<string, string>) =>
                item._id === product?.products[0]?._id
            );
      return data?.length > 0 ? true : false;
    };
    return (
      <CheckBox
        checked={checked()}
        onClick={(e) => {
          e.stopPropagation();
          {
            if (product?.products.length > 1) {
              product.selectedProduct
                ? onChangeCheckBox(product.selectedProduct)
                : errorNotification('Please select UOM');
            } else {
              onChangeCheckBox(product.products[0], index, type);
            }
          }
        }}
      />
    );
  };

  useEffect(() => {
    getListSize();
  }, []);

  useEffect(() => {
    if (displayNote) {
      setTimeout(() => {
        noteRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, [displayNote, noteRef.current]);

  useEffect(() => {
    window.addEventListener('resize', getListSize);
    dispatch(getSupplierListInOrderPage());
    if (action === 'edit' || action === 'addOn') {
      dispatch(getOrderDetailsById(orderId));
    }
    return () => {
      dispatch(resetOrderDetails());
      window.removeEventListener('resize', getListSize);
    };
  }, []);
  useEffect(() => {
    order.supplierId?._id &&
      dispatch(
        getAllProducts({
          supplierId: order.supplierId?._id,
          searchString: order.search,
        })
      );
    order.supplierId?._id &&
      dispatch(
        getPantryList({
          supplierId: order.supplierId?._id,
          searchString: order.search,
        })
      );
  }, [order.supplierId?._id]);

  useEffect(() => {
    if (action === 'edit' || action === 'addOn') {
      const ids = order?.products?.map((product: Record<string, string>) => {
        return product._id;
      });
      setCheckedIds(ids);
    }
  }, [orderId]);
  return (
    <div className="form create-order-form">
      <div className="form-title-row">
        <BackButton onClick={onClickBackButton}></BackButton>
        <div className="form-title">{pageTitle[action]}</div>
      </div>
      <div className="form-container create-order-form-container">
        <div className="form-fields-grid" ref={listRef}>
          <div>
            <div className="form-field-name">
              Delivery Date
              <div className="required-sign">*</div>
            </div>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                key={order?._id}
                className={`${
                  order?.errors?.[`deliveryDate`] ? 'input-with-error' : ''
                }`}
                // open={openDatePicker}
                // onClose={() => setOpenDatePicker(false)}
                disablePast
                label=""
                openTo="day"
                disabled={order?.status && order?.status === 'Placed'}
                views={['year', 'month', 'day']}
                showToolbar={false}
                /*PaperProps={{
                  className: order?.errors?.deliveryDate
                    ? 'input-with-error'
                    : '',
                }}*/
                inputFormat="dd MMMM yyyy"
                value={order?.deliveryDate}
                onChange={(e) => {
                  setOpenDatePicker(true);
                  onChangeDetail('deliveryDate', e);
                }}
                components={{ OpenPickerIcon: CalenderIcon }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position={'end'}
                      onClick={() => setOpenDatePicker((e) => !e)}
                    >
                      <Icon>
                        <CalenderIcon />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                renderInput={(params: any) => (
                  <Input
                    onClick={() => setOpenDatePicker(true)}
                    variant="filled"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select Date',
                      readOnly: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            {order?.errors?.deliveryDate && (
              <div className="error-message">{order.errors.deliveryDate}</div>
            )}
          </div>
          <div>
            <div className="form-field-name">
              Delivery Time
              <div className="required-sign">*</div>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileTimePicker
                label=""
                className={`${
                  order?.errors?.[`deliveryTime`] ? 'input-with-error' : ''
                }`}
                // open={openTimePicker}
                // onClose={() => setOpenTimePicker(false)}
                value={order?.deliveryTime}
                disabled={order?.status && order?.status === 'Placed'}
                onChange={(e) => {
                  setOpenTimePicker(true);
                  onChangeDetail('deliveryTime', e);
                }}
                components={{ OpenPickerIcon: ClockIcon }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position={'end'}
                      onClick={() => setOpenDatePicker((e) => !e)}
                    >
                      <Icon>
                        <ClockIcon />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                renderInput={(params: any) => (
                  <Input
                    onClick={() => setOpenTimePicker(true)}
                    variant="filled"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'AM Preferred',
                      readOnly: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {order?.errors?.deliveryTime && (
              <div className="error-message">{order.errors.deliveryTime}</div>
            )}
          </div>
          <div>
            <div className="form-field-name">
              Supplier
              <div className="required-sign">*</div>
            </div>
            <Autocomplete
              key={order?._id}
              disabled={order?.status && order?.status === 'Placed'}
              placeholder="Select Supplier"
              onChange={(_event, value) => onChangeDetail('supplierId', value)}
              options={supplierList}
              getOptionLabel={(option) => option?.name ?? option ?? ''}
              value={order?.supplierId?.name}
              defaultValue={order?.supplierId?.name}
              className="autocomplete"
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option?.name}
                </Box>
              )}
              renderInput={(params: any) => (
                <Input
                  variant="filled"
                  {...params}
                  placeholder="Select Supplier"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
          <div>
            <div className="form-field-name">Search</div>

            <Input
              variant="filled"
              className="input-field"
              name="search"
              type="text"
              placeholder="Search products"
              disabled={!order?.supplierId?._id}
              onChange={(e) => onChangeDetail('search', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents={!order?.supplierId?._id}
                  >
                    <Search
                      className="search-icon"
                      onClick={() => onSearchClick()}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="primary-divider"></div>
        <div className="select-product-container">
          <div className="select-product">
            <div className="order-details">
              <h2 className="order-details-title">Order Details</h2>

              <div className="order-list" style={addProductStyle}>
                {order?.products?.length > 0 ? (
                  order?.products?.map(
                    (product: Record<string, any>, index: number) => (
                      <div className="order-view" key={index}>
                        <div className="customize-order">
                          <p className="order-name">
                            <span className="order-id">
                              {product.productCode
                                ? `#${product.productCode}`
                                : ''}
                            </span>
                            <span> {product.name}</span>
                            <span>
                              {product.weight ? `(${product.weight} kg)` : ''}
                            </span>
                          </p>
                          <div className="unit-quantity-container">
                            <p className="order-name">{product.unitId.name}</p>
                            <div className="quantity-cell">
                              <RemoveRounded
                                onClick={() =>
                                  selectedProductQuantityChange(
                                    'remove',
                                    product._id
                                  )
                                }
                              />
                              <span className="quantity-cell-font">
                                {product.quantity ?? 1}
                              </span>
                              <AddRounded
                                onClick={() =>
                                  selectedProductQuantityChange(
                                    'add',
                                    product._id
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="secondary-divider"></div>
                        <div className="additional-actions">
                          {editNote === product._id ? (
                            <Input
                              variant="filled"
                              value={product.productNote}
                              placeholder="Enter Notes"
                              onChange={(e) => {
                                dispatch(
                                  editProductNote(product._id, e.target.value)
                                );
                              }}
                            />
                          ) : (
                            <p className="additional-note">
                              {product.productNote}
                            </p>
                          )}
                          <div className="action-cell">
                            {editNote === product._id ? (
                              <CheckRounded onClick={() => setEditNote('')} />
                            ) : (
                              <EditRounded
                                onClick={() => setEditNote(product._id)}
                              />
                            )}
                            <DeleteRounded
                              onClick={() => onDeleteProduct(product)}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="no-items">
                    No item selected. Select supplier and product to submit an
                    order.
                  </div>
                )}
              </div>
              <div className="order-list-accordion">
                <Accordion
                  expanded={expanded === 'order-list-accordion'}
                  onChange={handleChange('order-list-accordion')}
                  className="accordion"
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownRoundedIcon />}
                    aria-controls="panel1a-content"
                    id="order-list-accordion"
                    className="accordion-summary"
                  >
                    <Typography>Order Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {order?.products?.length > 0 ? (
                      order?.products?.map(
                        (product: Record<string, any>, index: number) => (
                          <div className="order-view" key={index}>
                            <div className="customize-order">
                              <p className="order-name">
                                <span className="order-id">
                                  {product.productCode
                                    ? `#${product.productCode}`
                                    : ''}
                                </span>
                                <span> {product.name}</span>
                                <span>
                                  {product.weight
                                    ? `(${product.weight} kg)`
                                    : ''}
                                </span>
                              </p>

                              <div className="unit-quantity-container">
                                <p className="order-name">
                                  {product.unitId.name}
                                </p>

                                <div className="quantity-cell">
                                  <RemoveRounded
                                    onClick={() =>
                                      selectedProductQuantityChange(
                                        'remove',
                                        product._id
                                      )
                                    }
                                  />
                                  <span className="quantity-cell-font">
                                    {product.quantity ?? 1}
                                  </span>
                                  <AddRounded
                                    onClick={() =>
                                      selectedProductQuantityChange(
                                        'add',
                                        product._id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="secondary-divider"></div>
                            <div className="additional-actions">
                              {editNote === product._id ? (
                                <Input
                                  variant="filled"
                                  value={product.productNote}
                                  placeholder="Enter Notes"
                                  onChange={(e) => {
                                    dispatch(
                                      editProductNote(
                                        product._id,
                                        e.target.value
                                      )
                                    );
                                  }}
                                />
                              ) : (
                                <p className="additional-note">
                                  {product.productNote}
                                </p>
                              )}
                              <div className="action-cell">
                                {editNote === product._id ? (
                                  <CheckRounded
                                    onClick={() => setEditNote('')}
                                  />
                                ) : (
                                  <EditRounded
                                    onClick={() => setEditNote(product._id)}
                                  />
                                )}
                                <DeleteRounded
                                  onClick={() => onDeleteProduct(product)}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="no-items">
                        No item selected. Select supplier and product to submit
                        an order.
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div>
              <TabContext value={activeTab}>
                <TabList
                  onChange={onChangeActiveTab}
                  aria-label="Dashboard-chart"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="All Products" value="0" />
                  <Tab
                    label="Pantry List"
                    value="1"
                    onClick={() => {
                      order?.supplierId?._id &&
                        dispatch(
                          getPantryList({
                            supplierId: order?.supplierId?._id,
                            searchString: order?.search,
                          })
                        );
                    }}
                  />
                </TabList>
                {allProducts?.docs?.length > 0 && (
                  <TabPanel
                    value="0"
                    sx={{ margin: '16px 0 0 0', padding: '5px' }}
                    style={addProductStyle}
                  >
                    {allProducts?.docs?.map((product: any, index: number) => (
                      <div className="order-view add-product" key={index}>
                        <div className="customize-order">
                          <div className="order-name">
                            {checkBoxCell(product, index, 'allProducts')}
                            <div>
                              <span className="order-id">
                                {product?.selectedProduct?.productCode
                                  ? `#${product?.selectedProduct?.productCode}`
                                  : ''}
                              </span>
                              <span> {product.name}</span>
                              <span>
                                {product?.selectedProduct?.weight
                                  ? `(${product?.selectedProduct?.weight} kg)`
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="secondary-divider"></div>
                        <div className="additional-actions">
                          <div className="select-units">
                            <Stack direction="row" className="unit-container">
                              {product.products.length > 0 &&
                                product.products.map((SingleProduct: any) => {
                                  return (
                                    <Chip
                                      className={
                                        SingleProduct.unitId.name ===
                                        product?.selectedProduct?.unitId?.name
                                          ? 'selected-chip'
                                          : 'unSelected-chip'
                                      }
                                      variant="filled"
                                      key={SingleProduct.unitId._id}
                                      label={SingleProduct.unitId.name}
                                      onClick={() =>
                                        onChangeUnit(
                                          SingleProduct,
                                          'allProducts'
                                        )
                                      }
                                    />
                                  );
                                })}
                            </Stack>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TabPanel>
                )}
                <TabPanel
                  value="1"
                  sx={{ margin: '16px 0 0 0', padding: '5px' }}
                  style={addProductStyle}
                >
                  {pantryList.docs?.map((product: any, index: number) => (
                    <div className="order-view add-product" key={index}>
                      <div className="customize-order">
                        <div className="order-name">
                          {checkBoxCell(product, index, 'pantryList')}
                          <div>
                            <span className="order-id">
                              {product?.selectedProduct?.productCode
                                ? `#${product?.selectedProduct?.productCode}`
                                : ''}
                            </span>
                            <span> {product.name}</span>
                            <span>
                              {product?.selectedProduct?.weight
                                ? `(${product?.selectedProduct?.weight} kg)`
                                : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="secondary-divider"></div>
                      <div className="additional-actions">
                        <div className="select-units">
                          <Stack
                            direction="row"
                            spacing={1}
                            className="unit-container"
                          >
                            {product?.products?.length > 0 &&
                              product?.products?.map((SingleProduct: any) => {
                                return (
                                  <Chip
                                    className={
                                      SingleProduct.unitId.name ===
                                      product?.selectedProduct?.unitId?.name
                                        ? 'selected-chip'
                                        : 'unSelected-chip'
                                    }
                                    variant="filled"
                                    key={SingleProduct.unitId._id}
                                    label={SingleProduct.unitId.name}
                                    onClick={() =>
                                      onChangeUnit(SingleProduct, 'pantryList')
                                    }
                                  />
                                );
                              })}
                          </Stack>
                        </div>
                      </div>
                    </div>
                  ))}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
        {displayNote && (
          <div className="note-container" ref={noteRef}>
            <div className="details-block-title">Notes</div>
            <Input
              variant="filled"
              multiline
              placeholder="Enter Notes"
              value={order?.notes}
              rows={2}
              onKeyUp={(event) => enterKeyHelper(event, onPlaceOrder)}
              onChange={(e) => onChangeDetail('notes', e.target.value)}
            />
          </div>
        )}
        <div className="form-button-row">
          <Button variant="contained" color="secondary" onClick={onCancelOrder}>
            Cancel
          </Button>
          {order?.products?.length > 0 && (
            <Button variant="contained" color="primary" onClick={onPlaceOrder}>
              {buttonText[action]}
            </Button>
          )}
          <a
            href="#"
            className="action-notes"
            onClick={() => {
              setDisplayNote((e) => !e);
            }}
          >
            <Description />
          </a>
        </div>
      </div>
      {openDeleteModal && (
        <CustomModal
          header="Delete Product"
          buttons={deleteModalButton}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete this item?
          </span>
        </CustomModal>
      )}
    </div>
  );
};
export default CreateNewOrder;
