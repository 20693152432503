import { INVOICE_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/invoiceReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface invoiceProps {
  invoiceList: Record<string, any>;
  invoiceDetails: Record<string, any>;
  orderListDropdown: Record<string, string>[];
  updateStatus: Record<string, any>;
  supplierDropdownInInvoice: Record<string, string>[];
  userDropdownInInvoice: Record<string, string>[];
  uploadedInvoiceResponce: Record<string, string>;
  addEditInvoice: Record<string, any>;
  supplierDropdown: Record<string, string>[];
  productDropdown: Record<string, string>[];
  mapperData: Record<string, any>[];
  inventoryProducts: Record<string, string>[] | null;
}

const initialInvoiceReducer: invoiceProps = {
  invoiceList: {},
  invoiceDetails: {
    invoiceNumber: '',
    orderDate: '',
    supplierName: '',
    email: '',
    status: '',
    acceptedBy: '',
    subTotalAmount: '',
    gstPercentage: '',
    gstAmount: '',
    totalAmount: '',
    products: null,
  },
  orderListDropdown: [],
  updateStatus: {
    orderId: '',
    deliveredBy: '',
    errors: {},
  },
  supplierDropdownInInvoice: [],
  userDropdownInInvoice: [],
  uploadedInvoiceResponce: {},
  addEditInvoice: {
    supplierId: null,
    invoiceNumber: '',
    invoiceDate: '',
    netTotal: null,
    gstPercentage: 10,
    taxAmount: null,
    totalAmount: null,
    products: [],
    fileURL: '',
  },
  supplierDropdown: [],
  productDropdown: [],
  mapperData: [],
  inventoryProducts: [],
};

export const invoiceReducer = (
  state = initialInvoiceReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action.type) {
    case INVOICE_REDUX_CONSTANTS.INVOICE_LIST:
      return {
        ...state,
        invoiceList: action.data,
      };

    case INVOICE_REDUX_CONSTANTS.GET_INVOICE_DETAILS_BY_ID:
      return {
        ...state,
        invoiceDetails: action.data,
      };

    case INVOICE_REDUX_CONSTANTS.ON_CHANGE_UPDATE_STATUS_DATA:
      const { name, value } = action.data;
      return {
        ...state,
        updateStatus: { ...state.updateStatus, [name]: value },
      };

    case INVOICE_REDUX_CONSTANTS.GET_ORDER_DROPDOWN_LIST:
      return { ...state, orderListDropdown: action.data };

    case INVOICE_REDUX_CONSTANTS.RESET_UPDATE_STATUS_DATA:
      return {
        ...state,
        updateStatus: { ...initialInvoiceReducer.updateStatus },
      };

    case INVOICE_REDUX_CONSTANTS.GET_SUPPLIER_DROPDOWN:
      return {
        ...state,
        supplierDropdownInInvoice: action.data,
      };

    case INVOICE_REDUX_CONSTANTS.GET_USER_DROPDOWN:
      return {
        ...state,
        userDropdownInInvoice: action.data,
      };

    case INVOICE_REDUX_CONSTANTS.RESET_INVOICE_DETAILS:
      return {
        ...state,
        invoiceDetails: {
          ...initialInvoiceReducer.invoiceDetails,
          products: null,
        },
      };

    case INVOICE_REDUX_CONSTANTS.UPLOAD_INVOICE:
      return {
        ...state,
        uploadedInvoiceResponce: action.data,
      };

    case INVOICE_REDUX_CONSTANTS.GET_SUPPLIER_DROPDOWN_LIST:
      return { ...state, supplierDropdown: action.data };

    case INVOICE_REDUX_CONSTANTS.GET_PRODUCT_DROPDOWN_LIST:
      return { ...state, productDropdown: action.data };

    case INVOICE_REDUX_CONSTANTS.ON_CHANGE_INVOICE_DATA:
      const { fieldName, fieldValue } = action.data;
      let finalData = fieldValue;

      if (fieldName === 'products' && fieldValue?.length) {
        finalData = fieldValue.map((product: Record<string, any>) => {
          return {
            ...product,
            quantity: product.quantity ?? 1,
            price: product.price ?? 0,
            amount: (product.quantity ?? 1) * (product.price ?? 0),
          };
        });
      }
      return {
        ...state,
        addEditInvoice: { ...state.addEditInvoice, [fieldName]: finalData },
      };

    case INVOICE_REDUX_CONSTANTS.UPLOAD_CEATE_INVOICE:
      return {
        ...state,
        addEditInvoice: {
          ...state?.addEditInvoice,
          fileURL: action.data.URL,
          fileName: action.data.fileName,
        },
      };

    case INVOICE_REDUX_CONSTANTS.DELETE_INVOICE_PRODUCT:
      const products = [...state.addEditInvoice.products];

      const finalproducts = products.filter(
        (product) => product._id !== action.data
      );
      return {
        ...state,
        addEditInvoice: {
          ...state.addEditInvoice,
          products: finalproducts,
        },
      };

    case INVOICE_REDUX_CONSTANTS.RESET_ADD_INVOICE:
      return {
        ...state,
        addEditInvoice: { ...initialInvoiceReducer.addEditInvoice },
      };

    case INVOICE_REDUX_CONSTANTS.ON_CHANGE_QUANTITY:
      const { action: increamentAction, id } = action.data;

      const updatedValue =
        state.addEditInvoice.products?.length &&
        state.addEditInvoice.products.map((product: Record<string, any>) => {
          if (product._id === id) {
            let qty = product?.quantity ?? 1;

            const quantityChanger: Record<string, number> = {
              add: qty + 1,
              remove: qty > 1 ? qty - 1 : qty,
            };
            const amountChanger: Record<string, number> = {
              add: (qty + 1) * (product.price ?? 0),
              remove: (qty > 1 ? qty - 1 : qty) * (product.price ?? 0),
            };

            return {
              ...product,
              quantity: quantityChanger[increamentAction],
              amount: amountChanger[increamentAction],
            };
          } else return product;
        });

      return {
        ...state,
        addEditInvoice: {
          ...state.addEditInvoice,
          ['products']: updatedValue,
        },
      };

    case INVOICE_REDUX_CONSTANTS.ON_INVOICE_INPUT_CHANGE:
      const { inputValue, productId } = action.data;
      const selectedProducts = [...state.addEditInvoice.products];
      const data = selectedProducts.map((product) => {
        if (product._id === productId) {
          const amount = (product.quantity ?? 1) * parseFloat(inputValue);
          return { ...product, price: inputValue, amount };
        } else return product;
      });

      return {
        ...state,
        addEditInvoice: {
          ...state.addEditInvoice,
          ['products']: data,
        },
      };

    case INVOICE_REDUX_CONSTANTS.GET_EDIT_INVOICE_DATA:
      return { ...state, addEditInvoice: action.data };

    case INVOICE_REDUX_CONSTANTS.REMOVE_UPLOADED_INVOICE:
      return {
        ...state,
        addEditInvoice: { ...state.addEditInvoice, fileURL: '' },
      };

    case INVOICE_REDUX_CONSTANTS.GET_MAPPER_DATA:
      const mapperProducts = action.data.map((product: Record<string, any>) => {
        return {
          ...product,
          inventoryName: '',
          inventoryUOM: '',
          weight: product.weight ?? '',
        };
      });
      return { ...state, mapperData: mapperProducts };

    case INVOICE_REDUX_CONSTANTS.GET_INVENTORY_PRODUCTS:
      return { ...state, inventoryProducts: action.data };

    case INVOICE_REDUX_CONSTANTS.ON_MAPPER_DATA_CHANGE:
      const { productID, key, inventoryValue } = action.data;
      const inventoryData = [...state.mapperData];

      const finalProduct = inventoryData?.map(
        (item: Record<string, string>) => {
          return item.productId === productID
            ? { ...item, [key]: inventoryValue }
            : item;
        }
      );

      return { ...state, mapperData: finalProduct };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialInvoiceReducer,
      };

    default:
      return state;
  }
};
