import { successNotification } from '../../../components/notifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { expenseApiServices } from '../../../services/pageServices/invoiceServices/expenseApiServises';
import { EXPENSES_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/expenseReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getExpenseListAction = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('expenseListLoaderAction');
      const response = await expenseApiServices.expenseList(params);
      if (response?.status === 200) {
        dispatch({
          type: EXPENSES_REDUX_CONSTANTS.EXPENSES_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('expenseListLoaderAction');
    }
  };
};

export const updateStatusAction = (expenseId: string, status: string) => {
  return async () => {
    try {
      const response = await expenseApiServices.updatestatus(expenseId, status);
      if (response?.status === 200) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const deleteExpenseAction = (ids: string, cb: () => void) => {
  return async () => {
    try {
      const params = {
        expenseIds: ids,
      };
      const response = await expenseApiServices.deleteExpense(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportExpenseExcelsheet = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await expenseApiServices.downloadExpenseExcelSheet(data);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportExpensePdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await expenseApiServices.downloadExpensePdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getExpneseSupplierDropdown = () => {
  return async (dispatch: any) => {
    try {
      const response = await expenseApiServices.expenseSupplerDropdown();
      if (response.status === 200) {
        dispatch({
          type: EXPENSES_REDUX_CONSTANTS.EXPENSE_FILTER_SUPPLIER,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const modifyExpenseAction = (
  expenseId: string,
  data: Record<string, string | number>
) => {
  return async () => {
    try {
      const response = await expenseApiServices.modifyExpense(expenseId, data);
      if (response.status === 200) {
        window.open(response.data.data.url, '_blank');
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getModifiedDataAction = (
  expenseId: string,
  data: Record<string, string>
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest(`${expenseId}Modify`);
      const response = await expenseApiServices.getModifiedData(
        expenseId,
        data
      );
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(`${expenseId}Modify`);
    }
  };
};

export const expenseAutoSyncAction = (autoSync: boolean) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('expenseSyncLoaderAction')
      const response = await expenseApiServices.expenseAutoSync({ autoSync });
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }finally{
      stopGeneralLoaderOnSuccessOrFail('expenseSyncLoaderAction')
    }
  };
};

export const expenseManualSyncAction = (expenseId: string) => {
  return async () => {
    startGeneralLoaderOnRequest(`${expenseId}Sync`);
    try {
      const response = await expenseApiServices.expenseManualSync(expenseId);
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(`${expenseId}Sync`);
    }
  };
};

export const getUploadedExpenseResponse = (data: Record<string, any>) => {
  return (dispatch: any) => {
    dispatch({
      type: EXPENSES_REDUX_CONSTANTS.GET_UPLOADED_EXPENSE_RESPONSE,
      data,
    });
  };
};

export const modifyUploadedExpenseAction = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await expenseApiServices.modifyUploadedExpense(data);
      if (response.status === 200) {
        window.open(response.data.data.url, '_blank');
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getModifiedUploadedExpenseAction = (
  data: Record<string, string>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getUploadedExpenseModifiedDataLoaderAction');
      const response = await expenseApiServices.getModyfiedUploadedExpenseData(
        data
      );
      if (response.status === 200) {
        successNotification(response.data.data.message);
        dispatch({
          type: EXPENSES_REDUX_CONSTANTS.GET_UPLOADED_EXPENSE_RESPONSE,
          data: { ...response.data.data },
        });
        return response.data.data;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getUploadedExpenseModifiedDataLoaderAction'
      );
    }
  };
};
