import { successNotification } from '../../components/notifyHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import supportApiServices from '../../services/pageServices/supportApiServices';
import { SUPPORT_REDUCX_CONSTANTS } from '../reduxConstants/supportReduxConstants';

export const onChageQueryAction = (name: string, query: string) => {
  return (dispatch: any) => {
    dispatch({
      type: SUPPORT_REDUCX_CONSTANTS.ON_CHANGE_QUERY,
      data: {
        name,
        query,
      },
    });
  };
};

export const raisequeryAction = (data: Record<string, string>) => {
  return async (dispatch: any) => {
    try {
      const response = await supportApiServices.raiseQuery(data);

      if (response?.status === 201) {
        successNotification(`Admin will reply you soon on your query`);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getQueryListAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await supportApiServices.queryList();
      if (response?.status === 200) {
        dispatch({
          type: SUPPORT_REDUCX_CONSTANTS.GET_QUERY,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
