import React ,{useEffect, useState} from 'react'
import { Accordion, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeEmailFetchOption, userProfileDetail, userProfileDetailImapConfig } from '../../store/actions/ProfileAction';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../components/input/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { addImapConfig } from '../../store/actions/invoiceActions/invoiceActions';
const UploadInvoice = () => {
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = useState({
    user: "",
    password: "",
    host: "",
    port: "",
    tls:true
  })
  const handleClickOpen = async () => {
    try {
      const imapConfig = await userProfileDetailImapConfig();
      
      if (imapConfig) {
        // Check if each attribute exists before setting it in the form state
        setForm({
          user: imapConfig.user || "",
          password: imapConfig.password || "",
          host: imapConfig.host || "",
          port: imapConfig.port || "",
          tls: imapConfig.tls || false, // Set a default value if 'tls' is missing
        });
        
        
      } else {
        // Handle the case where 'imapConfig' is not available
        // You can set default values for all form fields or display an error message
        // In this example, we set empty values for all fields
        setForm({
          user: "",
          password: "",
          host: "",
          port: "",
          tls: false,
        });
      }
      setOpen(true);
    } catch (error) {
      console.error('Error dispatching action:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch()

  const { profileMenuDetail, profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const handleOption = async(newOption:string) => {
    try {
      await dispatch(changeEmailFetchOption({ emailFetchOption: newOption }));
      await dispatch(userProfileDetail());
    } catch (error) {
      console.error('Error dispatching action:', error);
    }
  }

  const handleForm = (e: any) => {
    const { name, value ,checked,type} = e.target
    if (type == "checkbox") {
      setForm({...form,[name]:checked})
    } else {
    setForm({...form,[name]:value})
      
}
  }
  //console.log(form)
  const handleAdd = async() => {
    try {
      await dispatch(addImapConfig(form))
      setForm ({user: "",
      password: "",
      host: "",
      port: "",
      tls:true})
      setOpen(false)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div style={{margin:"30px"}}>
      <Button variant="contained"
            color={"primary"}
            onClick={handleClickOpen}
        >Add Email Configuration</Button>
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Configuration</DialogTitle>
          <DialogContent>
          <div className="form-fields-grid">
          <div>
            <div className="form-field-name">
              Email
              <div className="required-sign">*</div>
            </div>
            <Input
              variant="filled"
              placeholder="Enter email"
              className={`input-field`}
              name="user"
              id="user"
                  type="email"
                  value={form.user}
                  onChange={handleForm}
            />
              </div>
              <div>
            <div className="form-field-name">
              Password
              <div className="required-sign">*</div>
            </div>
            <Input
              variant="filled"
              placeholder="Enter password"
              className={`input-field`}
              name="password"
              id="password"
                  type="password"
                  value={form.password}
                  onChange={handleForm}
            />
              </div>
              <div>
            <div className="form-field-name">
              Port
              <div className="required-sign">*</div>
            </div>
            <Input
                  variant="filled"
                  placeholder="Enter port"
                  className={`input-field`}
                  name="port"
                  id="port"
                  type="text"
                  value={form.port}
                  onChange={handleForm}
            />
              </div>
              <div>
            <div className="form-field-name">
              Host
              <div className="required-sign">*</div>
            </div>
            <Input
              variant="filled"
              placeholder="Enter host"
              className={`input-field`}
              name="host"
              id="host"
                  type="text"
                  value={form.host}
                  onChange={handleForm}
            />
              </div>
              </div>
        </DialogContent>
          <DialogActions>
          <FormControlLabel control={<Checkbox onChange={handleForm}  checked={form.tls} name='tls' />} label="Tls" />
          <Button onClick={handleClose}>Cancel</Button> 
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      </div>
      <div style={{margin:"20px"}}>
        {/* Option 1 */}
        <div>
          <h1>Option 1: Automatic Invoicing Information Extraction</h1>
          <ul>
            <li>Helps to Save Time </li>
            <li>Helps to Reduce Error</li>
            <li>No steps needs to be done</li>
          </ul>
          <Button variant="contained"
            color={profileDetail.emailFetchOption==='option1'?"secondary":"primary"}
            onClick={()=>handleOption("option1")}
            >{profileDetail.emailFetchOption === 'option1' ? 'Selected' : 'Select Option'}</Button>
        </div>
        {/* Option 2 */}
        <div>
          <h1>Option 2: Email-based Invoice Upload</h1>
          <ul>
            <li>Helps to Save Time</li>
            <li>Helps to Reduce Error</li>
          </ul>
          <h3>Steps to follow:</h3>
          <ul>
            <li>Turn-on 2-step-authentication of your account</li>
            <li>Enable Imap </li>
            <li>Add host , port , user and password in your details</li>
          </ul>
          <Button variant="contained"
            color={profileDetail.emailFetchOption==='option2'?"secondary":"primary"}
            className="auth-modal-button"
            onClick={()=>handleOption("option2")}
            >{profileDetail.emailFetchOption === 'option2' ? 'Selected' : 'Select Option'}</Button>
        </div>
      </div>
      </>
  )
}

export default UploadInvoice