import {
  EditRounded,
  UploadRounded,
  VisibilityRounded,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { requestCredit } from '../../../../assets/images/requesCredit';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import CustomTable from '../../../../components/customTable/CustomTable';
import {
  invoiceTableProductsHeaderConstants,
  invoiceTableProductsMobileHeader,
} from '../../../../constants/invoiceconstants/invoiceConstants';
import { audConverter } from '../../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  fetchAttachment,
  getInvoiceDetailsByIdAction,
  getMapperDataAction,
  getModifiedInvoiceAction,
  getOrderDropdownDataAction,
  invoiceAcceptAction,
  invoicePendingAction,
  modifyInvoiceAction,
  onChangeUpdateStatusDataAction,
  resetInvoiceDetails,
  resetUpdateStatusDataAction,
  syncInvoiceAction,
} from '../../../../store/actions/invoiceActions/invoiceActions';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import Input from '../../../../components/input/Input';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import Loader from '../../../../components/loader/Loader';
import { desktopModificationIcon } from '../../../../assets/images/desktopModificationIcon';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import { MobileModyficationIcon } from '../../../../assets/images/mobileModificationIcon';
import { dateCalculationHelper } from '../../../../helpers/dateCalculationHelper';
import InventoryProductMappingModal from '../inventoryProductMappingModal/InventoryProductMappingModal';
import dayjs from 'dayjs';

const ViewInvoices = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const { invoiceId } = useParams<Record<string, string>>();
  const [orderNumberModal, setOrderNumberModal] = useState<boolean>(false);

  const { invoiceDetails, orderListDropdown, updateStatus } = useSelector(
    ({ invoiceReducer }: Record<string, any>) => invoiceReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const {
    invoiceDetailsLoaderAction,
    getModifiedDataLoaderAction,
    getMapperDataloaderAction,
    manualSyncInvoiceLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const onSaveData = () => {
    const finalData = {
      orderId: updateStatus?.orderId._id,
      deliveredBy: updateStatus?.deliveredBy
        ? updateStatus?.deliveredBy
        : undefined,
    };
    const isSubmitted: any = dispatch(
      invoiceAcceptAction(invoiceId, finalData, history)
    );
    if (isSubmitted) {
      dispatch(resetUpdateStatusDataAction());
    }
  };

  const modalButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOrderNumberModal(false);
        dispatch(resetUpdateStatusDataAction());
      },
      color: 'secondary',
    },
    {
      title: 'Save',
      onClick: () => onSaveData(),
      color: 'primary',
    },
  ];

  const onChangeData = (name: string, value: any) => {
    dispatch(onChangeUpdateStatusDataAction(name, value));
  };

  const onPendingClickAction = () => {
    dispatch(invoicePendingAction(invoiceId, history));
  };

  const modifyInvoice = () => {
    const data = {
      documentId: invoiceDetails.documentId,
      modifiedDocumentCount: invoiceDetails.modifiedDocumentCount,
    };
    dispatch(modifyInvoiceAction(invoiceId, data));
  };

  const getModifiedData = async () => {
    const success = await dispatch(
      getModifiedInvoiceAction(invoiceId, invoiceDetails.documentId)
    );
    if (success) {
      dispatch(getInvoiceDetailsByIdAction(invoiceId));
    }
  };

  const onAcceptAndSave = () => {
    dispatch(getOrderDropdownDataAction(invoiceDetails?.supplierId?._id));
    const success = dispatch(getMapperDataAction(invoiceId));
    success && setOrderNumberModal(true);
    onChangeData('status', 'Accepted');
  };

  const onSync = async () => {
    const success = await dispatch(syncInvoiceAction(invoiceId));
    if (success) {
      dispatch(getInvoiceDetailsByIdAction(invoiceId));
    }
  };

  useEffect(() => {
    dispatch(getInvoiceDetailsByIdAction(invoiceId));

    return () => {
      dispatch(resetInvoiceDetails());
    };
  }, []);

  const hasAccessOfCreditnote = profileDetail?.moduleAccess
    .find(
      (module: Record<string, string | boolean | string[]>) =>
        module.name === 'invoice'
    )
    .subModules.includes('credit-note');

  return (
    <div className="view-page-container">
      <div className="page-header">
        <div className="view-header-back-button-container">
          <BackButton
            onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE)}
          ></BackButton>
          <div className="form-title">Invoices Details</div>
        </div>
        <div className="button-container">
          {invoiceDetails.isAccountConnected &&
            !invoiceDetails?.isAutoSync &&
            !invoiceDetails?.isUploaded &&
            invoiceDetails?.status === 'Accepted' &&
            !invoiceDetails?.isFreezed && (
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                loader={manualSyncInvoiceLoaderAction}
                onClick={onSync}
              >
                <Tooltip arrow title="Click to sync invoice">
                  <UploadRounded />
                </Tooltip>
              </Button>
            )}
          {invoiceDetails?.status !== 'Accepted' &&
            invoiceDetails?.documentId &&
            !invoiceDetails?.isFreezed && (
              <Button
                className="list-icon-button desktop-button"
                variant="contained"
                color="primary"
                loader={getModifiedDataLoaderAction}
                onClick={() => getModifiedData()}
              >
                {desktopModificationIcon}
              </Button>
            )}
          {invoiceDetails?.status !== 'Accepted' &&
            !invoiceDetails?.documentId &&
            !invoiceDetails?.isFreezed && (
              <Button
                variant="contained"
                color="primary"
                className="desktop-button"
                onClick={() => history.push(`/invoices/list/edit/${invoiceId}`)}
              >
                Edit Invoice
              </Button>
            )}
          <Button
            variant="contained"
            color="primary"
            className="desktop-button"
            onClick={() => {
              window.open(invoiceDetails?.fileURL, '_blank');
            }}
          >
            View Actual Invoice
          </Button>
          {invoiceDetails?.status !== 'Accepted' &&
            invoiceDetails?.documentId &&
            !invoiceDetails?.isFreezed && (
              <Button
                variant="outlined"
                color="primary"
                className="desktop-button"
                onClick={() => modifyInvoice()}
              >
                Modify
              </Button>
            )}
          {invoiceDetails?.status !== 'Accepted' &&
            !invoiceDetails?.isFreezed &&
            hasAccessOfCreditnote && (
              <Button
                variant="outlined"
                color="primary"
                className="desktop-button"
                onClick={() => {
                  history.push(`/invoices/generateCreditNote/${invoiceId}`);
                }}
              >
                Request Credit
              </Button>
            )}
          {/* Mobile Buttons start here */}
          {invoiceDetails?.status !== 'Accepted' &&
            invoiceDetails?.documentId &&
            !invoiceDetails?.isFreezed && (
              <div className="mobile-button-container">
                <Button
                  className="list-icon-button"
                  variant="outlined"
                  color="primary"
                  onClick={() => getModifiedData()}
                >
                  <MobileModyficationIcon></MobileModyficationIcon>
                </Button>
              </div>
            )}
          {invoiceDetails?.status !== 'Accepted' && !invoiceDetails?.isFreezed && (
            <div className="mobile-button-container">
              <Button
                className="form-icon-button"
                variant="outlined"
                color="primary"
                onClick={() =>
                  invoiceDetails?.documentId
                    ? modifyInvoice()
                    : history.push(`/invoices/list/edit/${invoiceId}`)
                }
              >
                <EditRounded />
              </Button>
            </div>
          )}
          <div className="mobile-button-container">
            <Button
              className="form-icon-button"
              variant="outlined"
              color="primary"
              onClick={() => {
                window.open(invoiceDetails?.fileURL, '_blank');
              }}
            >
              <VisibilityRounded />
            </Button>
          </div>

          {invoiceDetails?.status !== 'Accepted' && !invoiceDetails?.isFreezed && (
            <div className="mobile-button-container">
              <Button
                className="form-icon-button"
                variant="outlined"
                color="primary"
                onClick={() => {}}
              >
                {requestCredit}
              </Button>
            </div>
          )}
          {/* mobile button end here */}
        </div>
      </div>
      {invoiceDetailsLoaderAction ? (
        <Loader />
      ) : (
        <div className="list-container">
          <div>
            <div className="details-block-title">Invoice details</div>
            <div className="view-details-white-container details-grid details-grid-three-columns">
              <div>
                <div className="detail-label">Supplier</div>
                <div className="detail-value">
                  {invoiceDetails?.supplierId?.supplierName || '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Email</div>
                <div className="detail-value">
                  {invoiceDetails?.supplierId?.supplierEmail || '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Invoice Status</div>
                <div className="detail-value">
                  <div
                    className={`status-tag ${invoiceDetails?.status?.toLowerCase()}`}
                  >
                    {invoiceDetails?.status || '-'}
                  </div>
                </div>
              </div>
              <div>
                <div className="detail-label">Invoice Accepted By</div>
                <div className="detail-value">
                  {invoiceDetails.checkedBy || '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Order Date</div>
                <div className="detail-value">
                  {invoiceDetails?.orderId?.orderDate
                    ? dayjs(
                        dateCalculationHelper(
                          invoiceDetails?.orderId?.orderDate,
                          profileDetail.timeZoneOffSet
                        )
                      ).format('DD MMMM YYYY')
                    : '-'}
                </div>
              </div>
              <div>
                <div className="detail-label">Invoice ID</div>
                <div className="detail-value">
                  {invoiceDetails?.invoiceNumber || '-'}
                </div>
              </div>
            </div>

            <div className="main-details-container">
              <div className="details-block-title">Line items</div>
              {invoiceDetails && (
                <CustomTable
                  headers={invoiceTableProductsHeaderConstants}
                  mobileHeaders={invoiceTableProductsMobileHeader}
                  data={invoiceDetails?.products ?? []}
                  isPagination
                />
              )}
            </div>
            <div className="invoices-total-container">
              <div className="total-container">
                <div className="sub-total-container">
                  <div>
                    <span>Sub Total</span>
                    <span className="invoice-total-value">
                      {audConverter(invoiceDetails?.netTotal)}
                    </span>
                  </div>
                  <div>
                    <span>{`GST`}</span>
                    <span className="invoice-total-value">
                      {audConverter(invoiceDetails?.taxAmount)}
                    </span>
                  </div>
                </div>
                <div>
                  <span>Total</span>
                  <span className="invoice-total-value">
                    {audConverter(invoiceDetails?.totalAmount)}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-button-row bottom-padding">
              {invoiceDetails?.status !== 'Accepted' &&
                !invoiceDetails?.isFreezed && (
                  <>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE)
                      }
                    >
                      Cancel
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      loader={getMapperDataloaderAction}
                      onClick={() => onAcceptAndSave()}
                    >
                      Accept & Save
                    </Button>

                    {invoiceDetails?.status !== 'Pending' && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={onPendingClickAction}
                      >
                        Add to Pending
                      </Button>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
      )}
      {orderNumberModal && (
        <InventoryProductMappingModal setMapperModal={setOrderNumberModal} />
      )}
    </div>
  );
};

export default ViewInvoices;
