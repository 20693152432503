import BillingOverview from '../pages/account-settings/billingOverview/BillingOverview';
import InviteUser from '../pages/account-settings/users/inviteUser/InviteUser';
import Users from '../pages/account-settings/users/Users';
import ForgotPassword from '../pages/auth/forgotPassword/ForgotPassword';
import LoginPage from '../pages/auth/login/LoginPage';
import Plans from '../pages/auth/plans/Plans';
import Register from '../pages/auth/register/Register';
import SetOrResetPassword from '../pages/auth/setOrResetPassword/SetOrResetPassword';
import ChangePassword from '../pages/changePassword/ChangePassword';
import Dashboard from '../pages/dashboard/Dashboard';
import Invoices from '../pages/invoices/invoices/Invoices';
import ViewInvoices from '../pages/invoices/invoices/viewInvoices/ViewInvoices';
import DraftOrderDetails from '../pages/orders/draftOrderDetails/DraftOrderDetails';
import Orders from '../pages/orders/Orders';
import AddProduct from '../pages/suppliers/products/addProduct/AddProduct';
import Subscriber from '../pages/admin/subscriber/Subscriber';
import Products from '../pages/suppliers/products/Products';
import EditProfile from '../pages/profile-module/editProfile/EditProfile';
import ProfileDetailPage from '../pages/profile-module/viewProfile/ProfileDetailPage';
import AddRecipe from '../pages/recipeCost/addRecipe/AddRecipe';
import RecipeCost from '../pages/recipeCost/RecipeCost';
import RecipeCostOverview from '../pages/recipeCost/recipeCostOverview/RecipeCostOverview';
import AddSale from '../pages/sales/addSale/AddSale';
import Sales from '../pages/sales/Sales';
import AddSupplier from '../pages/suppliers/supplier/addSupplier/AddSupplier';
import Supplier from '../pages/suppliers/supplier/Supplier';
import ViewSupplier from '../pages/suppliers/supplier/viewSupplier/ViewSupplier';
import Gst from '../pages/admin/GST/Gst';
import AddGst from '../pages/admin/GST/addGst/AddGst';
import ApplyForCredit from '../pages/orders/applyForCredit/ApplyForCredit';
import Support from '../pages/support/Support';
import AddEditCategory from '../pages/suppliers/supplier/addEditCategory/AddEditCategories';
import AccountingSoftware from '../pages/account-settings/softwareIntegration/AccountingSoftware';
import CardDetail from '../pages/auth/payment/CardDetail';
import PaymentFailed from '../pages/auth/payment/paymentFailed/PaymentFailed';
import ViewUser from '../pages/account-settings/users/viewUser/ViewUser';
import AddNewCard from '../pages/account-settings/billingOverview/addNewCard/AddNewCard';
import AccountSoftware from '../pages/auth/account-software/AccountSoftware';
import AccountSoftwareIntegration from '../pages/auth/account-software/accountSoftwareIntegration/AccountSoftwareIntegration';
import ViewProduct from '../pages/suppliers/products/viewProduct/ViewProduct';
import Statements from '../pages/invoices/statements/Statements';
import Authenticatecode from '../pages/auth/ AuthenticateCode/ AuthenticateCode';
import Expenses from '../pages/invoices/expenses/Expenses';
import CreditNotes from '../pages/invoices/creditNotes/CreditNotes';
import ViewCreditNote from '../pages/invoices/creditNotes/viewCreditNote/ViewCreditNote';
import CreateNewOrder from '../pages/orders/createNewOrder/CreateNewOrder';
import Plan from '../pages/admin/plan/Plan';
import AddPlan from '../pages/admin/plan/addPlan/AddPlan';
import ViewPlan from '../pages/admin/plan/viewPlan/ViewPlan';
import PaymentDetails from '../pages/auth/payment/paymentDetails/PaymentDetails';
import UploadTermsAndPolicy from '../pages/admin/upload/uploadTermsAndPolicy';
import Reconcile from '../pages/invoices/Reconcile/Reconcile';
import { ViewSale } from '../pages/sales/viewSale/ViewSale';
import ChangePlan from '../pages/account-settings/billingOverview/changePlan/ChangePlan';
import { ResetAuthentication } from '../pages/auth/ResetAuthentication/ResetAuthentication';
import LinkExpired from '../pages/auth/linkExpiered/LinkExpired';
import AddCard from '../pages/auth/payment/addCard/AddCard';
import ViewSubscriber from '../pages/admin/subscriber/viewSubscriber/ViewSubscriber';
import ViewStatement from '../pages/invoices/statements/viewStatement/ViewStatement';
import BillingHistory from '../pages/account-settings/billingHistory/BillingHistory';
import CardList from '../pages/account-settings/billingOverview/cardList/CardList';
import PlanPaymentDetails from '../pages/account-settings/billingOverview/planPaymentdetails/PlanPaymentDetails';
import AddInvoice from '../pages/invoices/invoices/addInvoices/AddInvoice';
import Reports from '../pages/reports/reports/Reports';
import ReconciledStatement from '../pages/reports/reconciledStatement/ReconciledStatement';
import ProductInventory from '../pages/productInventory/ProductInventory';
import ViewProductInventory from '../pages/productInventory/viewProductInventory/ViewProductInventory';
import RecipeCategory from '../pages/recipeCost/recipeCategory/RecipeCategory';
import PlanPaymentFailed from '../pages/account-settings/billingOverview/planpaymentfailed/PlanPaymentFailed';
import SoftwareIntegration from '../pages/admin/SoftwareIntegration/AdminSoftwareIntegration';
import PaymentAwaiting from '../pages/auth/paymentAwaiting/PaymentAwaiting';
import UploadInvoice from '../pages/upload-invoice/UploadInvoice';

export const ROUTE_CONSTANTS_VARIABLE: Record<string, string> = {
  DASHBOARD: '/dashboard',
  REGISTER: '/register',
  LOGIN: '/login',
  OTP_SCREEN: '/authenticateCode/:userId',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  SET_OR_RESET_PASSWORD: '/password/:action',
  VIEW_PROFILE_DETAILS: '/profile',
  EDIT_PROFILE: '/profile/edit',
  ORDER: '/orders',
  CREATE_ORDER: '/orders/:action', // old one
  EDIT_ORDER: '/orders/edit-order/:action/:orderId',
  VIEW_ORDER_DETAILS: '/orders/:status/:orderId',
  APPLY_FOR_CREDIT: '/orders/:status/:orderId/apply-for-credit',
  SUPPLIER: '/suppliers/list',
  ADD_EDIT_CATEGORY: '/:module/add-edit-category',
  ADD_SUPPLIER: '/suppliers/list/:action',
  EDIT_SUPPLIER: '/suppliers/list/:action/:supplierId',
  VIEW_SUPPLIER: '/suppliers/list/view/:supplierId',
  INVOICE: '/invoices/list',
  ADD_INVOICE: '/invoices/list/:action',
  EDIT_INVOICE: '/invoices/list/:action/:invoiceId',
  VIEW_INVOICES: '/invoices/view/:invoiceId',
  CREDIT_NOTE: '/invoices/credit-notes',
  VIEW_CREDIT_NOTE: '/credit-note/:action/:creditId',
  GENERATE_CREDIT_NOTE_FROM_INVOICE: '/invoices/:action/:invoiceId',
  GENERATE_CREDIT_NOTE_FROM_ORDER: '/order/:action/:orderId',
  GENERATE_CREDIT_NOTE_UPLOADED: '/credit-note/:action',
  RECONCILE: '/invoices/reconcile',
  STATEMENT: '/invoices/statements',
  VIEW_STATEMENT: '/invoices/statements/view/:statementId',
  EXPENSE: '/invoices/expenses',
  PRODUCT: '/suppliers/products',
  ADD_PRODUCT: '/suppliers/products/:action/',
  VIEW_PRODUCT: '/suppliers/products/view/:productId',
  EDIT_PRODUCT: '/suppliers/products/:action/:productId',
  RECIPE: '/recipe-cost',
  RECIPE_COST_OVERVIEW: '/recipe-cost/overview/:recipeId',
  ADD_RECIPE: '/recipe-cost/action/:action',
  EDIT_RECIPE: '/recipe-cost/action/:action/:recipeId',
  ADD_EDIT_RECIPE_CATEGORY: '/recipe-cost/recipe-category',
  SALE: '/sales',
  ADD_SALE: '/sales/:action',
  EDIT_SALE: '/sales/:action/:saleId',
  VIEW_SALE: '/sales/view/:saleId',
  REPORTS: '/reports/chart',
  RECONCILED_STATEMENTS: '/reports/reconciled-statements/list',
  ACCOUNT_SETTINGS: '/account-settings/billing-history',
  BILLING_HISTORY: '/account-settings/billing-history',
  BILLING_OVERVIEW: '/account-settings/billing-overview',
  CHANGE_PLAN: '/account-settings/upgrade-plan',
  CARD_LIST: '/account-settings/upgrade-plan/card-details',
  PLAN_PAYMENT_DETAILS: '/account-settings/upgrade-plan/Payment-details',
  ADD_NEW_CARD: '/account-settings/billing-overview/new-card',
  SOFTWARE_INTEGRATION: '/account-settings/software-integration',
  USER: '/account-settings/users',
  ADD_USER: '/account-settings/users/:action',
  EDIT_USER: '/account-settings/users/:action/:userId',
  VIEW_USER: '/account-settings/users/view/:userId',
  SUPPORT: '/support',
  PLANS: '/plans/:userId',
  PAYMENT_DETAILS: '/payment-details/:userId',
  PAYMENT_FAILED: '/payment-failed',
  ACCOUNT_SOFTWARE: '/account-software/:userId',
  ACCOUNT_SOFTWARE_INTEGRATION: '/account-software-integration/:userId',
  CARD_DETAIL: '/card-details/:userId',
  ADD_CARD: '/card-details/:userId/add-card/',
  RESET_AUTHENTICATION: '/reset-2FA/',
  INVITE_USER_GOOGLE_AUTHENTICATION: '/google-authentication/:route/:userId',
  GOOGLE_AUTHENTICATION: '/google-authentication/:userId',
  LINK_EXPIRED: '/link-expired',
  PRODUCT_INVENTORY: '/product-inventory',
  VIEW_PRODUCT_INVENTORY: '/product-inventory/view/:productId',
  PLAN_PAYMENT_FAILED: '/account-settings/upgrade-plan/payment-failed',
  PAYMENT_AWAITING: '/payment-awaiting',
  UPLOAD_INVOICE: '/upload-option',
};

export const ADMIN_ROUTE_CONST: Record<string, string> = {
  SUBSCRIBERS: '/subscriber',
  VIEW_SUBSCRIBER: '/subscriber/view/:subscriberId',
  GST: '/gst',
  ADD_GST: '/gst/:action',
  EDIT_GST: '/gst/:action/:gstId',
  PLAN: '/plan',
  ADD_PLAN: '/plan/:action',
  EDIT_PLAN: '/plan/:action/:planId',
  VIEW_PLAN: '/plan/view/:planId',
  TERMS_AND_CONDITION: '/upload-terms-and-policy/',
  SOFTWARE_INTEGRATION: '/software-integration',
};

export interface RouteConstantsProps {
  path: string;
  authenticated: boolean;
  component?: React.ComponentType<any>;
  isEscapeRedirect?: boolean;
  module?: string[];
  submodule?: string[];
}

export const ROUTE_CONSTANTS: RouteConstantsProps[] = [
  {
    path: '/',
    authenticated: true,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.REGISTER,
    component: Register,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PLANS,
    component: Plans,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.CARD_DETAIL,
    component: CardDetail,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_CARD,
    component: AddCard,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PAYMENT_DETAILS,
    component: PaymentDetails,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PAYMENT_FAILED,
    component: PaymentFailed,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ACCOUNT_SOFTWARE,
    component: AccountSoftware,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ACCOUNT_SOFTWARE_INTEGRATION,
    component: AccountSoftwareIntegration,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.INVITE_USER_GOOGLE_AUTHENTICATION,
    component: ResetAuthentication,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.LINK_EXPIRED,
    component: LinkExpired,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.LOGIN,
    component: LoginPage,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.OTP_SCREEN,
    component: Authenticatecode,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.FORGOT_PASSWORD,
    component: ForgotPassword,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.SET_OR_RESET_PASSWORD,
    component: SetOrResetPassword,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.GOOGLE_AUTHENTICATION,
    component: ResetAuthentication,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.RESET_AUTHENTICATION,
    component: ResetAuthentication,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE_DETAILS,
    component: ProfileDetailPage,
    authenticated: true,
    module: ['profile'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_PROFILE,
    component: EditProfile,
    authenticated: true,
    module: ['profile'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.CHANGE_PASSWORD,
    component: ChangePassword,
    authenticated: true,
    module: ['auth'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.DASHBOARD,
    component: Dashboard,
    authenticated: true,
    module: ['dashboard'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ORDER,
    component: Orders,
    authenticated: true,
    module: ['order'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.CREATE_ORDER,
    component: CreateNewOrder,
    authenticated: true,
    module: ['order'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_ORDER,
    component: CreateNewOrder,
    authenticated: true,
    module: ['order'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_ORDER_DETAILS,
    component: DraftOrderDetails,
    authenticated: true,
    module: ['order'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.APPLY_FOR_CREDIT,
    component: ApplyForCredit,
    authenticated: true,
    module: ['order'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.SUPPLIER,
    component: Supplier,
    authenticated: true,
    module: ['supplier'],
    submodule: ['supplier'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_SUPPLIER,
    component: ViewSupplier,
    authenticated: true,
    module: ['supplier'],
    submodule: ['supplier'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_EDIT_CATEGORY,
    component: AddEditCategory,
    authenticated: true,
    module: ['supplier', 'invoice'],
    submodule: ['supplier', 'invoice'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_SUPPLIER,
    component: AddSupplier,
    authenticated: true,
    module: ['supplier'],
    submodule: ['supplier'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_SUPPLIER,
    component: AddSupplier,
    authenticated: true,
    module: ['supplier'],
    submodule: ['supplier'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PRODUCT,
    component: Products,
    authenticated: true,
    module: ['supplier'],
    submodule: ['product'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_PRODUCT,
    component: ViewProduct,
    authenticated: true,
    module: ['supplier'],
    submodule: ['product'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_PRODUCT,
    component: AddProduct,
    authenticated: true,
    module: ['supplier'],
    submodule: ['product'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_PRODUCT,
    component: AddProduct,
    authenticated: true,
    module: ['supplier'],
    submodule: ['product'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_INVOICE,
    component: AddInvoice,
    authenticated: true,
    module: ['invoice'],
    submodule: ['invoice'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_INVOICE,
    component: AddInvoice,
    authenticated: true,
    module: ['invoice'],
    submodule: ['invoice'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.INVOICE,
    component: Invoices,
    authenticated: true,
    module: ['invoice'],
    submodule: ['invoice'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_INVOICES,
    component: ViewInvoices,
    authenticated: true,
    module: ['invoice'],
    submodule: ['invoice'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.CREDIT_NOTE,
    component: CreditNotes,
    authenticated: true,
    module: ['invoice'],
    submodule: ['credit-note'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.GENERATE_CREDIT_NOTE_FROM_INVOICE,
    component: ViewCreditNote,
    authenticated: true,
    module: ['invoice'],
    submodule: ['credit-note'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.GENERATE_CREDIT_NOTE_FROM_ORDER,
    component: ViewCreditNote,
    authenticated: true,
    module: ['invoice'],
    submodule: ['credit-note'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.GENERATE_CREDIT_NOTE_UPLOADED,
    component: ViewCreditNote,
    authenticated: true,
    module: ['invoice'],
    submodule: ['credit-note'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_CREDIT_NOTE,
    component: ViewCreditNote,
    authenticated: true,
    module: ['invoice'],
    submodule: ['credit-note'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.STATEMENT,
    component: Statements,
    authenticated: true,
    module: ['invoice'],
    submodule: ['statement'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_STATEMENT,
    component: ViewStatement,
    authenticated: true,
    module: ['invoice'],
    submodule: ['statement'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.RECONCILE,
    component: Reconcile,
    authenticated: true,
    module: ['invoice'],
    submodule: ['reconcile'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EXPENSE,
    component: Expenses,
    authenticated: true,
    module: ['invoice'],
    submodule: ['expense'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PRODUCT_INVENTORY,
    component: ProductInventory,
    authenticated: true,
    module: ['product-inventory'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_PRODUCT_INVENTORY,
    component: ViewProductInventory,
    authenticated: true,
    module: ['product-inventory'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_RECIPE,
    component: AddRecipe,
    authenticated: true,
    module: ['recipe'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.RECIPE,
    component: RecipeCost,
    authenticated: true,
    module: ['recipe'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.RECIPE_COST_OVERVIEW,
    component: RecipeCostOverview,
    authenticated: true,
    module: ['recipe'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_RECIPE,
    component: AddRecipe,
    authenticated: true,
    module: ['recipe'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_EDIT_RECIPE_CATEGORY,
    component: RecipeCategory,
    authenticated: true,
    module: ['recipe'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.SALE,
    component: Sales,
    authenticated: true,
    module: ['sale'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_SALE,
    component: ViewSale,
    authenticated: true,
    module: ['sale'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_SALE,
    component: AddSale,
    authenticated: true,
    module: ['sale'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_SALE,
    component: AddSale,
    authenticated: true,
    module: ['sale'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.REPORTS,
    component: Reports,
    authenticated: true,
    module: ['report'],
    submodule: ['purchase', 'sales', 'cost-sales', 'wages'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.RECONCILED_STATEMENTS,
    component: ReconciledStatement,
    authenticated: true,
    module: ['report'],
    submodule: ['reconciled-statements'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.BILLING_HISTORY,
    component: BillingHistory,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['billing-history'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.BILLING_OVERVIEW,
    component: BillingOverview,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['billing-overview'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_NEW_CARD,
    component: AddNewCard,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['billing-overview'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.CHANGE_PLAN,
    component: ChangePlan,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['billing-overview'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.CARD_LIST,
    component: CardList,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['billing-overview'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PLAN_PAYMENT_DETAILS,
    component: PlanPaymentDetails,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['billing-overview'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PLAN_PAYMENT_FAILED,
    component: PlanPaymentFailed,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['billing-overview'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.SOFTWARE_INTEGRATION,
    component: AccountingSoftware,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['software-integration'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.USER,
    component: Users,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['user'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.VIEW_USER,
    component: ViewUser,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['user'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.ADD_USER,
    component: InviteUser,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['user'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.EDIT_USER,
    component: InviteUser,
    authenticated: true,
    module: ['account-settings'],
    submodule: ['user'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.SUPPORT,
    component: Support,
    authenticated: true,
    module: ['support'],
  },
  {
    path: ADMIN_ROUTE_CONST.SUBSCRIBERS,
    component: Subscriber,
    authenticated: true,
    module: ['subscribers'],
  },
  {
    path: ADMIN_ROUTE_CONST.VIEW_SUBSCRIBER,
    component: ViewSubscriber,
    authenticated: true,
    module: ['subscribers'],
  },
  {
    path: ADMIN_ROUTE_CONST.TERMS_AND_CONDITION,
    component: UploadTermsAndPolicy,
    authenticated: true,
    module: ['uploadTAndC'],
  },
  {
    path: ADMIN_ROUTE_CONST.PLAN,
    component: Plan,
    authenticated: true,
    module: ['Plan'],
  },
  {
    path: ADMIN_ROUTE_CONST.VIEW_PLAN,
    component: ViewPlan,
    authenticated: true,
    module: ['Plan'],
  },
  {
    path: ADMIN_ROUTE_CONST.ADD_PLAN,
    component: AddPlan,
    authenticated: true,
    module: ['Plan'],
  },
  {
    path: ADMIN_ROUTE_CONST.EDIT_PLAN,
    component: AddPlan,
    authenticated: true,
    module: ['Plan'],
  },
  {
    path: ADMIN_ROUTE_CONST.GST,
    component: Gst,
    authenticated: true,
    module: ['GST(%)'],
  },
  {
    path: ADMIN_ROUTE_CONST.ADD_GST,
    component: AddGst,
    authenticated: true,
    module: ['GST(%)'],
  },
  {
    path: ADMIN_ROUTE_CONST.EDIT_GST,
    component: AddGst,
    authenticated: true,
    module: ['GST(%)'],
  },
  {
    path: ADMIN_ROUTE_CONST.SOFTWARE_INTEGRATION,
    component: SoftwareIntegration,
    authenticated: true,
    module: ['software-integration'],
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.PAYMENT_AWAITING,
    component: PaymentAwaiting,
    authenticated: false,
  },
  {
    path: ROUTE_CONSTANTS_VARIABLE.UPLOAD_INVOICE,
    component: UploadInvoice,
    authenticated: true,
  },
];
