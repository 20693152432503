import { Add, Delete, Sync } from '@mui/icons-material';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { importIcon } from '../../../assets/images/action-items/importIcon';
import { scanIcon } from '../../../assets/images/action-items/scanIcon';
import { filterIcon } from '../../../assets/images/filter';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import {
  deleteExpenseAction,
  expenseAutoSyncAction,
  expenseManualSyncAction,
  exportExpenseExcelsheet,
  exportExpensePdf,
  getExpenseListAction,
  getExpneseSupplierDropdown,
  getModifiedDataAction,
  getModifiedUploadedExpenseAction,
  getUploadedExpenseResponse,
  modifyExpenseAction,
  modifyUploadedExpenseAction,
  updateStatusAction,
} from '../../../store/actions/invoiceActions/expenseAction';
import { INVOICE_URLS } from '../../../constants/urlConstants';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import { errorNotification } from '../../../components/notifyHelper';
import FilterModal from '../../../components/filterModal/FilterModal';
import { expenseStatusDropdown } from '../../../constants/invoiceconstants/expenseConstants';
import { getCategories } from '../../../store/actions/commonAction';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import { dateLableHelper } from '../../../helpers/dateLabelHelper';
import _ from 'lodash';
import { audConverter } from '../../../constants/regexConstants';
import { levelOneRole } from '../../../constants/levelOneRoleConstants';


const Expenses = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
 
  const [paidExpense, setPaidExpense] = useState(0);
  const [unpaidExpense, setUnpaidExpense] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [rowId, setRowId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [figureDate, setFigureDate] = useState('');
  const [statusConfirmationModal, setStatusConfirmationModal] = useState(false);
  const [expenseData, setExpenseData] = useState<Record<string, any>>({});

  const { expenseList, supplier } = useSelector(
    ({ expenseReducer }: Record<string, any>) => expenseReducer ?? {}
  );
  const { expenseListLoaderAction, expenseSyncLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { expense } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const { docs, headers, page, pages, limit, total } = useMemo(
    () => expenseList ?? {},
    [expenseList]
  );

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const mobileHeaders = ['invoiceNumber', 'invoiceDate'];

  const centerTablefields = ['isUploaded'];

  const exportToExcel = () => {
    dispatch(
      exportExpenseExcelsheet({
        ...filters,
      })
    );
  };

  const exportToPdf = () => {
    dispatch(
      exportExpensePdf({
        ...filters,
      })
    );
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
  ];
 

  const onPageChange = (page: any) => {
    dispatch(
      getExpenseListAction({
        page: page,
        limit,
        ...filters,
      })
    );
  };

  const onChangeLimit = (value: any) => {
    dispatch(
      getExpenseListAction({
        page: 1,
        limit: value,
        ...filters,
      })
    );
  };

  const onChangeSwitch = async () => {
    const { _id, status } = expenseData;
    const changedStatus = status === 'Unpaid' ? 'Paid' : 'Unpaid';
    const updated = await dispatch(updateStatusAction(_id, changedStatus));
    if (updated) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
        ...filters,
      };
      dispatch(getExpenseListAction({ ...params }));
    }
    setExpenseData({});
    setStatusConfirmationModal(false);
  };

  const onClickDeleteConfirmation = () => {
    let allIds;
    if (rowId) {
      dispatch(deleteExpenseAction(rowId, afterSuccessfulDeletion));
    } else {
      allIds = checkedIds?.length > 1 ? checkedIds.join(',') : checkedIds[0];
      if (checkedIds.length !== 0) {
        dispatch(deleteExpenseAction(allIds, afterSuccessfulDeletion));
      } else {
        setOpenDeleteModal(false);
        errorNotification('Please select at least one row for deletion');
      }
    }
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setCheckedIds([]);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const statusChangeConfirmationModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setStatusConfirmationModal(false);
        setExpenseData({});
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onChangeSwitch,
      color: 'primary',
    },
  ];

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };
    dispatch(getExpenseListAction({ ...params }));
  };

  const selectAllCheckBox = () => {
    const allIds: string[] = [];
    docs.forEach((data: Record<string, any>) => {
      if (!data.isUploaded) {
        if (data.status !== 'Paid' && !data.isFreezed) allIds.push(data._id);
      }
    });
    if (docs?.length !== 0) {
      if (checkedIds?.length === allIds?.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(allIds);
      }
    }
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);
    }
  };

  const deleteTableRow = (val: string) => {
    setRowId(val);
    setOpenDeleteModal(true);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setCheckedIds([]);
  };

  const filterFields = [
    {
      name: 'billSupplier',
      label: 'Supplier',
      placeholder: 'Select Supplier',
      type: 'autocomplete',
      options: supplier,
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Select Status',
      type: 'autocomplete',
      options: expenseStatusDropdown,
      className: 'filter-field-gap',
    },
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];

  const filters = {
    billSupplier: expense?.billSupplier,
    status: expense?.status,
    startDate: expense?.startDate,
    endDate: expense?.endDate,
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getExpenseListAction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'expense',
      () => setOpenFilterModal(false),
      [getExpneseSupplierDropdown]
    );
  };

  const editExpense = async (expense: Record<string, any>) => {
    const data = {
      documentId: expense.documentId,
      modifiedDocumentCount: expense.modifiedDocumentCount,
    };
    const success = await dispatch(modifyExpenseAction(expense._id, data));
    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
        ...filters,
      };
      dispatch(getExpenseListAction({ ...params }));
    }
  };

  const getModifiedData = async (expense: Record<string, any>) => {
    const data = {
      documentId: expense.documentId.toString(),
    };
    const success = await dispatch(getModifiedDataAction(expense._id, data));
    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
        ...filters,
      };
      dispatch(getExpenseListAction({ ...params }));
    }
  };

  const onAutoSyncEnable = async (autoSync: boolean) => {
    const success = await dispatch(expenseAutoSyncAction(autoSync));
    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
        ...filters,
      };
      dispatch(getExpenseListAction({ ...params }));
    }
  };

  const syncItems = [
    {
      name: 'Auto sync',
      onClick: () => onAutoSyncEnable(true),
    },
    {
      name: 'Manual sync',
      onClick: () => onAutoSyncEnable(false),
    },
  ];

  const manualSyncRecord = async (expenseId: string) => {
    const success = await dispatch(expenseManualSyncAction(expenseId));
    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: paramLimit ?? limit ?? 15,
        ...filters,
      };

      dispatch(getExpenseListAction({ ...params }));
    }
  };

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: paramLimit ?? limit ?? 15,
    billSupplier: expense?.billSupplier,
    status: expense?.status,
    startDate: expense?.startDate
      ? new Date(expense?.startDate)?.toISOString()
      : undefined,
    endDate: expense?.endDate
      ? new Date(expense?.endDate)?.toISOString()
      : undefined,
  });

  useEffect(() => {
    const date = dateLableHelper(
      {
        startDate: expense?.startDate,
        endDate: expense?.endDate,
      },
      'expense',
      profileDetail.timeZoneOffSet
    );
    setFigureDate(date);
  }, [expense?.startDate, expense?.endDate]);

  useEffect(() => {
    let paidTotal = 0;
    let unpaidTotal = 0;
    let total = 0;
    docs?.map((expense: Record<string, any>) => {
      if (expense.status === 'Paid') {
        paidTotal += expense?.totalAmount;
      } else {
        unpaidTotal += expense?.totalAmount;
      }
      total += expense.totalAmount;
    });
    setPaidExpense(paidTotal);
    setUnpaidExpense(unpaidTotal);
    setTotalExpense(total);
  }, [docs]);

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };

    dispatch(getExpenseListAction({ ...params }));
    dispatch(resetfilterOnModuleChange('expenses'));
    dispatch(getExpneseSupplierDropdown());
    dispatch(getCategories());
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Expenses</div>
        <div className="button-container">
          {!_.isEmpty(expense) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(expense) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <CustomMenu
            className="action-menu"
            menuTitle={expenseList?.isAutoSync ? 'Auto Sync' : 'Manual Sync'}
            menuIcon="sync"
            menuItems={syncItems}
            id="invoice-action"
            disabled={!expenseList?.isAccountConnected}
            loader={expenseSyncLoaderAction}
          />
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal(true)}
            isFilterApplied={!_.isEmpty(expense)}
            filterData={expense}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="product-action"
          />{' '}
          {levelOneRole.includes(profileDetail.role) &&
            expenseList?.docs?.length > 0 && (
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={() => setOpenDeleteModal(true)}
              >
                <Delete />
              </Button>
            )}
        </div>
      </div>
      <div className="expense-list-container">
        <div className="sales-white-block-row white-card">
          <div className="white-block">
            <div className={figureDate.length > 0 ? 'sales-date-lable' : ''}>
              {figureDate}
            </div>
            <div>{audConverter(paidExpense ?? 0)}</div>
            <div>Paid</div>
          </div>
          <div className="white-block">
            <div className={figureDate.length > 0 ? 'sales-date-lable' : ''}>
              {figureDate}
            </div>
            <div>{audConverter(unpaidExpense ?? 0)}</div>
            <div>Unpaid</div>
          </div>
          <div className="white-block">
            <div className={figureDate.length > 0 ? 'sales-date-lable' : ''}>
              {figureDate}
            </div>
            <div>{audConverter(totalExpense ?? 0)}</div>
            <div>Expense Total</div>
          </div>
        </div>
        {expenseListLoaderAction ? (
          <Loader />
        ) : (
          <CustomTable
            headers={headers}
            mobileHeaders={mobileHeaders}
            centerTableFields={centerTablefields}
            data={docs}
            limit={limit}
            page={page}
            pages={pages}
            total={total}
            onPageChange={onPageChange}
            onChangeLimit={onChangeLimit}
            isCheckbox
            selectAllCheckBox={selectAllCheckBox}
            checkedIds={checkedIds}
            onChangeCheckBox={onChangeCheckBox}
            isAction
            isDeleteAction={
              levelOneRole.includes(profileDetail.role) ? true : false
            }
            deleteTableRow={deleteTableRow}
            editTableRow={(expense: Record<string, any>) =>
              editExpense(expense)
            }
            viewRecord={(expense: Record<string, any>) =>
              window.open(expense.fileURL, '_blank')
            }
            isGetModifiedData
            getModifiedData={(expense: Record<string, string>) =>
              getModifiedData(expense)
            }
            onChangeSwitch={(data) => {
              setStatusConfirmationModal(true);
              setExpenseData(data);
            }}
            primaryTextField="invoiceNumber"
            className="expense-list"
            isManualSync={
              !expenseList?.isAutoSync && expenseList?.isAccountConnected
            }
            syncRecord={(expenseId: string) => manualSyncRecord(expenseId)}
            listName="expense"
          />
        )}
      </div>

      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Expense"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}

      {openFilterModal && (
        <FilterModal
          requestFrom="expense"
          closeModal={() => setOpenFilterModal(false)}
          updateListAction={getExpenseListAction}
          filterFields={filterFields}
          defaultParams={{
            page: 1,
            limit: limit ?? paramLimit,
          }}
          isDateFilters
          filterAction={[getExpneseSupplierDropdown]}
        />
      )}

      {statusConfirmationModal && (
        <CustomModal
          className="delete-modal"
          header="Status Confirmation"
          buttons={statusChangeConfirmationModalButton}
          hideModal={() => {
            setStatusConfirmationModal(false);
            setExpenseData({});
          }}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to Change status?
          </span>
        </CustomModal>
      )}
    </div>
  );
};

export default Expenses;
