import { Dispatch } from 'react';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import { dashboardApiServices } from '../../services/pageServices/dashboardApiServices';
import { DASHBOARD_REDUX_CONSTANT } from '../reduxConstants/dashboardConstant';

type dispatch = Dispatch<{ type: string; data: Record<string, unknown> }>;

export const getPurchasependingInvoicesAction = () => {
  return async (dispatch: dispatch) => {
    try {
      const response = await dashboardApiServices.getPurchasePendingInvoices();
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_PENDING_INVOICES,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getPurchaseFoodAndBeveragesDetailsInDashboardAction = (params?: {
  category: string | undefined;
}) => {
  return async (dispatch: dispatch) => {
    try {
      const response =
        await dashboardApiServices.getPurchaseFoodAndBeveragesDetails(params);
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_FOOD_AND_BEVERAGES_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getPurchaseFoodAndBeveragesChartAction = (params?: {
  category: string | undefined;
}) => {
  return async (dispatch: dispatch) => {
    try {
      const response =
        await dashboardApiServices.getPurchaseFoodAndBeveragesChart(params);
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_FOOD_AND_BEVERAGES_CHART,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getPurchaseTopTenOrdersAction = (params: {
  category: string | undefined;
  duration: string;
}) => {
  return async (dispatch: dispatch) => {
    try {
      const response = await dashboardApiServices.getPurchaseTopTenOrders(
        params
      );

      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_TOP_TEN_ORDERS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getPurchaseTopTenSuppliersAction = (params: {
  duration: string;
  category: string | undefined;
}) => {
  return async (dispatch: dispatch) => {
    try {
      const response = await dashboardApiServices.getPurchaseTopTenSuppliers(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_TOP_TEN_SUPPLIERS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getPurchasePriceChangeDetailsAction = (params: {
  category: string | undefined;
}) => {
  return async (dispatch: dispatch) => {
    try {
      const response = await dashboardApiServices.getPurchasePriceChangeDetails(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_PRICE_CHANGES_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getSaleFoodAndBeveragesChartAction = (
  params?: Record<string, any>
) => {
  return async (dispatch: any) => {
    try {
      const response = await dashboardApiServices.getSaleFoodAndBeveragesChart(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_SALE_FOOD_AND_BEVERAGE_CHART,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getSaleFoodAndBeveragesDetailAction = (
  params?: Record<string, any>
) => {
  return async (dispatch: any) => {
    try {
      const response =
        await dashboardApiServices.getSaleFoodAndBeveragesDetails(params);
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_SALE_FOOD_AND_BEVERAGE_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getSaleSpendPerHeadAction = (params?: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      const response = await dashboardApiServices.getSaleSpendPerHeadDetails(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_SALE_SPEND_PER_HEAD_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const getDailySaleAction = (params?: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      const response = await dashboardApiServices.getDailySaleDetails(params);
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.GET_DAILY_SALE_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
