import { successNotification } from '../../components/notifyHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import signUpProcessHelper from '../../helpers/signUpProcessHelper';
import { paymentApiServices } from '../../services/pageServices/paymentApiServices';
import { PAYMENT_REDUX_CONSTANTS } from '../reduxConstants/paymentReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const getPaymentDetailsAction = (params: Record<string, string>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getPaymentDetailsLoaderAction');
      const response = await paymentApiServices.getPaymentDetails(params);
      if (response.status === 200) {
        const { paymentDetails } = response.data.data;
        dispatch({
          type: PAYMENT_REDUX_CONSTANTS.GET_PAYMENT_DETAILS,
          data: paymentDetails,
        });
        if (paymentDetails?.planDetails?.isPlanChanged) {
          successNotification(
            'Your plan details have been changed, click on pay now to proceed or else you can change the plan'
          );
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getPaymentDetailsLoaderAction');
    }
  };
};

export const proceedPaymentAction = (
  params: Record<string, string>,
  history: any
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('payNowLoaderAction');
      const response = await paymentApiServices.proceedPayment(params);
      if (response?.status === 200) {
        successNotification('Payment completed succesfully');
        const route = signUpProcessHelper(response.data.data);
        history.push(route);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('payNowLoaderAction');
    }
  };
};