import { Dispatch } from 'react';
import { successNotification } from '../../../components/notifyHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import billingOverviewApiServises from '../../../services/pageServices/accountSettingsServices/billingOverviewApiServisea';
import {
  accountInfoType,
  cardType,
} from '../../reducers/accountSettingsReducer/billingOverviewReducer';
import { BILLING_OVERVIEW_REDUX_CONSTANTS } from '../../reduxConstants/accountSettingsReduxConstants/billingOverviewReduxConstants';
import { AUTH_REDUX_CONSTANTS } from '../../reduxConstants/authReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getBillingOverviewDetailsAction = () => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('billingOverviewDetailsLoaderAction');
      const response =
        await billingOverviewApiServises.billingOverviewDetails();
      if (response.status === 200) {
        dispatch({
          type: BILLING_OVERVIEW_REDUX_CONSTANTS.GET_BILLING_OVERVIEW_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('billingOverviewDetailsLoaderAction');
    }
  };
};

export const onChangeAccountInfoAction = (
  name: string,
  value: string | boolean | Record<string, string | undefined> | undefined
) => {
  return (dispatch: any) => {
    dispatch({
      type: BILLING_OVERVIEW_REDUX_CONSTANTS.ON_CHANGE_ACCOUNT_INFO,
      data: {
        name,
        value,
      },
    });
  };
};

export const updateAccountInfoAction = (data: accountInfoType) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('updateAccountInfoLoaderAction');
      const response = await billingOverviewApiServises.updateAccountInfo(data);
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateAccountInfoLoaderAction');
    }
  };
};

export const cancelCurrentPlanAction = (cb: () => void) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('cancelCurrentPlanLoaderAction');
      const response = await billingOverviewApiServises.cancelcurrentPlan();
      if (response.status === 200) {
        successNotification(response.data.message);
        if (cb) {
          cb();
        }
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('cancelCurrentPlanLoaderAction');
    }
  };
};

export const deleteCardAction = (cardId: string) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('deleteCardLoaderAction');
      const response = await billingOverviewApiServises.deleteCard(cardId);
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('deleteCardLoaderAction');
    }
  };
};

export const onChangePaymentInfoAction = (data: cardType[]) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: BILLING_OVERVIEW_REDUX_CONSTANTS.UPDATE_PAYMENT_INFO,
      data,
    });
  };
};

export const changeDefalutCardAction = (cardId: string) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('changeDefaultcardLoaderAction');
      const response = await billingOverviewApiServises.changeDefalutCard(
        cardId
      );
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('changeDefaultcardLoaderAction');
    }
  };
};

export const getPlansListAction = (params?: Record<string, string>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getPlansLoaderAction');
      const response = await billingOverviewApiServises.getPlanListInBilling(
        params
      );
      dispatch({
        type: BILLING_OVERVIEW_REDUX_CONSTANTS.GET_PLAN_LIST,
        data: response.data.data,
      });
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getPlansLoaderAction');
    }
  };
};
export const getCardListAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await billingOverviewApiServises.getCardListInBilling();
      if (response.status === 200) {
        dispatch({
          type: BILLING_OVERVIEW_REDUX_CONSTANTS.GET_CARD_LIST_UPGRADE_PALN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const addCardActionInBilling = (
  data: Record<string, any>,
  cb: () => void
) => {
  return async () => {
    try {
      const response = await billingOverviewApiServises.addCardInBilling(data);
      if (response.status === 201) {
        successNotification('Card added successfully');
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addNewCardLoaderAction');
    }
  };
};

export const palnUpgradDetails = (varName: string, id: string) => {
  return (dispatch: any) => {
    dispatch({
      type: BILLING_OVERVIEW_REDUX_CONSTANTS.PLAN_UPGRADE_DETAILS,
      data: { varName, id },
    });
  };
};

export const getPaymentDetailActionInBilling = (
  params: Record<string, string>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getUpgradPlanPaymentDetailsLoaderAction');
      const response =
        await billingOverviewApiServises.getPaymentDetailInBilling(params);
      if (response.status === 200) {
        dispatch({
          type: BILLING_OVERVIEW_REDUX_CONSTANTS.GET_PAYMENT_DETAIL_UPGRADE_PLAN,
          data: response.data.data,
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getUpgradPlanPaymentDetailsLoaderAction'
      );
    }
  };
};

export const getPaymentStatusActionInBilling = (
  data: Record<string, string>,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('payNowLoaderAction');
      const response =
        await billingOverviewApiServises.getPaymentStatusInBilling(data);
      if (response?.status === 200) {
        successNotification('Payment completed succesfully');
        history.push(ROUTE_CONSTANTS_VARIABLE.BILLING_OVERVIEW);
        dispatch({
          type: AUTH_REDUX_CONSTANTS.PLAN_DETAILS_OF_USER,
          data: {},
        });
        dispatch({
          type: AUTH_REDUX_CONSTANTS.ADD_ON_DATA,
          data: { name: 'flow', value: undefined },
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('payNowLoaderAction');
    }
  };
};

export const resetPaymentDetailAction = () => {
  return (dispatch: any) => {
    dispatch({
      type: BILLING_OVERVIEW_REDUX_CONSTANTS.RESET_PAYMENT_DETAILS,
    });
  };
};
