export const desktopResetFilter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_14360_22003)">
      <path
        d="M21.7404 12.9881C21.5445 18.1966 17.2602 22.359 12.0038 22.359C6.60667 22.359 2.26019 18.0125 2.26019 12.6154C2.26019 7.23415 6.62256 2.87182 12.0038 2.87182V3.74102C12.0038 4.03746 12.3413 4.20752 12.5795 4.03104L14.8606 2.34133C15.0554 2.19705 15.0554 1.90558 14.8606 1.76126L12.5795 0.0715521C12.3413 -0.104885 12.0038 0.0651302 12.0038 0.361568V1.23077C5.71623 1.23077 0.619188 6.32782 0.619141 12.6154C0.619141 18.8876 5.73166 24 12.0038 24C18.1555 24 23.1675 19.1208 23.3813 13.0213C23.389 12.7998 23.2128 12.6154 22.9911 12.6154H22.1301C21.9214 12.6154 21.7482 12.7796 21.7404 12.9881Z"
        fill="white"
      />
      <path
        d="M22.9914 12.6154H22.1303C21.9216 12.6154 21.7484 12.7796 21.7406 12.9881C21.5447 18.1966 17.2605 22.359 12.004 22.359C10.149 22.359 8.41796 21.8455 6.94496 20.9523C5.70259 20.8176 4.5121 20.5106 3.39746 20.0557C5.48748 22.4694 8.57213 24 12.004 24C18.1557 24 23.1677 19.1209 23.3815 13.0214C23.3893 12.7998 23.213 12.6154 22.9914 12.6154Z"
        fill="white"
      />
      <path
        d="M7.05311 11.8658C7.05311 11.2246 6.51081 10.7029 5.84425 10.7029H5.01466C4.82055 10.7029 4.66323 10.8601 4.66309 11.0542L4.66211 14.1764C4.66211 14.3705 4.81947 14.5279 5.01367 14.5279C5.20788 14.5279 5.36523 14.3705 5.36523 14.1764V13.1901L6.43769 14.4086C6.50716 14.4876 6.60423 14.5279 6.70174 14.5279C6.78424 14.5279 6.86706 14.4991 6.93386 14.4403C7.07959 14.312 7.09375 14.0898 6.9655 13.9441L6.13056 12.9954C6.65931 12.8715 7.05311 12.4122 7.05311 11.8658ZM5.84425 12.3256C5.73391 12.3256 5.53895 12.3264 5.36542 12.3273L5.36598 11.4061H5.84425C6.11842 11.4061 6.34998 11.6166 6.34998 11.8658C6.34998 12.115 6.11842 12.3256 5.84425 12.3256Z"
        fill="white"
      />
      <path
        d="M9.49319 11.406C9.68739 11.406 9.84475 11.2486 9.84475 11.0544C9.84475 10.8603 9.68739 10.7029 9.49319 10.7029H8.17773C7.98353 10.7029 7.82617 10.8603 7.82617 11.0544V14.1764C7.82617 14.3706 7.98353 14.528 8.17773 14.528H9.49319C9.68739 14.528 9.84475 14.3706 9.84475 14.1764C9.84475 13.9823 9.68739 13.8248 9.49319 13.8248H8.5293V12.967H9.3962C9.59041 12.967 9.74777 12.8096 9.74777 12.6154C9.74777 12.4213 9.59041 12.2639 9.3962 12.2639H8.5293V11.406H9.49319Z"
        fill="white"
      />
      <path
        d="M15.789 11.406C15.9832 11.406 16.1406 11.2486 16.1406 11.0544C16.1406 10.8603 15.9832 10.7029 15.789 10.7029H14.4736C14.2794 10.7029 14.1221 10.8603 14.1221 11.0544V14.1764C14.1221 14.3706 14.2794 14.528 14.4736 14.528H15.789C15.9832 14.528 16.1406 14.3706 16.1406 14.1764C16.1406 13.9823 15.9832 13.8248 15.789 13.8248H14.8252V12.967H15.6921C15.8863 12.967 16.0437 12.8096 16.0437 12.6154C16.0437 12.4213 15.8863 12.2639 15.6921 12.2639H14.8252V11.406H15.789Z"
        fill="white"
      />
      <path
        d="M12.464 12.3102L12.3254 12.2591C11.9575 12.1238 11.7729 12.0559 11.6023 11.963C11.5019 11.9083 11.4965 11.8075 11.5022 11.7523C11.5139 11.6394 11.5951 11.4982 11.7881 11.4401C12.2492 11.3013 12.672 11.6371 12.6829 11.6458C12.8315 11.7692 13.0521 11.7496 13.1766 11.6016C13.3015 11.4529 13.2823 11.2312 13.1337 11.1062C13.1049 11.082 12.4184 10.5159 11.5853 10.7669C11.1539 10.8968 10.8467 11.2553 10.8029 11.6799C10.7637 12.0585 10.9412 12.4036 11.266 12.5805C11.4818 12.698 11.6952 12.7765 12.0827 12.919L12.2207 12.9699C12.3667 13.0237 12.7013 13.1771 12.6548 13.4379C12.6208 13.6281 12.3933 13.8248 12.0713 13.8248C11.7503 13.8248 11.441 13.695 11.2439 13.4775C11.1135 13.3336 10.8911 13.3227 10.7473 13.4531C10.6035 13.5834 10.5925 13.8058 10.7229 13.9497C11.051 14.3118 11.5551 14.5279 12.0714 14.5279C12.7105 14.5279 13.247 14.1215 13.347 13.5616C13.4218 13.1416 13.2293 12.5925 12.464 12.3102Z"
        fill="white"
      />
      <path
        d="M18.9868 10.7029H17.2627C17.0685 10.7029 16.9111 10.8603 16.9111 11.0544C16.9111 11.2486 17.0685 11.406 17.2627 11.406H17.7767V14.1764C17.7767 14.3706 17.934 14.528 18.1282 14.528C18.3224 14.528 18.4798 14.3706 18.4798 14.1764V11.406H18.9868C19.181 11.406 19.3384 11.2486 19.3384 11.0544C19.3384 10.8603 19.181 10.7029 18.9868 10.7029Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_14360_22003">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
