import {
  ALPHA_REGEX,
  ALPHA_WITH_MAXIMUM_LENGTH,
  ALPHA_WITH_MINIMUM_LENGTH,
  MOBILE_NUMBER_REGEX,
} from '../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  onChangeProfileDetail,
  updateProfileDetail,
} from '../../../store/actions/ProfileAction';

export const editProfileValidation = async (
  dispatch: any,
  profileDetail: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  history: any
) => {
  let validated = true;
  let errors: Record<string, string> = existingErrors ?? {};
  const errorMessages: Record<string, string> = {
    firstName: 'Pleae enter first name',
    lastName: 'Please enter last name',
    contactNumber: 'Please enter valid contact number',
  };

  const checkValidations = (field: string, value: string) => {
    switch (field) {
      case 'firstName':
      case 'lastName':
        if (!value?.toString()?.trim().length) {
          validated = false;
          errors[field] = errorMessages[field];
        } else if (!ALPHA_REGEX.test(value)) {
          validated = false;
          errors[field] = `Only enter letters. Not a valid entry`;
        } else if (!ALPHA_WITH_MINIMUM_LENGTH.test(value)) {
          validated = false;
          errors[field] = `Must contain atleast two letters`;
        } else if (!ALPHA_WITH_MAXIMUM_LENGTH.test(value)) {
          validated = false;
          errors[field] = `Maximum 50 characters allowed`;
        } else {
          delete errors?.[field];
        }
        break;

      case 'contactNumber':
        if (
          !value?.toString()?.trim()?.length ||
          !MOBILE_NUMBER_REGEX.test(value)
        ) {
          validated = false;
          errors.contactNumber = errorMessages[field];
        } else {
          delete errors?.[field];
        }
    }
  };
  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in profileDetail) {
        const value = profileDetail[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, profileDetail[fieldName]);
    }
    dispatch(onChangeProfileDetail('errors', errors));
  }
  if (validated && requestFrom === 'onSubmit') {
    const finalData = profileDetail;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'profileURL':
            finalData[key] = profileDetail?.profileURL
              ? profileDetail?.profileURL
              : null;
            break;

          case 'firstName':
          case 'lastName':
          case 'email':
          case 'contactNumber':
            finalData[key] = finalData[key].toString()?.trim();
            break;
        }
      }
    }

    dispatch(onChangeProfileDetail('tempProfileURL', null));
    await dispatch(updateProfileDetail(finalData));
    history.push(ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE_DETAILS);
  }
};
