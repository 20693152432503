import { errorNotification } from '../components/notifyHelper';
import { getAuthTokenFromLocalStorage } from './localstoragehelper';

export const displayErrors = (e: any) => {
  if (e?.code === 'ECONNABORTED') {
    errorNotification(
      'Request Timeout, Make sure you are connected to network.'
    );
  } else if (e.response?.status === 401) {
    errorNotification(
      e?.response?.data?.message ===
        'Plan is expired, contact your admin to renew your plan'
        ? e?.response?.data?.message
        : 'You have been logged out due to inactivity. Please log in to continue'
    );
  } else if (e?.response?.data?.meta?.flow === 3) {
    return;
  } else {
    if (e.message === 'Previous same call cancellation') {
      return;
    } else {
      errorNotification(
        e?.response?.data?.message ??
          e?.message ??
          'Something went wrong, please try again later'
      );
      throw e;
    }
  }
};
