export const mobileResetFilter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_14360_22016)">
      <path
        d="M21.7399 12.9881C21.544 18.1966 17.2597 22.359 12.0033 22.359C6.60618 22.359 2.2597 18.0125 2.2597 12.6154C2.2597 7.23415 6.62207 2.87182 12.0033 2.87182V3.74102C12.0033 4.03746 12.3408 4.20752 12.579 4.03104L14.8601 2.34133C15.0549 2.19705 15.0549 1.90558 14.8601 1.76126L12.579 0.0715521C12.3408 -0.104885 12.0033 0.0651302 12.0033 0.361568V1.23077C5.71575 1.23077 0.618699 6.32782 0.618652 12.6154C0.618652 18.8876 5.73117 24 12.0033 24C18.155 24 23.167 19.1208 23.3808 13.0213C23.3886 12.7998 23.2123 12.6154 22.9906 12.6154H22.1296C21.9209 12.6154 21.7477 12.7796 21.7399 12.9881Z"
        fill="#F21D86"
      />
      <path
        d="M22.9909 12.6154H22.1298C21.9211 12.6154 21.7479 12.7796 21.7401 12.9881C21.5442 18.1966 17.26 22.359 12.0036 22.359C10.1485 22.359 8.41747 21.8455 6.94447 20.9523C5.7021 20.8176 4.51161 20.5106 3.39697 20.0557C5.48699 22.4694 8.57164 24 12.0036 24C18.1552 24 23.1673 19.1209 23.381 13.0214C23.3888 12.7998 23.2125 12.6154 22.9909 12.6154Z"
        fill="#F21D86"
      />
      <path
        d="M7.05262 11.8658C7.05262 11.2246 6.51032 10.7029 5.84376 10.7029H5.01417C4.82006 10.7029 4.66275 10.8601 4.66261 11.0542L4.66162 14.1764C4.66162 14.3705 4.81898 14.5279 5.01318 14.5279C5.20739 14.5279 5.36475 14.3705 5.36475 14.1764V13.1901L6.4372 14.4086C6.50667 14.4876 6.60375 14.5279 6.70125 14.5279C6.78375 14.5279 6.86657 14.4991 6.93337 14.4403C7.07911 14.312 7.09326 14.0898 6.96501 13.9441L6.13007 12.9954C6.65882 12.8715 7.05262 12.4122 7.05262 11.8658ZM5.84376 12.3256C5.73342 12.3256 5.53847 12.3264 5.36493 12.3273L5.3655 11.4061H5.84376C6.11793 11.4061 6.3495 11.6166 6.3495 11.8658C6.3495 12.115 6.11793 12.3256 5.84376 12.3256Z"
        fill="#F21D86"
      />
      <path
        d="M9.49221 11.406C9.68641 11.406 9.84377 11.2486 9.84377 11.0544C9.84377 10.8603 9.68641 10.7029 9.49221 10.7029H8.17676C7.98255 10.7029 7.8252 10.8603 7.8252 11.0544V14.1764C7.8252 14.3706 7.98255 14.528 8.17676 14.528H9.49221C9.68641 14.528 9.84377 14.3706 9.84377 14.1764C9.84377 13.9823 9.68641 13.8248 9.49221 13.8248H8.52832V12.967H9.39523C9.58943 12.967 9.74679 12.8096 9.74679 12.6154C9.74679 12.4213 9.58943 12.2639 9.39523 12.2639H8.52832V11.406H9.49221Z"
        fill="#F21D86"
      />
      <path
        d="M15.789 11.406C15.9832 11.406 16.1406 11.2486 16.1406 11.0544C16.1406 10.8603 15.9832 10.7029 15.789 10.7029H14.4736C14.2794 10.7029 14.1221 10.8603 14.1221 11.0544V14.1764C14.1221 14.3706 14.2794 14.528 14.4736 14.528H15.789C15.9832 14.528 16.1406 14.3706 16.1406 14.1764C16.1406 13.9823 15.9832 13.8248 15.789 13.8248H14.8252V12.967H15.6921C15.8863 12.967 16.0437 12.8096 16.0437 12.6154C16.0437 12.4213 15.8863 12.2639 15.6921 12.2639H14.8252V11.406H15.789Z"
        fill="#F21D86"
      />
      <path
        d="M12.4635 12.3102L12.3249 12.2591C11.9571 12.1238 11.7724 12.0559 11.6018 11.963C11.5014 11.9083 11.496 11.8075 11.5018 11.7523C11.5134 11.6394 11.5946 11.4982 11.7876 11.4401C12.2488 11.3013 12.6715 11.6371 12.6824 11.6458C12.831 11.7692 13.0516 11.7496 13.1761 11.6016C13.3011 11.4529 13.2818 11.2312 13.1332 11.1062C13.1044 11.082 12.4179 10.5159 11.5848 10.7669C11.1534 10.8968 10.8463 11.2553 10.8024 11.6799C10.7632 12.0585 10.9407 12.4036 11.2655 12.5805C11.4813 12.698 11.6947 12.7765 12.0822 12.919L12.2202 12.9699C12.3662 13.0237 12.7008 13.1771 12.6543 13.4379C12.6203 13.6281 12.3928 13.8248 12.0708 13.8248C11.7498 13.8248 11.4405 13.695 11.2434 13.4775C11.113 13.3336 10.8906 13.3227 10.7468 13.4531C10.603 13.5834 10.592 13.8058 10.7224 13.9497C11.0505 14.3118 11.5546 14.5279 12.0709 14.5279C12.7101 14.5279 13.2465 14.1215 13.3465 13.5616C13.4213 13.1416 13.2288 12.5925 12.4635 12.3102Z"
        fill="#F21D86"
      />
      <path
        d="M18.9868 10.7029H17.2627C17.0685 10.7029 16.9111 10.8603 16.9111 11.0544C16.9111 11.2486 17.0685 11.406 17.2627 11.406H17.7767V14.1764C17.7767 14.3706 17.934 14.528 18.1282 14.528C18.3224 14.528 18.4798 14.3706 18.4798 14.1764V11.406H18.9868C19.181 11.406 19.3384 11.2486 19.3384 11.0544C19.3384 10.8603 19.181 10.7029 18.9868 10.7029Z"
        fill="#F21D86"
      />
    </g>
    <defs>
      <clipPath id="clip0_14360_22016">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
