import { errorNotification } from '../../../../components/notifyHelper';
import {
  ALPHA_REGEX,
  ALPHA_WITH_MAXIMUM_LENGTH,
  ALPHA_WITH_MINIMUM_LENGTH,
  EMAIL_ADDRESS_REGEX,
  MOBILE_NUMBER_REGEX,
} from '../../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  addUser,
  editUserData,
  onChangeUserData,
} from '../../../../store/actions/accountSettingsActions/accountSettingsUsersActions';
import { userProfileDetail } from '../../../../store/actions/ProfileAction';
import { cloneDeep } from 'lodash';

export const inviteUserValidations = async (
  dispatch: any,
  userDetail: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestForm: string,
  fieldName: string | undefined,
  setSendInvitationModal: React.Dispatch<React.SetStateAction<boolean>>,
  action: string,
  history: any,
  profileDetail?: Record<string, any>
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorName: Record<string, string> = {
    firstName: 'Please enter first name',
    lastName: 'Please enter last name',
    email: 'Please enter email',
    contactNumber: 'Please enter contact number',
    role: 'Please select role',
  };
  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'firstName':
      case 'lastName':
        if (!value?.toString()?.trim()?.length) {
          validated = false;
          errors[field] = errorName[field];
        } else if (!ALPHA_REGEX.test(value)) {
          validated = false;
          errors[field] = `Only enter letters. Not a valid entry`;
        } else if (!ALPHA_WITH_MINIMUM_LENGTH.test(value)) {
          validated = false;
          errors[field] = `Must contain atleast two letters`;
        } else if (!ALPHA_WITH_MAXIMUM_LENGTH.test(value)) {
          validated = false;
          errors[field] = `Maximum 50 characters allowed`;
        } else {
          delete errors?.[field];
        }
        break;

      case 'email':
        if (!EMAIL_ADDRESS_REGEX.test(value?.toString()?.trim())) {
          validated = false;
          errors.email = errorName[field];
        } else {
          delete errors.email;
        }
        break;

      case 'contactNumber':
        if (!MOBILE_NUMBER_REGEX.test(value?.toString()?.trim())) {
          validated = false;
          errors.contactNumber = errorName[field];
        } else {
          delete errors.contactNumber;
        }
        break;

      case 'firstName':
      case 'lastName':
      case 'role':
        if (
          !value?.toString()?.trim()?.length ||
          value == undefined ||
          value == null
        ) {
          validated = false;
          errors[field] = errorName[field];
        } else {
          delete errors[field];
        }
        break;
    }
  };
  if (isSubmittedOnce) {
    if (requestForm == 'onSubmit') {
      for (const key in userDetail) {
        const value = userDetail[key];
        if (key !== 'errors') checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, userDetail[fieldName]);
    }
    dispatch(onChangeUserData('errors', errors));
  }
  if (validated && requestForm === 'onSubmit') {
    const finalData: Record<string, any> = {};
    Object.keys(userDetail || {}).forEach((fieldKey = '') => {
      switch (fieldKey) {
        case 'moduleAccess':
          let submodule: string[] = [];
          const data = userDetail[fieldKey]
            .filter((module: any) => module.hasAccess)
            .map((module: any) => {
              module?.hasSubModule &&
                (submodule = module.subModules
                  .filter((submodule: any) => submodule.hasAccess)
                  .map((submodule: any) => {
                    return submodule.name;
                  }));
              return module.hasSubModule
                ? {
                    name: module.name,
                    hasSubModule: module.hasSubModule,
                    subModules: submodule,
                  }
                : {
                    name: module.name,
                    hasSubModule: module.hasSubModule,
                  };
            });
          finalData['moduleAccess'] = data;
          break;
        case 'email':
          finalData[fieldKey] = userDetail[fieldKey].toLowerCase();
          break;

        case 'isActive':
          finalData[fieldKey] = userDetail[fieldKey];
          break;

        default:
          if (
            ['string', 'boolean', 'number'].includes(
              typeof userDetail?.[fieldKey]
            )
          ) {
            finalData[fieldKey] =
              userDetail?.[fieldKey]?.toString()?.trim() || '';
          }
          break;
      }
    });
    let isAddedSuccessFully = false;
    if (action === 'add') {
      finalData.moduleAccess.length === 0
        ? errorNotification('Please select module/s')
        : (isAddedSuccessFully = await dispatch(addUser(finalData)));
      if (isAddedSuccessFully) {
        setSendInvitationModal(true);
      }
    } else {
      finalData.moduleAccess?.length === 0
        ? errorNotification('Please select module/s')
        : (isAddedSuccessFully = await dispatch(
            editUserData(
              finalData,
              userDetail._id,
              () => history.push(ROUTE_CONSTANTS_VARIABLE.USER),
              profileDetail
            )
          ));
      if (profileDetail?._id === userDetail._id && isAddedSuccessFully) {
        await dispatch(userProfileDetail());
      }
    }
  }
};
