import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  Icon,
  InputAdornment,
} from '@mui/material';
import { DatePicker,MobileDatePicker , LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useHistory, useParams } from 'react-router';
import CalenderIcon from '../../../../assets/images/CalenderIcon';
import BackButton from '../../../../components/backButton/BackButton';
import Button from '../../../../components/button/Button';
import CustomTable from '../../../../components/customTable/CustomTable';
import Input from '../../../../components/input/Input';
import {
  addInvoiceMobileHeader,
  addInvoiceProductTableHeader,
} from '../../../../constants/invoiceconstants/invoiceConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import BrowseFile from '../../../../components/browsFile/BrowseFile';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProductAction,
  getAddEditInvoiceDetails,
  getProductDropdownAction,
  getSupplierDropdownAction,
  onChangeInvoiceData,
  onChangeQuantityAction,
  onInvoiceProductPriceChange,
  removeUploadedInvoice,
  resetAddInvoiceAction,
  uploadCreateInvoice,
} from '../../../../store/actions/invoiceActions/invoiceActions';
import { addInvoiceValidation } from './addInvoiceValidation';
import { audConverter } from '../../../../constants/regexConstants';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import rightIcon from '../../../../assets/images/right-icon.svg';
import { focusHelper } from '../../../../helpers/focusHelper';
import _ from 'lodash';

const AddInvoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowId, setRowId] = useState<string>('');
  const [subTotal, setSubTotal] = useState(0);

  const { action, invoiceId } = useParams<Record<string, string>>();

  const { supplierDropdown, addEditInvoice, productDropdown } = useSelector(
    ({ invoiceReducer }: Record<string, any>) => invoiceReducer ?? {}
  );
  const { addInvoiceLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { errors } = addEditInvoice || {};

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangeInvoiceData(name, value));
    if (isSubmitted) {
      addInvoiceValidation(
        dispatch,
        { ...addEditInvoice, [name]: value },
        errors,
        isSubmitted,
        'onBlur',
        name,
        action,
        setIsSubmittedSuccessfully,
        history
      );
    }
  };

  const onSelectChange = (name: string, value: any) => {
    let finalValue = value;

    dispatch(onChangeInvoiceData(name, finalValue));
    if (name === 'supplierId') {
      dispatch(onChangeInvoiceData('products', []));
      value && dispatch(getProductDropdownAction(value?._id));
    }
    if (isSubmitted) {
      addInvoiceValidation(
        dispatch,
        { ...addEditInvoice, [name]: finalValue },
        errors,
        isSubmitted,
        'onBlur',
        name,
        action,
        setIsSubmittedSuccessfully,
        history
      );
    }
  };

  const onClickAddInvoice = async (action: string) => {
    setIsSubmitted(true);
    addInvoiceValidation(
      dispatch,
      addEditInvoice,
      errors,
      true,
      'onSubmit',
      undefined,
      action,
      setIsSubmittedSuccessfully,
      history
    );
    if (!_.isEmpty(errors)) {
      focusHelper(Object.keys(errors)?.[0]);
    }
  };

  const invoiceAddedConfirmationButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE);
      },
      color: 'primary',
    },
  ];

  const onClickDeleteConfirmation = () => {
    dispatch(deleteProductAction(rowId));
    setOpenDeleteModal(false);
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const onIncrementalChange = async (action: string, id: string) => {
    await dispatch(onChangeQuantityAction({ action, id }));
  };

  const onInputCellChange = (
    inputValue: string | number,
    productId: string
  ) => {
    dispatch(onInvoiceProductPriceChange({ inputValue, productId }));
  };

  useEffect(() => {
    let total = 0;
    addEditInvoice?.products?.length &&
      addEditInvoice?.products.forEach(
        (product: Record<string, any>) => (total += product?.amount)
      );
    dispatch(onChangeInvoiceData('netTotal', total));
  }, [addEditInvoice?.products]);

  useEffect(() => {
    const taxAmount =
      addEditInvoice.netTotal > 0 
        ? ((addEditInvoice?.gstPercentage ?? 10) * addEditInvoice.netTotal) /
          100
        : 0;

    dispatch(onChangeInvoiceData('taxAmount', taxAmount));
    dispatch(
      onChangeInvoiceData('totalAmount', addEditInvoice?.netTotal + taxAmount)
    );
  }, [addEditInvoice?.gstPercentage, addEditInvoice?.netTotal]);

  useEffect(() => {
    dispatch(getSupplierDropdownAction());
    if (action === 'edit') {
      dispatch(getAddEditInvoiceDetails(invoiceId));
    }

    return () => {
      dispatch(resetAddInvoiceAction());
    };
  }, []);

  return (
    <div className="form">
      <div className="form-title-switch-container">
        <div className="form-title-row">
          <BackButton
            onClick={() =>
              action === 'add'
                ? history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE)
                : history.push(`/invoices/view/${invoiceId}`)
            }
          />
          <div className="form-title">
            {action === 'add' ? 'Add' : 'Edit'} Invoice
          </div>
        </div>
      </div>

      <div className="form-container">
        {action === 'edit' && (
          <div className="invoice-title-container">
            <div className="details-block-title">Invoice Details</div>
            <div className="invoice-status-container">
              <div className="status-title">Invoice status</div>
              <span
                className={`status-tag ${addEditInvoice?.status?.toLowerCase()}`}
              >
                {addEditInvoice?.status}
              </span>
            </div>
          </div>
        )}
        <div className="form-fields-grid">
          <div>
            <div className="form-field-name">
              Invoice ID
              <div className="required-sign">*</div>
            </div>
            <Input
              variant="filled"
              placeholder="Enter invoice id"
              className={`input-field ${
                addEditInvoice?.errors?.invoiceNumber ? 'input-with-error' : ''
              }`}
              name="invoiceNumber"
              id="invoiceNumber"
              value={addEditInvoice.invoiceNumber}
              type="text"
              helperText={addEditInvoice?.errors?.invoiceNumber}
              onChange={onHandleChangeInput}
            />
          </div>
          <div>
            <div className="form-field-name">
              Date
              <div className="required-sign">*</div>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker 
                className={`inputDate ${
                  addEditInvoice?.errors?.invoiceDate ? 'input-with-error' : ''
                }`}
                label=""
                openTo="day"
                showToolbar={false}
                views={['year', 'month', 'day']}
                value={addEditInvoice?.invoiceDate}
                inputFormat="dd MMMM yyyy"
                disableFuture
                onChange={(e) => {
                  onSelectChange('invoiceDate', e);
                }}
                components={{ OpenPickerIcon: CalenderIcon }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <Icon>
                        <CalenderIcon />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                renderInput={(params: any) => (
                  <Input
                    variant="filled"
                    id="invoiceDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select Date',
                      readOnly: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {addEditInvoice?.errors?.invoiceDate && (
              <div className="error-message">
                {addEditInvoice.errors.invoiceDate}
              </div>
            )}
          </div>
          <div>
            <div className="form-field-name">
              Supplier
              <div className="required-sign">*</div>
            </div>
            <FormControl className={`input-field `}>
              <Autocomplete
                key={Math.random()}
                placeholder="Select Supplier"
                onChange={(event, value) => {
                  onSelectChange('supplierId', value);
                }}
                options={supplierDropdown}
                getOptionLabel={(option) =>
                  option?.name ?? option?.companyName ?? option ?? ''
                }
                value={addEditInvoice.supplierId}
                className="autocomplete"
                id="supplierId"
                renderInput={(params) => (
                  <Input
                    variant="filled"
                    {...params}
                    placeholder="Select Supplier"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <FormHelperText className="input-with-error">
                {addEditInvoice?.errors?.supplierId}
              </FormHelperText>
            </FormControl>
          </div>
          <div>
            <div className="form-field-name">
              Product
              <div className="required-sign">*</div>
            </div>
            <FormControl className={`input-field `}>
              <Autocomplete
                key={Math.random()}
                placeholder="Select Product"
                options={productDropdown}
                multiple
                disabled={!addEditInvoice?.supplierId}
                getOptionLabel={(option) => option?.name ?? option ?? ''}
                value={addEditInvoice?.products}
                isOptionEqualToValue={(option: any, value: any) =>
                  option._id === value._id
                }
                onChange={(event, value) => onSelectChange('products', value)}
                className="autocomplete"
                id="productId"
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      deleteIcon={<></>}
                      label={option?.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <Input
                    variant="filled"
                    {...params}
                    placeholder="Select Product"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <FormHelperText className="input-with-error">
                {addEditInvoice?.errors?.products}
              </FormHelperText>
            </FormControl>
          </div>
          <div>
            <div className="form-field-name">
              GST(%)<div className="required-sign">*</div>
            </div>
            <Input
              variant="filled"
              placeholder="Enter GST"
              className={`input-field ${
                addEditInvoice?.errors?.gstPercentage ? 'input-with-error' : ''
              }`}
              name="gstPercentage"
              id="gstPercentage"
              value={addEditInvoice?.gstPercentage ?? 10}
              type="text"
              helperText={addEditInvoice?.errors?.gstPercentage}
              onChange={onHandleChangeInput}
            />
          </div>
        </div>
        {addEditInvoice?.products?.length > 0 && (
          <>
            <div className="main-details-container">
              <div className="details-block-title">Line items</div>
              <CustomTable
                headers={addInvoiceProductTableHeader}
                mobileHeaders={addInvoiceMobileHeader}
                centerTableFields={['quantity', 'price']}
                data={addEditInvoice?.products ?? []}
                isPagination={false}
                isAction
                isEditable={false}
                isIncremental
                onIncrementalChange={onIncrementalChange}
                onInputCellChange={onInputCellChange}
                deleteTableRow={(productId: string) => {
                  setOpenDeleteModal(true);
                  setRowId(productId);
                }}
                listName="addInvoice"
                addTableClassName="add-table-container"
              />
            </div>
            <div className="invoices-total-container">
              <div className="total-container">
                <div className="sub-total-container">
                  <div>
                    <span>Sub Total</span>
                    <span className="invoice-total-value">
                      {audConverter(addEditInvoice?.netTotal ?? 0)}
                    </span>
                  </div>
                  <div>
                    <span>{`GST`}</span>
                    <span className="invoice-total-value">
                      {audConverter(addEditInvoice?.taxAmount)}
                    </span>
                  </div>
                </div>
                <div>
                  <span>Total</span>
                  <span className="invoice-total-value">
                    {audConverter(addEditInvoice?.totalAmount ?? 0)}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-button-row bottom-padding">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                loader={addInvoiceLoaderAction}
                onClick={() => onClickAddInvoice(action)}
              >
                {action === 'add' ? 'Add Invoice' : 'save'}
              </Button>
            </div>
          </>
        )}
      </div>
      {isSubmittedSuccessfully && (
        <CustomModal
          header="Invoice Added Confirmation"
          buttons={invoiceAddedConfirmationButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setIsSubmittedSuccessfully(false);
            history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>Invoice added successfully</span>
          </>
        </CustomModal>
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Product"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
    </div>
  );
};

export default AddInvoice;
