import {
  Autocomplete,
  Collapse,
  FormControl,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  AddRounded,
  EditRounded,
  RemoveRounded,
  DeleteRounded,
  VisibilityRounded,
  CancelRounded,
  UploadRounded,
} from '@mui/icons-material';
import { FC, useState } from 'react';
import { processTableDataByType } from '../../helpers/TableDataProcessHelper';
import CheckBox from '../checkbox/CheckBox';
import Pagination from '../pagination/Pagination';
import TableHeader from './tableHeader/TableHeader';
import Input from '../input/Input';
import Button from '../button/Button';
import { useSelector } from 'react-redux';
import { MobileModyficationIcon } from '../../assets/images/mobileModificationIcon';
import ButtonLoader from '../buttonLoader/ButtonLoader';
import { ReactComponent as RecipeHatIcon } from '../../assets/images/recipeHatIcon.svg';
import { levelOneRole } from '../../constants/levelOneRoleConstants';
import { NUMBER_DOT_REGEX } from '../../constants/regexConstants';

interface CustomTableProps {
  headers: Record<string, any>[];
  mobileHeaders: string[];
  centerTableFields?: string[];
  rightTableFields?: string[];
  data: any;
  total?: number;
  limit?: number;
  page?: number;
  pages?: number;
  sortID?: string;
  sortDesc?: string;
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onChangeLimit?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  onSortChange?: (sortID: string, sortDesc: string) => void;
  isCheckbox?: boolean;
  isAction?: boolean;
  isDeleteAction?: boolean;
  isIncremental?: boolean;
  onIncrementalChange?: (action: string, id: string) => void;
  onInputCellChange?: (value: string | number, id: string) => void;
  onChangeCheckBox?: (recordId: string) => void;
  onChangeData?: (name: string, id: string, value: string) => void;
  deleteTableRow?: (recordId: string) => void;
  cancelTableRow?: (record: Record<string, any>) => void;
  editTableRow?: (record: Record<string, any>) => void;
  viewRecord?: (record: Record<string, any>) => void;
  onClickRow?: (record: Record<string, any>) => void;
  isSortable?: boolean;
  isEditable?: boolean;
  isCancelable?: boolean;
  isManualSync?: boolean;
  syncRecord?: (recordId: string) => void;
  isGetModifiedData?: boolean;
  getModifiedData?: (record: Record<string, any>) => void;
  checkedIds?: Array<string>;
  selectAllCheckBox?: () => void;
  primaryTextField?: string;
  isPagination?: boolean;
  addTableClassName?: string;
  className?: string;
  listName?: string;
  onChangeSwitch?: (data: Record<string, any>) => void;
  message?: string;
}

interface TableRowProps {
  headers: Record<string, any>[];
  mobileHeaders: string[];
  centerTableFields?: string[];
  rightTableFields?: string[];
  data?: any;
  row: Record<string, any>;
  isCheckbox?: boolean;
  isAction?: boolean;
  isDeleteAction?: boolean;
  isIncremental?: boolean;
  onIncrementalChange?: (action: string, id: string) => void;
  onInputCellChange?: (value: string | number, id: string) => void;
  index: number;
  onChangeCheckBox: (recordId: string) => void;
  onChangeData?: (name: string, id: string, value: string) => void;
  editTableRow?: (record: Record<string, any>) => void;
  deleteTableRow?: (recordId: string) => void;
  cancelTableRow?: (record: Record<string, any>) => void;
  viewRecord?: (record: Record<string, any>) => void;
  onClickRow?: (record: Record<string, any>) => void;
  isEditable?: boolean;
  isCancelable?: boolean;
  isManualSync?: boolean;
  syncRecord?: (recordId: string) => void;
  isGetModifiedData?: boolean;
  getModifiedData?: (record: Record<string, any>) => void;
  checkedIds: Array<string>;
  openRow: Record<string, any>;
  setOpenRow: (val: Record<string, any>) => void;
  primaryTextField?: string;
  listName?: string;
  onChangeSwitch: (data: Record<string, any>) => void;
}

const Row: FC<TableRowProps> = (props) => {
  const {
    index,
    isCheckbox,
    centerTableFields,
    rightTableFields,
    isAction,
    isDeleteAction,
    isIncremental,
    onIncrementalChange,
    onInputCellChange,
    onChangeData,
    headers,
    row,
    data,
    mobileHeaders,
    onChangeCheckBox,
    editTableRow,
    deleteTableRow,
    cancelTableRow,
    viewRecord,
    onClickRow,
    isEditable,
    isCancelable,
    isManualSync,
    syncRecord,
    isGetModifiedData,
    getModifiedData,
    checkedIds,
    openRow,
    setOpenRow,
    primaryTextField,
    listName,
    onChangeSwitch,
  } = props;

  const isRowClickable = onClickRow;
  const loaderData = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );
  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const loaderKey = Object.keys(loaderData)?.[0];

  const collapsibleHeaders: any = headers?.length
    ? headers?.reduce(
        (prev, current) => ({
          ...prev,
          [current.name]: current.label,
        }),
        {}
      )
    : [];

  const checkBoxCell = () => {
    switch (listName) {
      case 'invoice':
      case 'statement':
      case 'expense':
        return (
          <CheckBox
            disabled={
              data[index].isUploaded === true ||
              data[index]?.status === 'Accepted' ||
              data[index]?.status === 'Reconciled' ||
              data[index]?.status === 'Paid' ||
              data[index]?.isFreezed
            }
            checked={checkedIds.includes(row.id)}
            onClick={(e) => {
              e.stopPropagation();
              onChangeCheckBox(row.id);
            }}
          />
        );
      default:
        return (
          <CheckBox
            disabled={data[index]?.isFreezed}
            checked={checkedIds.includes(row.id)}
            onClick={(e) => {
              e.stopPropagation();
              onChangeCheckBox(row.id);
            }}
          />
        );
    }
  };

  const onQuantityChange = (event: any, action: string) => {
    event.stopPropagation();

    if (onIncrementalChange) {
      onIncrementalChange(action, row.id);
    }
  };

  const onChange = (e: any, name: string, dropDownValue?: string) => {
    let data;
    if (name === 'selectedUnit') {
      data = dropDownValue;
    } else {
      const { value } = e.target;
      data = value;
    }

    if (onChangeData) {
      onChangeData(name, row.id, data);
    }
  };

  const onInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(
      () =>
        event.target.id && document.getElementById(event.target.id)?.focus(),
      100
    );

    const { name, value } = event.target;
   
    var data = value;
    if (data.length >= 2 && data[0] === "0" && data[1] !== ".") {
      // Remove the first character by slicing the string from the second character onward
      data = data.slice(1);
    }
    
    if(isNaN(parseFloat(value))) {
      NUMBER_DOT_REGEX.test("0") &&
      onInputCellChange &&
      onInputCellChange(parseFloat("0"), row.id);
    } else {
      NUMBER_DOT_REGEX.test(data) &&
      onInputCellChange &&
      onInputCellChange(data, row.id);
      
    }
    
  };

  const isEditVisible = () => {
    switch (listName) {
      case 'order':
        if (
          data[index]?.status === 'Completed' ||
          data[index]?.status === 'Received'
        ) {
          return false;
        } else if (
          data[index]?.status === 'Placed' &&
          !data[index]?.allowedToAddOn
        ) {
          return false;
        }
        return true;

      case 'creditNote':
        if (
          data[index]?.status === 'Approved' ||
          data[index]?.status === 'Rejected'
        ) {
          return false;
        }
        return true;

      case 'expense':
        if (data[index]?.status == 'Paid') {
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  const isCancelVisible = () => {
    switch (listName) {
      case 'subscirberActivePlans':
        return data[index]?.isCurrentPlan ? true : false;

      default:
        return false;
    }
  };

  const isSyncVisible = () => {
    switch (listName) {
      case 'invoice':
        return data[index]?.isUploaded || data[index]?.status !== 'Accepted'
          ? false
          : true;

      case 'statement':
        return data[index]?.isUploaded || data[index]?.status !== 'Reconciled'
          ? false
          : true;

      case 'expense':
        return data[index]?.isUploaded || data[index]?.status !== 'Paid'
          ? false
          : true;

      default:
        return false;
    }
  };

  const isgetModifyVisible = () => {
    switch (listName) {
      case 'expense':
        if (data[index]?.status === 'Paid') {
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  const isDeleteVisible = () => {
    switch (listName) {
      case 'invoice':
        return data[index]?.isUploaded || data[index]?.status === 'Accepted'
          ? false
          : true;

      case 'statement':
        return data[index]?.isUploaded || data[index]?.status === 'Reconciled'
          ? false
          : true;

      case 'expense':
        return data[index]?.isUploaded || data[index]?.status === 'Paid'
          ? false
          : true;

      default:
        return true;
    }
  };

  const checkAction = (data: Record<string, any>) => {
    if (listName === 'expense') {
      return data.status === 'Paid' ? true : false;
    } else if (listName === 'user') {
      return data.isActive === 'Active' ? true : false;
    }
  };

  const isDisableSwitch = (data: Record<string, any>) => {
    if (listName === 'expense') {
      return data.status === 'Paid' ? true : false;
    } else if (listName === 'user') {
      return levelOneRole.includes(profileDetail.role) ? false : true;
    }
  };

  return (
    <>
      <TableRow
        key={Math.random().toString()}
        className={`desktop-table-row ${
          isRowClickable ? 'cursor-pointer' : ''
        } ${
          !data[index]?.isInvoiceAmountMatched &&
          listName === 'reconcile' &&
          'red-border-table-row'
        } ${isCancelVisible() ? 'row-active' : ''}`}
        onClick={onClickRow ? () => onClickRow(row) : () => {}}
      >
        {isCheckbox && (
          <TableCell className="table-checkbox">{checkBoxCell()}</TableCell>
        )}

        {Object.entries(row).map(([key, value], i: any) => {
          switch (key) {
            case 'id':
              return null;

            case 'quantity':
              return (
                <TableCell
                  className={`desktop-cell  ${
                    centerTableFields?.includes('quantity')
                      ? 'table-field-text-center'
                      : ''
                  } ${key === primaryTextField ? 'font-primary' : ''}`}
                  key={`${key}-${i}`}
                >
                  {isIncremental ? (
                    <div className="quantity-cell">
                      <RemoveRounded
                        onClick={(e: any) => onQuantityChange(e, 'remove')}
                      />
                      <span className="quantity-cell-font">{value}</span>
                      <AddRounded onClick={(e) => onQuantityChange(e, 'add')} />
                    </div>
                  ) : (
                    value ?? '-'
                  )}
                </TableCell>
              );

            case 'price':
            case 'weight':
              return (listName === 'addInvoice' && key === 'price') ||
                (listName === 'addRecipe' && key === 'weight') ? (
                <TableCell
                  className={`desktop-cell table-field-text-center ${
                    key === primaryTextField ? 'font-primary' : ''
                  }`}
                  key={`${key}-${index}`}
                >
                  <Input
                    id={`${data[index]._id}${key}`}
                    key={key}
                    variant="filled"
                    className={` price-input-value input-field ${
                      data[index]?.isError ? 'input-with-error' : ''
                    }`}
                    name={key}
                    value={value}
                    onChange={onInputChange}
                    onBlur={() =>
                      onInputCellChange &&
                      onInputCellChange(parseFloat(value), row.id)
                    }
                    style={{ width: '97px' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="price-input-cell"
                        >
                          {key === 'price' ? '$' : ''}
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell
                  className={`desktop-cell ${
                    key === primaryTextField ? 'font-primary' : ''
                  } ${
                    centerTableFields?.includes(key)
                      ? 'table-field-text-center'
                      : ''
                  } ${
                    rightTableFields?.includes(key)
                      ? 'table-field-text-right'
                      : ''
                  }`}
                  key={`${key}-${i}`}
                >
                  {value ?? '-'}
                </TableCell>
              );

            case 'code':
              let code = `-`;

              if (data[row.id].state && data[row.id].postalCode) {
                code = `${data[row.id].state}/${data[row.id].postalCode}`;
              } else if (data[row.id].state) {
                code = `${data[row.id].state}/ - `;
              } else if (data[row.id].postalcode) {
                code = `- /${data[row.id].postalCode}`;
              }

              return value?.toString()?.length > 15 ? (
                <Tooltip enterDelay={1000} arrow title={value}>
                  <TableCell className={`desktop-cell`} key={`${key}-${i}`}>
                    {code ?? '-'}
                  </TableCell>
                </Tooltip>
              ) : (
                <TableCell className={`desktop-cell $`} key={`${key}-${i}`}>
                  {code ?? '-'}
                </TableCell>
              );

            case 'status':
            case 'companyName': 
              return  <td className="font-primary"><p style={{ cursor: 'pointer' }} onClick={() => viewRecord?.(data[index])} >{value}</p></td>;
            case 'isActive':
              return listName === 'expense' || listName === 'user' ? (
                <TableCell
                  className={`desktop-cell expense-status-container`}
                  key={`${key}-${i}`}
                >
                  <Switch
                    checked={checkAction(data[index])}
                    disabled={isDisableSwitch(data[index])}
                    size="small"
                    edge="start"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChangeSwitch(data[index]);
                    }}
                  />
                  {value ?? '-'}
                </TableCell>
              ) : (
                <TableCell
                  className={`desktop-cell ${
                    centerTableFields?.includes(key)
                      ? 'table-field-text-center'
                      : ''
                  } ${
                    rightTableFields?.includes(key)
                      ? 'table-field-text-right'
                      : ''
                  } `}
                  key={`${key}-${i}`}
                >
                  {value ?? '-'}
                </TableCell>
              );

            case 'name':
              let component = <></>;
              if (listName === 'addRecipe') {
                component = data[index].recipeId ? (
                  <div className="recipe-name-cell">
                    <RecipeHatIcon />
                    <span>{value ?? '-'}</span>
                  </div>
                ) : (
                  <div>
                    <img className="empty-recipe-icon" />
                    {value ?? '-'}
                  </div>
                );
              } else {
                component = <span>{value ?? '-'}</span>;
              }

              return value?.toString()?.length > 15 ? (
                <Tooltip enterDelay={1000} arrow title={value}>
                  <TableCell
                    className={`desktop-cell ${
                      key === primaryTextField ? 'font-primary' : ''
                    } ${
                      centerTableFields?.includes(key)
                        ? 'table-field-text-center'
                        : ''
                    } ${
                      rightTableFields?.includes(key)
                        ? 'table-field-text-right'
                        : ''
                    }`}
                    key={`${key}-${i}`}
                  >
                    {component}
                  </TableCell>
                </Tooltip>
              ) : (
                <TableCell
                  className={`desktop-cell ${
                    key === primaryTextField ? 'font-primary' : ''
                  } ${
                    centerTableFields?.includes(key)
                      ? 'table-field-text-center'
                      : ''
                  } ${
                    rightTableFields?.includes(key)
                      ? 'table-field-text-right'
                      : ''
                  }`}
                  key={`${key}-${i}`}
                >
                  {component}
                </TableCell>
              );

            default:
              return value?.toString()?.length > 15 ? (
                <Tooltip enterDelay={1000} arrow title={value}>
                  <TableCell
                    className={`desktop-cell ${
                      key === primaryTextField ? 'font-primary' : ''
                    } ${
                      centerTableFields?.includes(key)
                        ? 'table-field-text-center'
                        : ''
                    } ${
                      rightTableFields?.includes(key)
                        ? 'table-field-text-right'
                        : ''
                    }`}
                    key={`${key}-${i}`}
                  >
                    {value ?? '-'}
                  </TableCell>
                </Tooltip>
              ) : (
                <TableCell
                  className={`desktop-cell ${
                    key === primaryTextField ? 'font-primary' : ''
                  } ${
                    centerTableFields?.includes(key)
                      ? 'table-field-text-center'
                      : ''
                  } ${
                    rightTableFields?.includes(key)
                      ? 'table-field-text-right'
                      : ''
                  }`}
                  key={`${key}-${i}`}
                >
                  {value ?? '-'}
                </TableCell>
              );
          }
        })}

        {isAction && (
          <TableCell className="desktop-cell">
            {listName === 'paymentSubscriptionHistory' ? (
              <div className="action-cell">
                <Button
                  color="success"
                  variant="outlined"
                  className="success-outlined-button"
                  onClick={() => {}}
                >
                  Active
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  className="outlined-primary-button "
                  onClick={() => {}}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="action-cell">
                {isManualSync && isSyncVisible() && !data[index].isFreezed && (
                  <Tooltip arrow title="Click to Sync record">
                    {loaderKey === `${row.id}Sync` ? (
                      <ButtonLoader />
                    ) : (
                      <UploadRounded
                        onClick={() => syncRecord && syncRecord(row.id)}
                      />
                    )}
                  </Tooltip>
                )}
                {loaderKey == `${row.id}Modify` ? (
                  <ButtonLoader />
                ) : (
                  isGetModifiedData &&
                  !data[index].isFreezed &&
                  isgetModifyVisible() && (
                    <MobileModyficationIcon
                      onClick={() =>
                        getModifiedData && getModifiedData(data[index])
                      }
                    />
                  )
                )}
                {viewRecord && (
                  <Tooltip arrow title="Click to view record">
                    <VisibilityRounded
                      onClick={() => viewRecord?.(data[index])}
                    />
                  </Tooltip>
                )}
                {isEditable && isEditVisible() && !data[index].isFreezed && (
                  <Tooltip arrow title="Click to edit record">
                    <EditRounded
                      onClick={() => editTableRow && editTableRow(data[index])}
                    />
                  </Tooltip>
                )}
                {isDeleteAction && isDeleteVisible() && !data[index].isFreezed && (
                  <Tooltip arrow title="Click to delete record">
                    <DeleteRounded
                      onClick={() => deleteTableRow && deleteTableRow(row.id)}
                    />
                  </Tooltip>
                )}
                {isCancelable && isCancelVisible() && !data[index].isFreezed && (
                  <Tooltip arrow title="Click to cancel">
                    <Button
                      color="primary"
                      variant="outlined"
                      className="outlined-primary-button "
                      onClick={() =>
                        cancelTableRow && cancelTableRow(data[index])
                      }
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                )}
              </div>
            )}
          </TableCell>
        )}
      </TableRow>
      {/* mobile table row starts here */}
      <div
        className={`mobile-table-row ${
          index === 0 ? 'first-mobile-table-row' : ''
        } ${
          !data[index]?.isInvoiceAmountMatched &&
          listName === 'reconcile' &&
          'red-border-table-row'
        } ${isCancelVisible() ? 'row-active' : ''}`}
      >
        <div
          className={`table-row ${openRow === row.id ? 'collapsed-row' : ''} ${
            isRowClickable ? 'cursor-pointer' : ''
          }`}
          onClick={(e) => {
            setOpenRow(openRow === row.id ? -1 : row.id);
          }}
        >
          {isCheckbox && checkBoxCell()}
          {Object.entries(row).map(([key, value], i) => {
            if (mobileHeaders.includes(key)) {
              switch (key) {
                case 'quantity':
                  return isIncremental ? (
                    <div
                      key={Math.random().toString()}
                      className="quantity-cell"
                    >
                      <RemoveRounded
                        onClick={(e) => onQuantityChange(e, 'remove')}
                      />
                      <span className="quantity-cell-font">{value}</span>
                      <AddRounded onClick={(e) => onQuantityChange(e, 'add')} />
                    </div>
                  ) : (
                    <div
                      className={key === primaryTextField ? 'font-primary' : ''}
                      key={`${key}-${i}`}
                    >
                      {value?.toString()?.length > 20 ? (
                        <Tooltip arrow enterDelay={1000} title={value ?? '-'}>
                          <span>{value ?? '-'}</span>
                        </Tooltip>
                      ) : (
                        <span>{value ?? '-'}</span>
                      )}
                    </div>
                  );

                case 'name':
                  let cellValue = <></>;
                  if (listName === 'addRecipe') {
                    cellValue = data[index]?.recipeId ? (
                      <span className="recipe-name-cell">
                        <RecipeHatIcon />
                        <span>{value ?? '-'}</span>
                      </span>
                    ) : (
                      <span>{value ?? '-'}</span>
                    );
                  } else {
                    cellValue = <span>{value ?? '-'}</span>;
                  }
                  return (
                    <div
                      className={key === primaryTextField ? 'font-primary' : ''}
                      key={`${key}-${i}`}
                    >
                      {value?.toString()?.length > 20 ? (
                        <Tooltip arrow enterDelay={1000} title={value ?? '-'}>
                          {cellValue}
                        </Tooltip>
                      ) : (
                        cellValue
                      )}
                    </div>
                  );

                default:
                  return (
                    <div
                      className={`${
                        key === primaryTextField ? 'font-primary' : ''
                      } mobile-cell`}
                      key={`${key}-${i}`}
                    >
                      {value?.toString()?.length > 20 ? (
                        <Tooltip arrow enterDelay={1000} title={value ?? '-'}>
                          <span>{value ?? '-'}</span>
                        </Tooltip>
                      ) : (
                        <span>{value ?? '-'}</span>
                      )}
                    </div>
                  );
              }
            }
          })}
        </div>
        <div
          className={`table-collapse-row ${
            isRowClickable ? 'cursor-pointer' : ''
          }`}
          onClick={onClickRow ? () => onClickRow(row) : () => {}}
        >
          <div style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={openRow === row.id} timeout="auto" unmountOnExit>
              <div className="collapsed-table">
                {Object.entries(row).map(([key, value], i: any) => {
                  switch (key) {
                    case 'id':
                      return null;

                    case 'price':
                    case 'weight':
                      return (listName === 'addInvoice' && key === 'price') ||
                        (listName === 'addRecipe' && key === 'weight')
                        ? !mobileHeaders.includes(key) && (
                            <div className="table-row" key={`${key}-${index}`}>
                              <div>
                                <span>{collapsibleHeaders[key]}</span>
                              </div>
                              <div className="mobile-cell">
                                <Input
                                  variant="filled"
                                  className={`input-field ${
                                    data[index]?.isError
                                      ? 'input-with-error'
                                      : ''
                                  }`}
                                  name={key}
                                  value={value}
                                  type="text"
                                  onChange={onInputChange}
                                  onBlur={() =>
                                    onInputCellChange &&
                                    onInputCellChange(parseFloat(value), row.id)
                                  }
                                  style={{ width: '80px', height: '32px' }}
                                />
                              </div>
                            </div>
                          )
                        : !mobileHeaders.includes(key) && (
                            <div className="table-row" key={`${key}-${i}`}>
                              <div>
                                <span>{collapsibleHeaders[key]}</span>
                              </div>
                              <div className="mobile-cell">
                                <span>{value ?? '-'}</span>
                              </div>
                            </div>
                          );

                    case 'code':
                      let code = `-`;

                      if (data[row.id].state && data[row.id].postalCode) {
                        code = `${data[row.id].state}/${
                          data[row.id].postalCode
                        }`;
                      } else if (data[row.id].state) {
                        code = `${data[row.id].state}/ - `;
                      } else if (data[row.id].postalcode) {
                        code = `- /${data[row.id].postalCode}`;
                      }

                      return (
                        !mobileHeaders.includes(key) && (
                          <div className="table-row" key={`${key}-${i}`}>
                            <div>
                              <span>{collapsibleHeaders[key]}</span>
                            </div>
                            <div className="mobile-cell">
                              <span>{code ?? '-'}</span>
                            </div>
                          </div>
                        )
                      );

                    case 'status':
                    case 'isActive':
                      return listName === 'expense' || listName === 'user' ? (
                        <div className="table-row" key={`${key}-${i}`}>
                          <div>
                            <span>{collapsibleHeaders[key]}</span>
                          </div>
                          <div className="mobile-cell expense-status-container">
                            <span>
                              <Switch
                                checked={checkAction(data[index])}
                                disabled={isDisableSwitch(data[index])}
                                size="small"
                                edge="start"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onChangeSwitch(data[index]);
                                }}
                              />
                              {value ?? '-'}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="table-row" key={`${key}-${i}`}>
                          <div>
                            <span>{collapsibleHeaders[key]}</span>
                          </div>
                          <div className="mobile-cell">
                            <span>{value ?? '-'}</span>
                          </div>
                        </div>
                      );

                    default:
                      return (
                        !mobileHeaders.includes(key) && (
                          <div className="table-row" key={`${key}-${i}`}>
                            <div>
                              <span>{collapsibleHeaders[key]}</span>
                            </div>
                            <div className="mobile-cell">
                              <span>{value ?? '-'}</span>
                            </div>
                          </div>
                        )
                      );
                  }
                })}

                {isAction && (
                  <div className="table-row">
                    <div>Action</div>

                    <div
                      className={`mobile-cell mobile-action-cell ${
                        listName === 'paymentSubscriptionHistory'
                          ? 'subscriber-action-cell'
                          : ''
                      }`}
                    >
                      {listName === 'paymentSubscriptionHistory' ? (
                        <div className="action-cell">
                          <Button
                            color="success"
                            variant="outlined"
                            className="success-outlined-button"
                            onClick={() => {}}
                          >
                            Active
                          </Button>
                          <Button
                            color="primary"
                            variant="outlined"
                            className="outlined-primary-button "
                            onClick={() => {}}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <div className="action-cell">
                          {isManualSync &&
                            isSyncVisible() &&
                            !data[index].isFreezed && (
                              <Tooltip arrow title="Click to Sync record">
                                {loaderKey === `${row.id}Sync` ? (
                                  <ButtonLoader />
                                ) : (
                                  <UploadRounded
                                    onClick={() =>
                                      syncRecord && syncRecord(row.id)
                                    }
                                  />
                                )}
                              </Tooltip>
                            )}
                          {loaderKey == `${row.id}Modify` && loaderKey ? (
                            <ButtonLoader />
                          ) : (
                            isGetModifiedData &&
                            !data[index].isFreezed && (
                              <MobileModyficationIcon
                                onClick={() =>
                                  getModifiedData &&
                                  getModifiedData(data[index])
                                }
                              />
                            )
                          )}
                          {viewRecord && (
                            <Tooltip arrow title="Click to view record">
                              <VisibilityRounded
                                onClick={() => viewRecord?.(data[index])}
                              />
                            </Tooltip>
                          )}
                          {isEditable &&
                            isEditVisible() &&
                            !data[index].isFreezed && (
                              <Tooltip arrow title="Click to edit record">
                                <EditRounded
                                  onClick={() =>
                                    editTableRow && editTableRow(data[index])
                                  }
                                />
                              </Tooltip>
                            )}
                          {isCancelable &&
                            isCancelVisible() &&
                            !data[index].isFreezed && (
                              <Tooltip arrow title="Click to cancel">
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  className="outlined-primary-button "
                                  onClick={() =>
                                    cancelTableRow &&
                                    cancelTableRow(data[index])
                                  }
                                >
                                  Cancel
                                </Button>
                              </Tooltip>
                            )}

                          {isDeleteAction &&
                            isDeleteVisible() &&
                            !data[index].isFreezed && (
                              <Tooltip arrow title="Click to delete record">
                                <DeleteRounded
                                  onClick={() =>
                                    deleteTableRow && deleteTableRow(row.id)
                                  }
                                />
                              </Tooltip>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

const CustomTable: FC<CustomTableProps> = (props) => {
  const {
    headers,
    mobileHeaders,
    centerTableFields,
    rightTableFields,
    data,
    total,
    limit,
    page,
    pages,
    sortID,
    sortDesc,
    onPageChange,
    onChangeLimit,
    onSortChange,
    isCheckbox,
    isAction,
    isDeleteAction,
    isIncremental: isIncremental,
    onIncrementalChange,
    onInputCellChange,
    onChangeData,
    onChangeCheckBox,
    deleteTableRow,
    cancelTableRow,
    editTableRow,
    checkedIds,
    selectAllCheckBox,
    viewRecord,
    onClickRow,
    isSortable,
    isEditable,
    isCancelable,
    isManualSync,
    syncRecord,
    isGetModifiedData,
    getModifiedData,
    primaryTextField,
    isPagination,
    addTableClassName,
    className,
    listName,
    onChangeSwitch,
    message,
  } = props;
  const [open, setOpen] = useState<Record<string, any>>({});

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const tableData = data?.map((row: Record<string, any>, index: number) => {
    const finalObj: Record<string, any> = {
      id: row?._id ?? row?.id ?? index,
    };

    headers?.forEach((header) => {
      finalObj[`${header.name}`] = processTableDataByType({
        header: header,
        row: row,
        timeZoneOffSet: profileDetail.timeZoneOffSet,
      });
    });

    return finalObj;
  });

  const isAllCheckedRecordsSame = (listName?: string) => {
    if (listName) {
      let filteredData = [];
      switch (listName) {
        case 'invoice':
          filteredData = data.filter(
            (record: Record<string, string | boolean>) => {
              if (!record.isUploaded) {
                if (record.status !== 'Accepted' && !record?.isFreezed)
                  return record;
              }
            }
          );
          break;

        case 'expense':
          filteredData = data.filter(
            (record: Record<string, string | boolean>) => {
              if (!record.isUploaded) {
                if (record.status !== 'Paid' && !record?.isFreezed)
                  return record;
              }
            }
          );
          break;
      }
      const isAllChecked = filteredData
        .map((record: Record<string, string>) =>
          checkedIds?.includes(record._id)
        )
        .every((e: boolean) => e === true);

      return checkedIds && checkedIds?.length > 0 ? isAllChecked : false;
    } else {
      const isAllChecked = tableData
        .filter((record: Record<string, string | boolean>) => record?.isFreezed)
        .map((record: Record<string, string>) =>
          checkedIds?.includes(record.id)
        )
        .every((e: boolean) => e === true);
      return checkedIds && checkedIds?.length > 0 ? isAllChecked : false;
    }
  };

  const HeaderVisibility = () => {
    if (listName === 'topTenOrders') {
      return false;
    }
    return true;
  };

  const isAllCheckedAction = () => {
    if (listName === 'invoice' || listName === 'expense')
      return isAllCheckedRecordsSame(listName);
    else if (checkedIds?.length === tableData?.length)
      return isAllCheckedRecordsSame();
    else return false;
  };

  // const isAllCheckedRecordsSame = () => {};

  return data?.length ? (
    <div className={`list-container ${className ? className : ''}`}>
      <TableContainer className={addTableClassName}>
        <Table className="main-table" stickyHeader>
          {HeaderVisibility() && (
            <TableHeader
              headers={headers}
              isCheckbox={isCheckbox}
              mobileHeaders={mobileHeaders}
              centerTableFields={centerTableFields}
              rightTableFields={rightTableFields}
              isAction={isAction}
              selectAllCheckBox={selectAllCheckBox ?? (() => {})}
              onRequestSort={onSortChange ?? (() => {})}
              isSortable={isSortable}
              sortID={sortID}
              sortDesc={sortDesc}
              isAllChecked={
                listName === 'invoice' || listName === 'expense'
                  ? isAllCheckedRecordsSame(listName)
                  : isAllCheckedRecordsSame()
              }
            />
          )}

          <TableBody>
            {!!tableData?.length &&
              tableData.map((row: Record<string, any>, index: any) => (
                <Row
                  key={Math.random().toString()}
                  index={index}
                  data={data}
                  row={row}
                  listName={listName}
                  headers={headers}
                  mobileHeaders={mobileHeaders}
                  centerTableFields={centerTableFields}
                  rightTableFields={rightTableFields}
                  isCheckbox={isCheckbox}
                  isAction={isAction}
                  isDeleteAction={isDeleteAction ?? true}
                  isIncremental={isIncremental}
                  isEditable={isEditable ?? true}
                  isCancelable={isCancelable ?? false}
                  isManualSync={isManualSync ?? false}
                  syncRecord={syncRecord}
                  isGetModifiedData={isGetModifiedData ?? false}
                  onIncrementalChange={onIncrementalChange}
                  onInputCellChange={onInputCellChange}
                  onChangeData={onChangeData}
                  onChangeCheckBox={onChangeCheckBox ?? (() => {})}
                  deleteTableRow={deleteTableRow}
                  cancelTableRow={cancelTableRow}
                  editTableRow={editTableRow}
                  getModifiedData={getModifiedData}
                  checkedIds={checkedIds ?? []}
                  openRow={open}
                  setOpenRow={setOpen}
                  viewRecord={viewRecord}
                  onClickRow={onClickRow}
                  primaryTextField={primaryTextField}
                  onChangeSwitch={onChangeSwitch ?? (() => {})}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={`${addTableClassName ?? ''} mobile-table`}>
        {HeaderVisibility() && (
          <TableHeader
            headers={headers}
            isCheckbox={isCheckbox}
            mobileHeaders={mobileHeaders}
            centerTableFields={centerTableFields}
            isAction={isAction}
            selectAllCheckBox={selectAllCheckBox ?? (() => {})}
            onRequestSort={onSortChange ?? (() => {})}
            isSortable={isSortable}
            sortID={sortID}
            sortDesc={sortDesc}
            isAllChecked={isAllCheckedAction()}
          />
        )}

        {tableData?.length &&
          tableData.map((row: Record<string, any>, index: any) => (
            <Row
              key={index}
              index={index}
              data={data}
              row={row}
              listName={listName}
              headers={headers}
              mobileHeaders={mobileHeaders}
              isCheckbox={isCheckbox}
              isAction={isAction}
              isIncremental={isIncremental}
              onIncrementalChange={onIncrementalChange}
              onInputCellChange={onInputCellChange}
              onChangeData={onChangeData}
              isEditable={isEditable ?? true}
              isCancelable={isCancelable ?? false}
              isManualSync={isManualSync ?? false}
              syncRecord={syncRecord}
              isGetModifiedData={isGetModifiedData ?? false}
              isDeleteAction={isDeleteAction ?? true}
              onChangeCheckBox={onChangeCheckBox ?? (() => {})}
              deleteTableRow={deleteTableRow}
              cancelTableRow={cancelTableRow}
              editTableRow={editTableRow}
              getModifiedData={getModifiedData}
              checkedIds={checkedIds ?? []}
              openRow={open}
              setOpenRow={setOpen}
              viewRecord={viewRecord}
              onClickRow={onClickRow}
              primaryTextField={primaryTextField}
              onChangeSwitch={onChangeSwitch ?? (() => {})}
            />
          ))}
      </div>
      {isPagination ?? (
        <Pagination
          total={total}
          limit={limit}
          page={page}
          pages={pages}
          pageActionClick={onPageChange}
          onChangeLimit={onChangeLimit}
        />
      )}
    </div>
  ) : (
    <div className="no-record-text">{message ?? 'No record found'}</div>
  );
};

export default CustomTable;
