import { Check, Close, Delete, Sync } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';

import { printerIcon } from '../../../assets/images/action-items/printer';

import { filterIcon } from '../../../assets/images/filter';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import {
  deleteStatementAction,
  getStatementListAction,
  statementAutoSyncAction,
  statementExporttoExcel,
  statementExportToPdf,
  syncStatementAction,
  getSupplierListInStatement,
  modifyUploadedStatementAction,
  getModifiedUploadedStatementAction,
  getUploadedStatementResponse,
} from '../../../store/actions/invoiceActions/statementAction';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import companyLogo from '../../../assets/images/company-logo.svg';
import { format, parseISO } from 'date-fns';
import { audConverter } from '../../../constants/regexConstants';
import useMediaQuery from '../../../hooks/MediaQuery';
import { INVOICE_URLS } from '../../../constants/urlConstants';
import { useHistory } from 'react-router';
import FilterModal from '../../../components/filterModal/FilterModal';
import { statementStatusDropdown } from '../../../constants/invoiceconstants/statementConstants';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import { errorNotification } from '../../../components/notifyHelper';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import _ from 'lodash';
import { levelOneRole } from '../../../constants/levelOneRoleConstants';


const Statements = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const tableRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 620px)');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [rowId, setRowId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);


  const { statementListLoaderAction, statementAutoSyncLoaderAction } =
    useSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? {}
    );
  const { statementList, supplierList } = useSelector(
    ({ statementReducer }: Record<string, any>) => statementReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const { statement } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => statementList ?? {},
    [statementList]
  );

  const mobileHeaders = ['supplierName', 'statementDate'];

  const centerTableFields = ['isUploaded'];

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const onClickPrint = useReactToPrint({
    content: () => {
      const PrintElem = document.createElement('div');
      const header = `<div class='print-header'><img src="${companyLogo}" alt="foodygent" /><span>Foodygent</span></div>`;
      PrintElem.innerHTML = header;
      const bodyTitle = `<div class='print-body-title'>Statements</div>`;
      PrintElem.innerHTML += bodyTitle;
      const table = tableRef.current?.cloneNode(true);
      table && PrintElem.appendChild(table);
      return PrintElem;
    },
    pageStyle: `@page { size: auto; margin: 0mm; } 
    @media print { 
      html, body { overflow: hidden;}
      body { -webkit-print-color-adjust: exact; padding: 0 40px !important; } 
    }`,
    removeAfterPrint: !isMobile,
  });

  const exporttoExcel = () => {
    dispatch(statementExporttoExcel(filters));
  };

  const exportToPdf = () => {
    dispatch(statementExportToPdf(filters));
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exporttoExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
    {
      icon: printerIcon,
      name: 'Print',
      onClick: () => {
        if (docs?.length > 0) onClickPrint();
      },
    },
  ];

  const onPageChange = (page: any) => {
    dispatch(getStatementListAction({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getStatementListAction({ page: 1, limit: value, ...filters }));
  };

  const handleKeyDown = (e: any) => {
    if (e.ctrlKey && e.which === 80) {
      e.preventDefault();
      onClickPrint();
      return false;
    }
  };

  const filterFields = [
    {
      name: 'supplierId',
      label: 'Supplier',
      placeholder: 'Select Supplier',
      type: 'autocomplete',
      options: supplierList,
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Select Status',
      type: 'autocomplete',
      options: statementStatusDropdown,
      className: 'filter-field-gap',
    },
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];
  const onClickDeleteConfirmation = () => {
    let allIds;
    if (rowId) {
      dispatch(deleteStatementAction(rowId, afterSuccessfulDeletion));
    } else {
      allIds = checkedIds?.length > 1 ? checkedIds.join(',') : checkedIds[0];
      if (checkedIds.length !== 0) {
        dispatch(deleteStatementAction(allIds, afterSuccessfulDeletion));
      } else {
        setOpenDeleteModal(false);
        errorNotification('Please select at least one row for deletion');
      }
    }
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setCheckedIds([]);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };
    dispatch(getStatementListAction({ ...params }));
  };

  const selectAllCheckBox = () => {
    const allIds: string[] = [];
    docs.forEach((data: Record<string, any>) => {
      if (!data.isUploaded) {
        if (data.status !== 'Reconciled') allIds.push(data._id);
      }
    });
    if (docs?.length !== 0) {
      if (checkedIds?.length === allIds?.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(allIds);
      }
    }
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);
    }
  };

  const deleteTableRow = (val: string) => {
    setRowId(val);
    setOpenDeleteModal(true);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setCheckedIds([]);
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getStatementListAction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'statement',
      () => setOpenFilterModal(false),
      [getSupplierListInStatement]
    );
  };

  const syncAction = async (sync: boolean) => {
    const success = await dispatch(statementAutoSyncAction(sync));

    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: limit ?? paramLimit ?? 15,
        ...filters,
      };
      dispatch(getStatementListAction({ ...params }));
    }
  };

  const syncItems = [
    {
      name: 'Auto sync',
      onClick: () => syncAction(true),
    },
    {
      name: 'Manual sync',
      onClick: () => syncAction(false),
    },
  ];

  const manualSyncRecord = async (statementId: string) => {
    const success = await dispatch(syncStatementAction(statementId));
    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: limit ?? paramLimit ?? 15,
        ...filters,
      };
      dispatch(getStatementListAction({ ...params }));
    }
  };

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: statement?.limit ?? limit ?? paramLimit ?? 15,
    supplierId: statement?.supplierId?._id,
    status: statement?.status,
    startDate: statement?.startDate
      ? new Date(statement?.startDate)?.toISOString()
      : undefined,
    endDate: statement?.endDate
      ? new Date(statement?.endDate)?.toISOString()
      : undefined,
  });

  const filters = {
    supplierId: statement?.supplierId?._id,
    status: statement?.status,
    startDate: statement?.startDate,
    endDate: statement?.endDate,
  };

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: limit ?? paramLimit ?? 15,
      ...filters,
    };

    dispatch(getStatementListAction({ ...params }));
    dispatch(resetfilterOnModuleChange('statements'));
    dispatch(getSupplierListInStatement());
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Statements</div>
        <div className="button-container">
          {!_.isEmpty(statement) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(statement) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(statement)}
            filterData={statement}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle={statementList?.isAutoSync ? 'Auto Sync' : 'Manual Sync'}
            menuIcon="sync"
            menuItems={syncItems}
            id="invoice-action"
            disabled={!statementList?.isAccountConnected}
            loader={statementAutoSyncLoaderAction}
          />
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="product-action"
          />
          {levelOneRole.includes(profileDetail.role) &&
            statementList?.docs?.length > 0 && (
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={() => setOpenDeleteModal(true)}
              >
                <Delete />
              </Button>
            )}
        </div>
      </div>
      {statementListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          mobileHeaders={mobileHeaders}
          centerTableFields={centerTableFields}
          data={docs}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          isCheckbox
          selectAllCheckBox={selectAllCheckBox}
          checkedIds={checkedIds}
          onChangeCheckBox={onChangeCheckBox}
          isAction
          isDeleteAction={
            levelOneRole.includes(profileDetail.role) ? true : false
          }
          deleteTableRow={deleteTableRow}
          isEditable={false}
          viewRecord={(statement: Record<string, any>) => {
            history.push(`/invoices/statements/view/${statement._id}`);
          }}
          isManualSync={
            !statementList?.isAutoSync && statementList?.isAccountConnected
          }
          syncRecord={(invoiceId: string) => manualSyncRecord(invoiceId)}
          listName="statement"
        />
      )}
  
      {openFilterModal && (
        <FilterModal
          requestFrom="statement"
          closeModal={() => setOpenFilterModal(false)}
          updateListAction={getStatementListAction}
          filterFields={filterFields}
          defaultParams={{
            page: 1,
            limit: limit ?? paramLimit,
          }}
          isDateFilters
          filterAction={[getSupplierListInStatement]}
        />
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Statement"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
      {/* for printing table start here*/}
      <div className="print-table" ref={tableRef}>
        {docs?.length > 0 && (
          <>
            <div className="print-header-table statement-header">
              {headers?.map((header: Record<string, string>) => (
                <span
                  className={
                    header.name === 'status' || header.name === 'isUploaded'
                      ? 'center-field'
                      : ''
                  }
                >
                  {header.label}
                </span>
              ))}
            </div>
            <div className="print-table-body">
              {docs?.map((data: Record<string, any>) => (
                <div className="print-table-row statememt-row">
                  <span>{data.supplierName}</span>
                  <span>
                    {format(parseISO(data.statementDate), 'dd MMM yyyy')}
                  </span>
                  <span>{audConverter(data.statementBalance)}</span>
                  <span style={{ textAlign: 'center' }}>
                    <span
                      className={`status-tag ${data.status.toLowerCase()}`}
                      // style={{ padding: '0 2px' }}
                    >
                      {data.status}
                    </span>
                  </span>
                  <span className="text-center">
                    {data.isUploaded ? (
                      <div className={`icon-tag`}>
                        <span className="right">
                          <Check />
                        </span>
                      </div>
                    ) : (
                      <div className={`icon-tag`}>
                        <span className="wrong">
                          <Close />
                        </span>
                      </div>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {/* for printing table end here*/}
    </div>
  );
};

export default Statements;
