import { Delete } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';

import { filterIcon } from '../../../assets/images/filter';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import Loader from '../../../components/loader/Loader';
import CustomMenu from '../../../components/menu/Menu';

import { INVOICE_URLS } from '../../../constants/urlConstants';
import {
  autoSyncAction,
  deleteInvoice,
  exportInvoicesExcelsheet,
  exportInvoicesPdf,
  fetchAttachment,
  getInvoiceListAction,
  getModifiedUploadedInvoiceAction,
  getSupplierDropdownInInvoice,
  getUploadedInvoiceResponse,
  getUserDropdownInInvoice,
  modifyUploadedInvoiceAction,
  syncInvoiceAction,
} from '../../../store/actions/invoiceActions/invoiceActions';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';
import FilterModal from '../../../components/filterModal/FilterModal';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import {
  errorNotification,
  successNotification,
} from '../../../components/notifyHelper';
import { invoiceStatusDropdown } from '../../../constants/invoiceconstants/invoiceConstants';
import { levelOneRole } from '../../../constants/levelOneRoleConstants';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import _ from 'lodash';
import { addProductIcon } from '../../../assets/images/addProductIcon';
import jsPDF from 'jspdf';


const Invoices = () => {
  const history = useHistory();

  const dispatch = useDispatch<any>();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [rowId, setRowId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [checkedIds, setCheckedIds] = useState<Array<string>>([]);

  const { invoiceList, supplierDropdownInInvoice, userDropdownInInvoice } =
    useSelector(
      ({ invoiceReducer }: Record<string, any>) => invoiceReducer ?? {}
    );

  const { invoice } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const { docs, headers, limit, page, pages, total } = useMemo(
    () => invoiceList ?? {},
    [invoiceList]
  );

  const { invoiceListLoaderAction, invoiceAutosyncLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );
  const fetchAttachments =async () => {
    await dispatch(fetchAttachment())
  }
  useEffect(() => {
    fetchAttachments()
  },[])
  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const mobileHeaders = ['invoiceNumber', 'invoiceDate'];

  const filters = {
    supplierId: invoice?.supplierId?._id,
    userId: invoice?.userId?._id,
    status: invoice?.status,
    startDate: invoice?.startDate,
    endDate: invoice?.endDate,
  };

  const exportToExcel = () => {
    dispatch(
      exportInvoicesExcelsheet({
        ...filters,
      })
    );
  };

  const exportToPdf = () => {
    dispatch(
      exportInvoicesPdf({
        ...filters,
      })
    );
  };

  const actionItems = [
  //Removed upload invoice and scan invoice option from action menu
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
  ];

  const syncAction = async (sync: boolean) => {
    const success = await dispatch(autoSyncAction(sync));

    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: limit ?? paramLimit ?? 15,
        ...filters,
      };
      dispatch(getInvoiceListAction({ ...params }));
    }
  };

  const syncItems = [
    {
      name: 'Auto sync',
      onClick: () => syncAction(true),
    },
    {
      name: 'Manual sync',
      onClick: () => syncAction(false),
    },
  ];

  const filterFields = [
    {
      name: 'supplierId',
      label: 'Supplier',
      placeholder: 'Select Supplier',
      type: 'autocomplete',
      options: supplierDropdownInInvoice,
    },
    {
      name: 'userId',
      label: 'User',
      placeholder: 'Select User',
      type: 'autocomplete',
      options: userDropdownInInvoice,
      className: 'filter-field-gap',
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Select Status',
      type: 'autocomplete',
      options: invoiceStatusDropdown,
      className: 'filter-field-gap',
    },
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];

  const addMenuItems = [
    {
      icon: addProductIcon,
      name: 'Add Invoice',
      onClick: () => history.push('/invoices/list/add'),
    },
    {
      icon: <span className="material-icons">category</span>,
      name: 'Add/Edit Category',
      onClick: () => history.push(`/invoices/add-edit-category`),
    },
  ];

  const afterSuccessfulDeletion = async () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    const params = {
      page: 1,
      limit: paramLimit ?? limit ?? 15,
      ...filters,
    };
    await dispatch(getInvoiceListAction({ ...params }));
  };

  const onClickDeleteConfirmation = () => {
    let allIds;
    if (rowId) {
      dispatch(deleteInvoice(rowId, afterSuccessfulDeletion));
    } else {
      allIds = checkedIds?.length > 1 ? checkedIds.join(',') : checkedIds[0];
      if (checkedIds.length !== 0) {
        dispatch(deleteInvoice(allIds, afterSuccessfulDeletion));
      } else {
        setOpenDeleteModal(false);
        errorNotification('Please select at least one row for deletion');
      }
    }
  };

  const deleteModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenDeleteModal(false);
        setCheckedIds([]);
        setRowId('');
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: onClickDeleteConfirmation,
      color: 'primary',
    },
  ];

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    limit: invoice?.limit ?? limit ?? paramLimit ?? 15,
    supplierId: invoice?.supplierId?._id,
    userId: invoice?.userId?._id,
    status: invoice?.status,
    startDate: invoice?.startDate
      ? new Date(invoice?.startDate)?.toISOString()
      : undefined,
    endDate: invoice?.endDate
      ? new Date(invoice?.endDate)?.toISOString()
      : undefined,
  });

  const deleteTableRow = (val: string) => {
    setRowId(val);
    setOpenDeleteModal(true);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setRowId('');
    setCheckedIds([]);
    setRowId('');
  };

  const selectAllCheckBox = () => {
    const allIds: string[] = [];
    docs.forEach((data: Record<string, any>) => {
      if (!data.isUploaded) {
        if (data.status !== 'Accepted' && !data.isFreezed)
          allIds.push(data._id);
      }
    });
    if (docs?.length !== 0) {
      if (checkedIds?.length === allIds?.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(allIds);
      }
    }
  };

  const onChangeCheckBox = (value: string) => {
    if (value) {
      let newArr = [...checkedIds];
      const index = newArr.findIndex((e) => e === value);
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      setCheckedIds(newArr);
    }
  };

  const onDeleteAllClick = () => {
    setOpenDeleteModal(true);
  };

  const onPageChange = (page: any) => {
    dispatch(getInvoiceListAction({ page: page, limit, ...filters }));
  };

  const onChangeLimit = (value: any) => {
    dispatch(getInvoiceListAction({ page: 1, limit: value, ...filters }));
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getInvoiceListAction,
      { page: 1, limit: limit ?? paramLimit ?? 15 },
      'invoice',
      () => setOpenFilterModal(false),
      [getSupplierDropdownInInvoice, getUserDropdownInInvoice]
    );
  };

  const manualSyncRecord = async (invoiceId: string) => {
    const success = await dispatch(syncInvoiceAction(invoiceId));
    if (success) {
      const params = {
        page: paramPage ?? page ?? 1,
        limit: limit ?? paramLimit ?? 15,
        ...filters,
      };
      dispatch(getInvoiceListAction({ ...params }));
    }
  };

  useEffect(() => {
    const params = {
      page: paramPage ?? page ?? 1,
      limit: limit ?? paramLimit ?? 15,
      ...filters,
    };
    dispatch(getInvoiceListAction({ ...params }));
    dispatch(getSupplierDropdownInInvoice());
    dispatch(getUserDropdownInInvoice());
    dispatch(resetfilterOnModuleChange('invoice'));
  }, []);


  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Invoices</div>
        <div className="button-container">
          <Button
            variant="contained"
            className="desktop-button"
            color="primary"
            onClick={() => history.push('/invoices/list/add')}
          >
            Add Invoice
          </Button>
          {!_.isEmpty(invoice) && ( 
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(invoice) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {/* Mobile Buttons end here */}
          <Button
            variant="contained"
            className="desktop-button"
            color="primary"
            onClick={() => {
              history.push(`/invoices/add-edit-category`);
            }}
          >
            Add Categories
          </Button>
          {/* Mobile Buttons start here */}
          <div className="mobile-button-container">
            <CustomMenu
              className="action-menu"
              menuTitle="Actions"
              menuIcon="add"
              menuItems={addMenuItems}
              id="user-action"
            />
          </div>
          {/* mobile button end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal((e) => !e)}
            isFilterApplied={!_.isEmpty(invoice)}
            filterData={invoice}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle={invoiceList?.isAutoSync ? 'Auto Sync' : 'Manual Sync'}
            menuIcon="sync"
            menuItems={syncItems}
            id="invoice-action"
            disabled={!invoiceList?.isAccountConnected}
            loader={invoiceAutosyncLoaderAction}
          />
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="invoice-action"
          />
          {levelOneRole.includes(profileDetail.role) &&
            invoiceList?.docs?.length > 0 && (
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={onDeleteAllClick}
              >
                <Delete />
              </Button>
            )}
        </div>
      </div>
      {invoiceListLoaderAction ? (
        <Loader />
      ) : (
        <CustomTable
          headers={headers}
          centerTableFields={['isUploaded']}
          mobileHeaders={mobileHeaders}
          data={docs}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          pages={pages}
          total={total}
          isCheckbox
          selectAllCheckBox={selectAllCheckBox}
          checkedIds={checkedIds}
          onChangeCheckBox={onChangeCheckBox}
          listName="invoice"
          isAction
          isDeleteAction={
            levelOneRole.includes(profileDetail.role) ? true : false
          }
          isEditable={false}
          deleteTableRow={deleteTableRow}
          viewRecord={(invoice: Record<string, any>) =>
            history.push(`/invoices/view/${invoice._id}`)
          }
          isManualSync={
            !invoiceList?.isAutoSync && invoiceList?.isAccountConnected
          }
          syncRecord={(invoiceId: string) => manualSyncRecord(invoiceId)}
        />
      )}
      {openDeleteModal && (
        <CustomModal
          className="delete-modal"
          header="Delete Invoice"
          buttons={deleteModalButton}
          hideModal={handleClose}
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to delete?
          </span>
        </CustomModal>
      )}
      {openFilterModal && (
        <FilterModal
          requestFrom="invoice"
          closeModal={() => setOpenFilterModal(false)}
          updateListAction={getInvoiceListAction}
          filterFields={filterFields}
          defaultParams={{
            page: 1,
            limit: limit ?? paramLimit,
          }}
          isDateFilters
          filterAction={[
            getSupplierDropdownInInvoice,
            getUserDropdownInInvoice,
          ]}
        />
      )}
    </div>
  );
};

export default Invoices;
