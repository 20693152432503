import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export const successNotification = (message: string) => {
  toast(message, {
    type: 'default',
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    className: 'toast-background',
    bodyClassName: 'toast-body',
    toastId : `success${message}`
  });
  return false;
};

export const errorNotification = (message: string) => {
  toast(message, {
    type: 'default',
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    closeButton: false,
    className: 'toast-background',
    bodyClassName: 'toast-body',
    toastId : `error${message}`
  });
  return false;
};

export const warningNotification = (message: string) => {
  toast(message, {
    type: 'default',
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    closeButton: false,
    className: 'toast-background',
    bodyClassName: 'toast-body',
    toastId : `warning${message}`
  });
  return false;
};
