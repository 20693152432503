import { LOGIN_REDUX_CONSTANTS } from '../reduxConstants/loginReduxConstants';
import { PRODUCT_INVENTORY_REDUX_CONSTANTS } from '../reduxConstants/productInventoryReduxConstants';

interface ProductInventoryProps {
  productInventoryList: Record<string, any>;
  filterSupplierDropdown: Record<string, string>[];
  filterProductDropdown: Record<string, string>[];
  productInventoryDetails: Record<string, string>;
}

const initialProductInventoryReducer: ProductInventoryProps = {
  productInventoryList: {},
  filterSupplierDropdown: [],
  filterProductDropdown: [],
  productInventoryDetails: {},
};

export const productInventoryReducer = (
  state = initialProductInventoryReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action.type) {
    case PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_PRODUCT_INVENTORY_LIST:
      return { ...state, productInventoryList: action.data };

    case PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_FILTER_SUPPLIER_DROPDOWN:
      return { ...state, filterSupplierDropdown: action.data };

    case PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_FILTER_PRODUCT_DORPDOWN:
      return { ...state, filterProductDropdown: action.data };

    case PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_PRODUCT_INVENTORY_DETAILS_BY_ID:
      return { ...state, productInventoryDetails: action.data };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return { ...initialProductInventoryReducer };

    default:
      return { ...state };
  }
};
