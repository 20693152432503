import { BarChartRounded, TimelineRounded } from '@mui/icons-material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  registerables,
} from 'chart.js';
import 'chart.js/auto';
import { useEffect, useMemo, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import Button from '../../../components/button/Button';
import CustomMenu from '../../../components/menu/Menu';
import {
  color_palette,
  supplierSpendingChartColours,
} from '../../../constants/colorPalette';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardApiServices } from '../../../services/pageServices/dashboardApiServices';
import { dashboardReducer } from '../../../store/reducers/dashboardReducer';
import { audConverter } from '../../../constants/regexConstants';
import {
  getDailySaleAction,
  getSaleFoodAndBeveragesChartAction,
  getSaleFoodAndBeveragesDetailAction,
  getSaleSpendPerHeadAction,
} from '../../../store/actions/dashboardActions';
import { graphValueHelper } from '../../../helpers/graphValueHelper';

const SalesChartDashboard = () => {
  const [chartType, setChartType] = useState('Bar Chart');
  const [category, setCategory] = useState('all');
  const [doughnutFilter, setDoughnutFilter] = useState('Weekly');
  const dispatch = useDispatch();

  const {
    dailySale,
    saleFoodAndBeverageDetail,
    saleFoodAndBeverageChart,
    saleSpendPerHead,
  } = useSelector(
    ({ dashboardReducer }: Record<string, any>) => dashboardReducer ?? {}
  );


  const { dailySaleBeverages, dailySaleFood, dailySaleLabel } = useMemo(
    () => dailySale ?? {},
    [dailySale]
  );

  const foodSale = saleFoodAndBeverageChart?.weekWiseData?.map(
    (e: Record<string, number | string>) => e.foodSales
  );
  const bevSale = saleFoodAndBeverageChart?.weekWiseData?.map(
    (e: Record<string, number | string>) => e.bevSales
  );
  const saleLabel = saleFoodAndBeverageChart?.weekWiseData?.map(
    (e: Record<string, number | string>) => e.label
  );

  const { spendPerHeadTotal, spendPerHeadLabel } = useMemo(
    () => saleSpendPerHead ?? {},
    [saleSpendPerHead]
  );
  const chartTypes = [
    {
      icon: <BarChartRounded />,
      name: 'Bar Chart',
      onClick: () => setChartType('Bar Chart'),
    },
    {
      icon: <TimelineRounded />,
      name: 'Line Chart',
      onClick: () => setChartType('Line Chart'),
    },
  ];
  const onChangeSpendHeadFilter = (duration: string) => {
    const params = {
      duration: duration,
    };

    dispatch(getSaleSpendPerHeadAction(params));
    setDoughnutFilter(duration);
    const jslegend = document.querySelector('#js-legend');
    if (jslegend) jslegend.innerHTML = '';
  };
  const doughnutFilterOptions = [
    {
      name: 'Weekly',
      onClick: () => onChangeSpendHeadFilter('Weekly'),
    },
    {
      name: 'Fortnightly',
      onClick: () => onChangeSpendHeadFilter('Fortnightly'),
    },
    {
      name: 'Monthly',
      onClick: () => onChangeSpendHeadFilter('Monthly'),
    },
    {
      name: 'Yearly',
      onClick: () => onChangeSpendHeadFilter('Yearly'),
    },
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels,
    ...registerables
  );

  const mainChartData = {
    labels: saleLabel,
    datasets: [
      {
        label: 'Food',
        data: foodSale,
        backgroundColor: color_palette.primary,
        borderRadius: (context: any) => (context.chart.width > 640 ? 5 : 1),
        borderColor: color_palette.primary,
        barPercentage: 0.7,
        categoryPercentage: 0.5,
      },
      {
        label: 'Beverages',
        data: bevSale,
        backgroundColor: color_palette.lightPrimary,
        borderRadius: (context: any) => (context.chart.width > 640 ? 5 : 1),
        barPercentage: 0.7,
        borderColor: color_palette.lightPrimary,
        categoryPercentage: 0.5,
      },
    ],
  };

  const mainChartOptions: any = {
    responsive: true,
    plugins: {
      datalabels: false,
      legend: {
        labels: (context: any) =>
          context.chart.width > 640
            ? { boxWidth: 18, boxHeight: 18, font: { size: 10 } }
            : { boxWidth: 8, boxHeight: 8, font: { size: 6 } },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          font: (context: any) => {
            const width = context.chart.width;
            const size = width > 640 ? 12 : 4;
            return {
              size: size,
            };
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value: number) => graphValueHelper(value),
          font: (context: any) => {
            const width = context.chart.width;
            const size = width > 640 ? 12 : 6;
            return {
              size: size,
            };
          },
        },
      },
    },
  };

  const supplierspendingChartsOptions = {
    layout: {
      padding: 10,
    },
    responsive: true,
    onResize: (context: any) => {
      if (window.innerWidth > 640) {
        context.height = 200;
      }
    },
    onHover: (evt: any, elements: any) => {
      let segment;
      if (elements && elements.length) {
        segment = elements[0];

        // elements.chart.update();
        // const selectedIndex = segment['_index'];

        //  segment.element.outerRadius += 10;
      } else {
        if (segment) {
          // segment.element.outerRadius -= 10;
        }
        segment = null;
      }
    },
    // maintainAspectRatio: () => (window.innerWidth > 768 ? false : true),
    maintainAspectRatio: false,
    // cutout: () => {
    //     return window.innerWidth > 640 ? 65 : window.innerWidth >= 414 ? 70 : 50;
    // },
    plugins: {
      datalabels: {
        display: false,
        formatter: function (value: any, context: any) {
          return '$' + value;
        },
        font: () =>
          window.innerWidth > 640
            ? {
                size: 15,
              }
            : {
                size: 12,
              },
        color: '#FFFFFF',
        anchor: 'center',
        align: 'end',
      },
      legend: {
        display: false,
        // position: () => (window.innerWidth > 640 ? 'right' : 'top'),
        // position: 'right',
        // textAlign: 'right',
        // // align: () => (window.innerWidth > 640 ? 'start' : 'center'),
        // align: () => (window.innerWidth > 640 ? 'start' : 'center'),
        // labels: {
        //   boxWidth: 14,
        //   boxHeight: 8,
        // },
      },
    },
  };
  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart: any) {
      const items: any =
        chart.options.plugins.legend.labels.generateLabels(chart);
      const jsLegend = document.getElementById('js-legend');

      /*
      after update it'll cause creating new elements and remove attached style
      So if condition will only run one time and add li elements and in next update it won't run
      checking items because on first render it'll be empty and next render it'll get values
      */
      if (items?.length > 0 && jsLegend?.children?.length === 0) {
        const ul = document.createElement('ul');
        items.forEach((item: any, index: number) => {
          const li = document.createElement('li');
          li.onclick = () => {
            const { type } = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index);
              if (!chart.legend.legendItems?.[item.index].hidden) {
                li.setAttribute('style', 'text-decoration:line-through');
              } else {
                li.setAttribute('style', 'text-decoration:none');
              }
            } else {
              chart.setDatasetVisibility(
                item.datasetIndex,
                !chart.isDatasetVisible(item.datasetIndex)
              );
            }
            chart.update();
          };
          const boxSpan = document.createElement('div');
          boxSpan.style.background = item.fillStyle;
          li.appendChild(boxSpan);
          li.appendChild(document.createTextNode(item.text));
          ul.appendChild(li);
        });
        const jsLegend: any = document.getElementById('js-legend');
        if (jsLegend) jsLegend.replaceChildren(ul);
      }
    },
  };

  const supplierspendingChartsData = {
    datasets: [
      {
        data: spendPerHeadTotal,
        backgroundColor: supplierSpendingChartColours,
        hoverOffset: 10,
        borderWidth: 0,
        hoverBorderWidth: 0,
      },
    ],
    labels: spendPerHeadLabel,
  };

  const dailyChartData = {
    labels: dailySaleLabel,
    datasets: [
      {
        label: 'Beverages',
        data: dailySaleBeverages,
        borderColor: '#18E49F',
        backgroundColor: '#18E49F',
        borderRadius: (context: any) => (context.chart.width > 640 ? 5 : 1),
      },
      {
        label: 'Food',
        data: dailySaleFood,
        borderColor: '#199AFB',
        backgroundColor: '#199AFB',
        borderRadius: (context: any) => (context.chart.width > 640 ? 5 : 1),
      },
    ],
  };

  const dailyChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    onResize: (context: any) => {
      if (window.innerWidth > 640) {
        context.height = 250;
      } else {
        context.height = 200;
      }
    },
    elements: {
      line: {
        tension: 0.3,
      },
    },
    plugins: {
      datalabels: false,
      legend: {
        display: true,
        labels: {
          boxWidth: 14,
          boxHeight: 14,
          font: { size: 10 },
        },
      },
      filler: {
        propagate: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          font: (context: any) => {
            const width = context.chart.width;
            const size = width > 640 ? 12 : 10;
            return {
              size: size,
            };
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value: number) => graphValueHelper(value),

          font: (context: any) => {
            const width = context.chart.width;
            const size = width > 640 ? 10 : 6;
            return {
              size: size,
            };
          },
        },
      },
    },
  };
  const onChangeCategory = (category: string) => {
    const params = {
      category: category === 'All' ? undefined : category,
    };
    dispatch(getSaleFoodAndBeveragesChartAction(params));
    dispatch(getSaleFoodAndBeveragesDetailAction(params));
    dispatch(getDailySaleAction(params));
    setCategory(category.toLowerCase());
  };
  const pieDOM = useMemo(() => {
    return (
      <Pie
        height={200}
        data={supplierspendingChartsData}
        //@ts-ignore
        options={supplierspendingChartsOptions}
        plugins={[htmlLegendPlugin]}
      />
    );
  }, [saleSpendPerHead]);
  useEffect(() => {
    const jslegend = document.querySelector('#js-legend');
    if (jslegend) jslegend.innerHTML = '';
    dispatch(getSaleFoodAndBeveragesChartAction());
    dispatch(getSaleFoodAndBeveragesDetailAction());
    dispatch(getSaleSpendPerHeadAction());
    dispatch(getDailySaleAction());
  }, []);

  return (
    <>
      <div className="chart-button-container">
        <CustomMenu
          className="action-menu dashboard-chart-menu"
          menuTitle={chartType}
          menuItems={chartTypes}
          id="chart-type"
        />
        <div>
          <Button
            color="primary"
            className="outlined-primary-button"
            variant={category === 'all' ? 'outlined' : 'contained'}
            onClick={() => onChangeCategory('All')}
          >
            All
          </Button>
          <Button
            color="primary"
            className="outlined-primary-button"
            variant={category === 'food' ? 'outlined' : 'contained'}
            onClick={() => onChangeCategory('Food')}
          >
            Food
          </Button>
          <Button
            color="primary"
            className="outlined-primary-button"
            variant={category === 'beverages' ? 'outlined' : 'contained'}
            onClick={() => onChangeCategory('Beverages')}
          >
            Beverages
          </Button>
        </div>
      </div>
      <div className="white-block-row dashboard__pending-invoices-sales-info-block-row purchase-block-row">
        <div className="white-block">
          <div>{audConverter(saleFoodAndBeverageDetail?.foodSales ?? 0)}</div>
          <div> Food sales</div>
        </div>
        <div className="white-block">
          <div>
            {audConverter(saleFoodAndBeverageDetail?.beveragesSales ?? 0)}
          </div>
          <div>Beverage sales</div>
        </div>
      </div>

      <div className="white-block">
        {chartType === 'Line Chart' ? (
          <Line data={mainChartData} options={mainChartOptions} />
        ) : (
          <Bar data={mainChartData} options={mainChartOptions} />
        )}
      </div>

      <div className="doughnut-graph-list-container pie-graph-list-container">
        <div className="white-block">
          <div className="page-header">
            <div className="dashboard-sub-heading">Spend per head</div>
            <CustomMenu
              className="action-menu dashboard-chart-menu"
              menuTitle={doughnutFilter}
              menuItems={doughnutFilterOptions}
              id="doughnut-filter"
            />
          </div>
          <div className="supplier-spending-chart-container">
            <div className="chart-container">{pieDOM}</div>
            <div className="chart-legends">
              <div id="js-legend"></div>
            </div>
          </div>
        </div>

        <div className="white-block daily-sales">
          <div className="page-header">
            <div className="dashboard-sub-heading">Daily Sales</div>
          </div>
          <div className="supplier-spending-chart-container daily-sale-line-chart">
            <Line data={dailyChartData} options={dailyChartOptions} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesChartDashboard;
