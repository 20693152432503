import { BarChartRounded, TimelineRounded } from '@mui/icons-material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import 'chart.js/auto';
import { useEffect, useMemo, useState } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import Button from '../../../components/button/Button';
import CustomMenu from '../../../components/menu/Menu';
import {
  color_palette,
  supplierSpendingChartColours,
} from '../../../constants/colorPalette';
import RecentPriceChange from './RecentPriceChange';
import TopTenItemsOrdered from './topTenItemsOrdered/TopTenItemsOrdered';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPurchaseFoodAndBeveragesChartAction,
  getPurchaseFoodAndBeveragesDetailsInDashboardAction,
  getPurchasependingInvoicesAction,
  getPurchasePriceChangeDetailsAction,
  getPurchaseTopTenOrdersAction,
  getPurchaseTopTenSuppliersAction,
} from '../../../store/actions/dashboardActions';
import { audConverter } from '../../../constants/regexConstants';
import { graphValueHelper } from '../../../helpers/graphValueHelper';

const PurchaseChartDashboard = () => {
  const [chartType, setChartType] = useState('Bar Chart');
  const [category, setCategory] = useState('All');
  const [doughnutFilter, setDoughnutFilter] = useState('Weekly');
  const dispatch = useDispatch();

  const {
    pendingInvoicesList,
    food,
    beverage,
    purchaseFoodAndBeveragesChart,
    purchaseTopTenSupplierList,
    purchasePriceChangeDetails,
  } = useSelector(
    ({ dashboardReducer }: Record<string, any>) => dashboardReducer ?? {}
  );

  const { purchase, suppliers } = useMemo(
    () => purchaseTopTenSupplierList ?? {},
    [purchaseTopTenSupplierList]
  );

  const { foodData, beverageData, label } = useMemo(
    () => purchaseFoodAndBeveragesChart ?? {},
    [purchaseFoodAndBeveragesChart]
  );

  const categoryfilter = {
    category: category === 'All' ? undefined : category,
  };

  const durationFilter = { duration: doughnutFilter };

  const chartTypes = [
    {
      icon: <BarChartRounded />,
      name: 'Bar Chart',
      onClick: () => setChartType('Bar Chart'),
    },
    {
      icon: <TimelineRounded />,
      name: 'Line Chart',
      onClick: () => setChartType('Line Chart'),
    },
  ];

  const onChangeDoughnutFilter = (duration: string) => {
    dispatch(
      getPurchaseTopTenSuppliersAction({
        duration,
        ...categoryfilter,
      })
    );
    dispatch(getPurchaseTopTenOrdersAction({ ...categoryfilter, duration }));
    setDoughnutFilter(duration);
    const jslegend = document.querySelector('#js-legend');
    if (jslegend) jslegend.innerHTML = '';
  };

  const doughnutFilterOptions = [
    {
      name: 'Weekly',
      onClick: () => onChangeDoughnutFilter('Weekly'),
    },
    {
      name: 'Fortnightly',
      onClick: () => onChangeDoughnutFilter('Fortnightly'),
    },
    {
      name: 'Monthly',
      onClick: () => onChangeDoughnutFilter('Monthly'),
    },
    {
      name: 'Yearly',
      onClick: () => onChangeDoughnutFilter('Yearly'),
    },
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const mainChartData = {
    labels: label,
    datasets: [
      {
        label: 'Food',
        data: foodData ?? [],
        backgroundColor: color_palette.primary,
        borderRadius: (context: any) => (context.chart.width > 640 ? 5 : 1),
        borderColor: color_palette.primary,
        pointRadius: 5,
        barPercentage: 0.7,
        categoryPercentage: 0.5,
      },
      {
        label: 'Beverages',
        data: beverageData ?? [],
        backgroundColor: color_palette.lightPrimary,
        borderRadius: (context: any) => (context.chart.width > 640 ? 5 : 1),
        borderColor: color_palette.lightPrimary,
        pointRadius: 5,
        barPercentage: 0.7,
        categoryPercentage: 0.5,
      },
    ],
  };

  const mainChartOptions: any = {
    responsive: true,
    plugins: {
      datalabels: false,
      legend: {
        labels: (context: any) =>
          context.chart.width > 640
            ? { boxWidth: 18, boxHeight: 18, font: { size: 10 } }
            : { boxWidth: 8, boxHeight: 8, font: { size: 6 } },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          font: (context: any) => {
            const width = context.chart.width;
            const size = width > 640 ? 12 : 4;
            return {
              size: size,
            };
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value: number) => graphValueHelper(value),
          font: (context: any) => {
            const width = context.chart.width;
            const size = width > 640 ? 12 : 6;
            return {
              size: size,
            };
          },
        },
      },
    },
  };

  const supplierspendingChartsOptions: any = {
    layout: {
      padding: 10,
    },
    responsive: true,
    onResize: (context: any) => {
      if (window.innerWidth > 640) {
        context.height = 200;
      }
    },
    onHover: (evt: any, elements: any) => {
      let segment;
      if (elements && elements.length) {
        segment = elements[0];

        // elements.chart.update();
        // const selectedIndex = segment['_index'];

        //  segment.element.outerRadius += 10;
      } else {
        if (segment) {
          // segment.element.outerRadius -= 10;
        }
        segment = null;
      }
    },
    maintainAspectRatio: false,

    cutout: () => {
      return window.innerWidth > 640
        ? 65
        : window.innerWidth >= 414
        ? 70
        : window.innerWidth >= 360
        ? 50
        : 40;
      // return 70;
    },
    plugins: {
      datalabels: false,
      legend: {
        display: false,
      },
    },
  };
  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart: any) {
      const items: any =
        chart.options.plugins.legend.labels.generateLabels(chart);
      const jsLegend = document.getElementById('js-legend');

      if (items?.length > 0 && jsLegend?.children?.length === 0) {
        const ul = document.createElement('ul');
        items.forEach((item: any, index: number) => {
          const li = document.createElement('li');
          li.onclick = () => {
            const { type } = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index);
              if (!chart.legend.legendItems?.[item.index].hidden) {
                li.setAttribute('style', 'text-decoration:line-through');
              } else {
                li.setAttribute('style', 'text-decoration:none');
              }
            } else {
              chart.setDatasetVisibility(
                item.datasetIndex,
                !chart.isDatasetVisible(item.datasetIndex)
              );
            }
            chart.update();
          };
          const boxSpan = document.createElement('div');
          boxSpan.style.background = item.fillStyle;
          li.appendChild(boxSpan);
          li.appendChild(document.createTextNode(item.text));
          ul.appendChild(li);
        });
        const jsLegend: any = document.getElementById('js-legend');
        if (jsLegend) jsLegend.replaceChildren(ul);
      }
    },
  };

  const supplierspendingChartsData = {
    datasets: [
      {
        data: purchase,
        backgroundColor: supplierSpendingChartColours,
        hoverOffset: 10,
        borderWidth: 0,
        hoverBorderWidth: 0,
      },
    ],
    labels: suppliers,
  };
  const onChangeCategory = (category: string) => {
    const params = {
      category: category === 'All' ? undefined : category,
    };
    setCategory(category);
    dispatch(getPurchaseFoodAndBeveragesChartAction(params));
    dispatch(getPurchaseFoodAndBeveragesDetailsInDashboardAction(params));
    dispatch(
      getPurchaseTopTenSuppliersAction({
        ...durationFilter,
        ...params,
      })
    );
    dispatch(getPurchaseTopTenOrdersAction({ ...params, ...durationFilter }));
    dispatch(getPurchasePriceChangeDetailsAction(params));
  };

  const doughnutDOM = useMemo(() => {
    return (
      <Doughnut
        id="doughnut"
        height={200}
        data={supplierspendingChartsData}
        options={supplierspendingChartsOptions}
        plugins={[htmlLegendPlugin]}
      />
    );
  }, [suppliers]);

  useEffect(() => {
    const jslegend = document.querySelector('#js-legend');
    if (jslegend) jslegend.innerHTML = '';
    dispatch(getPurchasependingInvoicesAction());
    dispatch(
      getPurchaseFoodAndBeveragesDetailsInDashboardAction(categoryfilter)
    );
    dispatch(getPurchaseFoodAndBeveragesChartAction(categoryfilter));
    dispatch(
      getPurchaseTopTenOrdersAction({ ...categoryfilter, ...durationFilter })
    );
    dispatch(
      getPurchaseTopTenSuppliersAction({
        ...durationFilter,
        ...categoryfilter,
      })
    );
    dispatch(getPurchasePriceChangeDetailsAction(categoryfilter));
  }, []);

  return (
    <>
      <div className="chart-button-container">
        <CustomMenu
          className="action-menu dashboard-chart-menu"
          menuTitle={chartType}
          menuItems={chartTypes}
          id="chart-type"
        />
        <div>
          <Button
            color="primary"
            className="outlined-primary-button"
            variant={category === 'All' ? 'outlined' : 'contained'}
            onClick={() => onChangeCategory('All')}
          >
            All
          </Button>
          <Button
            color="primary"
            className="outlined-primary-button"
            variant={category === 'Food' ? 'outlined' : 'contained'}
            onClick={() => onChangeCategory('Food')}
          >
            Food
          </Button>
          <Button
            color="primary"
            className="outlined-primary-button"
            variant={category === 'Beverages' ? 'outlined' : 'contained'}
            onClick={() => onChangeCategory('Beverages')}
          >
            Beverages
          </Button>
        </div>
      </div>
      <div className="white-block-row dashboard__pending-invoices-sales-info-block-row">
        <div className="white-block">
          <div>{pendingInvoicesList?.pendingInvoice ?? 0}</div>
          <div>New Pending Invoices</div>
        </div>
        <div className="white-block">
          <div>{audConverter(food)}</div>
          <div> Food Purchases</div>
        </div>
        <div className="white-block">
          <div>{audConverter(beverage)}</div>
          <div>Beverage Purchases</div>
        </div>
      </div>

      <div className="white-block">
        {chartType === 'Bar Chart' && (
          <Bar data={mainChartData} options={mainChartOptions} />
        )}

        {chartType === 'Line Chart' && (
          <Line data={mainChartData} options={mainChartOptions} />
        )}
      </div>

      <div className="doughnut-graph-list-container">
        <div className="white-block">
          <div className="page-header">
            <div className="dashboard-sub-heading">
              TOP TEN SUPPLIER SPENDING
            </div>
            <CustomMenu
              className="action-menu dashboard-chart-menu"
              menuTitle={doughnutFilter}
              menuItems={doughnutFilterOptions}
              id="doughnut-filter"
            />
          </div>
          <div className="supplier-spending-chart-container">
            <div className="chart-container">{doughnutDOM}</div>
            <div className="chart-legends">
              <div id="js-legend"></div>
            </div>
          </div>
        </div>
        <div className="white-block">
          <div className="dashboard-sub-heading">TOP TEN ITEMS ORDERED</div>
          <TopTenItemsOrdered />
        </div>
      </div>

      {purchasePriceChangeDetails?.length > 0 && <RecentPriceChange />}
    </>
  );
};

export default PurchaseChartDashboard;
