import { ROUTE_CONSTANTS_VARIABLE } from './../../routes/RouteConstants';
import { successNotification } from './../../components/notifyHelper';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';
import { displayErrors } from './../../helpers/errorNotifyHelper';
import { orderApiService } from './../../services/pageServices/orderApiService';
import { ORDER_REDUX_CONSTANTS } from './../reduxConstants/orderReduxConstants';
import { Dispatch } from 'react';
import { downloadAll } from '../../helpers/downloadHelper';
import { RouteComponentProps } from 'react-router';

export const getOrderList = (params: Record<string, any>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('orderListLoaderAction');
      const response = await orderApiService.getOrderList(params);
      if (response?.status === 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.GET_ORDER_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('orderListLoaderAction');
    }
  };
};
export const getSupplierListInOrderPage =() =>{
  return async (dispatch:Dispatch<any>) => {
    try {
    
        const response = await orderApiService.getSupplierList();
        if(response?.status === 200){
          dispatch({
            type : ORDER_REDUX_CONSTANTS.GET_SUPPLIER_LIST_FORORDER,
            data : response.data.data

          })
        }
    }catch(e){
      displayErrors(e)
    }
  }
  
}

export const onChangeOrderDetail = (
  data: Record<
    string,
    string | null | undefined | Record<string, string | number>
  >
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.CREATE_ORDER_ON_CHANGE_VALUE,
      data,
    });
  };
};

export const resetOrderDetails = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.RESET_ORDER_DETAILS,
    });
  };
};

export const deleteOrders = (ids: string, cb: () => void) => {
  return async () => {
    try {
      const response = await orderApiService.deleteOrders({ orderIds: ids });
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeProductQuantity = (data: Record<string, string>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.ON_CHANGE_PRODUCT_QUANTITY,
      data,
    });
  };
};

export const createOrder = (
  data: Record<string, any>,
  history: RouteComponentProps['history']
) => {
  return async () => {
    try {
      const response = await orderApiService.createOrder(data);
      if (response.status === 201) {
        successNotification('Order placed successfully');
        if (response.data.data._id) {
          history.push(`/orders/draft/${response.data.data._id}`);
        } else {
          history.push(ROUTE_CONSTANTS_VARIABLE.ORDER);
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const deleteProductFromDraftOrder = (productId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.DELETE_PRODUCT_FOR_DRAFT_ORDER,
      data: { productId },
    });
  };
};

export const getOrderDetailsById = (orderId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getOrderDetailsByIdLoaderAction');
      const response = await orderApiService.viewOrderDetails(orderId);
      if (response?.status === 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.GET_ORDER_DETAILS_BY_ID,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getOrderDetailsByIdLoaderAction');
    }
  };
};

export const confirmAndSendOrderDetails = (
  orderId: string,
  orderDetails: Record<string, any>,
  cb: () => void
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('confirmOrderLoaderAction');
      const response = await orderApiService.confirmAndSendOrder(
        orderId,
        orderDetails
      );
      if (response?.status === 200) {
        cb();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('confirmOrderLoaderAction');
    }
  };
};

export const receivedOredrAction = (orderId: string, history: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await orderApiService.receivedOrder(orderId);
      if (response?.status === 200) {
        history.push(ROUTE_CONSTANTS_VARIABLE.APPLY_FOR_CREDIT);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeOrderTableDataAction = (data: Record<string, any>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.ON_CHANGE_ORDER_TABLE_DATA,
      data,
    });
  };
};

export const uploadSignatureAction = (
  formData: any,
  config: Record<string, Record<string, string>>,
  orderId: string,
  history: RouteComponentProps['history']
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await orderApiService.uploadSignature(
        formData,
        config,
        orderId
      );
      if (response.status === 201) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.UPLOAD_SIGNATURE.replace,
          data: response.data.data,
        });
        successNotification('signature uploaded successfully');
        history.push(`/orders/completed/${orderId}`);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getAllProducts = (data: Record<string, string>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await orderApiService.getAllProduct(data);
      if (response?.status === 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.GET_ALL_PRODUCTS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeUnitAction = (
  selectedProduct: Record<string, any>,
  keyName: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.ON_CHANGE_UNIT,
      data: { selectedProduct, keyName },
    });
  };
};

export const createOrderQuantityChange = (data: Record<string, string>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.CREATE_ORDER_QUANTITY_CHANGE,
      data,
    });
  };
};

export const selectProduct = (product: Record<string, any>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.GET_SELECTED_PRODUCT,
      data: product,
    });
  };
};

export const getPantryList = (data: Record<string, string>) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await orderApiService.getPantryList(data);
      if (response?.status === 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.GET_PANTRY_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const editProductNote = (productId: string, productNote: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.EDIT_PRODUCT_NOTE,
      data: { productId, productNote },
    });
  };
};

export const onClickDoneAction = (orderId: string) => {
  return async () => {
    try {
      const response = await orderApiService.addOnDisable(orderId);
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportProductExcelsheet = (orderId: string) => {
  return async () => {
    try {
      const response = await orderApiService.downloadOrderExcelSheet(orderId);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportOrderPdf = (orderId: string) => {
  return async () => {
    try {
      const response = await orderApiService.downloadorderPdf(orderId);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const updateOrder = (
  orderId: string,
  params: Record<string, boolean>,
  data: Record<string, any>,
  cb: () => void
) => {
  return async () => {
    try {
      const response = await orderApiService.updateOrder(orderId, params, data);
      if (response.status === 200) {
        cb();
        successNotification(`Order Details Updated Successfully`)
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetAllProducts = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.RESET_ALL_PRODUCTS,
    });
  };
};

export const orderReorderAction = (
  orderId: string,
  history: RouteComponentProps['history']
) => {
  return async () => {
    try {
      const response = await orderApiService.orderReorder(orderId);
      if (response.status === 201) {
        history.push(`/orders/edit-order/edit/${response.data.data._id}`);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const orderReceivedValidationForCredit = (
  orderId: string,
  history: any
) => {
  return async () => {
    try {
      const response = await orderApiService.ToReceiveOrder(orderId);
      if (response.status === 200) {
        history.push(`/orders/completed/${orderId}/apply-for-credit`);
      }
    } catch (e) {
      displayErrors(e);
      // history.push(ROUTE_CONSTANTS_VARIABLE.ORDER_LIST);
    }
  };
};
