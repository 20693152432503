import { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import Input from '../../../../components/input/Input';
import { getUnits, updateUnits } from '../../../../store/actions/commonAction';
import Button from '../../../../components/button/Button';
import { Delete } from '@mui/icons-material';
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader';
import _ from 'lodash';
import { errorNotification } from '../../../../components/notifyHelper';
import { enterKeyHelper } from '../../../../helpers/enterKeyHelper';

interface UnitModalProps {
  setUnitModal: (val: boolean) => void;
}
const UnitModal: FC<UnitModalProps> = (props) => {
  const { setUnitModal } = props;
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<
    Record<string, string | undefined>
  >({
    value: '',
    error: '',
  });

  const { units } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );

  const [updatedUnits, setUpdatedUnits] =
    useState<Record<string, string>[]>(units);

  const { addUnitLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const onClickSave = () => {
    if (!_.isEqual(units, updatedUnits)) {
      dispatch(
        updateUnits({ units: updatedUnits }, () => {
          setUnitModal(false);
          dispatch(getUnits());
        })
      );
    } else {
      errorNotification('Please add atleast one unit');
    }
  };

  const unitButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setUnitModal(false);
        setInputValue({
          value: undefined,
          error: undefined,
        });
      },
      color: 'secondary',
    },
    {
      title: addUnitLoaderAction ? <ButtonLoader /> : 'Save',
      onClick: onClickSave,
      color: 'primary',
    },
  ];

  const onAddUnit = () => {
    if (inputValue.value && inputValue.value.toString().trim().length) {
      const duplicateRecord = updatedUnits.filter(
        (unit) =>
          unit.name.toString().trim().toLowerCase() ===
          inputValue?.value?.toString().trim().toLowerCase()
      );

      if (duplicateRecord?.length) {
        setInputValue({
          value: undefined,
          error: 'This unit already exist',
        });
      } else {
        setUpdatedUnits((prevValue) =>
          inputValue.value
            ? [
                ...prevValue,
                {
                  name:
                    inputValue.value.toString().trim() &&
                    inputValue.value.toString().trim(),
                },
              ]
            : [...prevValue]
        );
        setInputValue({ value: '', error: '' });
      }
    } else {
      setInputValue({
        value: undefined,
        error: 'Please enter valid unit name',
      });
    }
  };

  const deleteUnit = (index: number) => {
    const unitList = updatedUnits;
    unitList.splice(index, 1);

    setUpdatedUnits([...unitList]);
  };

  useEffect(() => {
    dispatch(getUnits());
  }, []);

  return (
    <CustomModal
      header="Add/Edit Units"
      buttons={unitButtons}
      className="unit-modal"
      bodyClassName="unit-modal-body"
      hideModal={() => {
        setUnitModal(false);
        setInputValue({
          value: undefined,
          error: undefined,
        });
      }}
    >
      <div className="form-field-name">Add Units</div>
      <div className="unit-input-button-container">
        <Input
          variant="filled"
          placeholder="Enter a New Unit"
          value={inputValue.value}
          onChange={(e) => setInputValue({ value: e.target.value })}
          onKeyUp={(event) => enterKeyHelper(event, onAddUnit)}
          helperText={inputValue?.error}
        />
        <Button
          color="primary"
          variant="contained"
          className="modal-button"
          onClick={onAddUnit}
        >
          Add
        </Button>
      </div>
      <div className="unit-chips-container">
        {updatedUnits?.length > 0 &&
          updatedUnits.map((unit: Record<string, string>, index: number) => (
            <div className="unit-chip" style={{ paddingRight: '16px' }}>
              <pre>{unit.name}</pre>
              {/* {!unit?.isDefault && <Delete onClick={() => deleteUnit(index)} />} */}
            </div>
          ))}
      </div>
    </CustomModal>
  );
};

export default UnitModal;
