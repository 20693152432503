import { Add, Delete } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { exportExcelIcon } from '../../../assets/images/action-items/exportExcelIcon';
import { exportPdfIcon } from '../../../assets/images/action-items/exportPdfIcon';
import { printerIcon } from '../../../assets/images/action-items/printer';
import { filterIcon } from '../../../assets/images/filter';
import Button from '../../../components/button/Button';
import CustomTable from '../../../components/customTable/CustomTable';
import CustomMenu from '../../../components/menu/Menu';
import companyLogo from '../../../assets/images/company-logo.svg';
import { format, parseISO } from 'date-fns';
import { audConverter } from '../../../constants/regexConstants';
import useMediaQuery from '../../../hooks/MediaQuery';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Icon,
  InputAdornment,
  InputLabel,
  Tooltip,
} from '@mui/material';
import CalenderIcon from '../../../assets/images/CalenderIcon';
import Input from '../../../components/input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { dateLableHelper } from '../../../helpers/dateLabelHelper';
import FilterModal from '../../../components/filterModal/FilterModal';
import {
  exportToExcelReconcileAction,
  exportToPdfReconcileAction,
  getInvoiceIdsAction,
  getReconcilestatementAction,
  getStatementsAction,
  getSupplierListaction,
  onAdjustmentNoteChange,
  onChangeInvoiceAcion,
  reconcileStatementAction,
  resetAdjustmentnoteDetailsAction,
} from '../../../store/actions/invoiceActions/reconcileAction';
import { useQueryParams } from '../../../hooks/getQueryParamHook';
import { useUrlParamsUpdate } from '../../../hooks/useUrlParamsUpdate';
import ReconcilePagination from '../../../components/pagination/ReconcilePagination';
import Loader from '../../../components/loader/Loader';
import { resetFilterHelper } from '../../../helpers/resetFilterHelper';
import { desktopResetFilter } from '../../../assets/images/desktopResetFilter';
import { mobileResetFilter } from '../../../assets/images/mobileResetFilter';
import { errorNotification } from '../../../components/notifyHelper';
import { adjustmentNoteValidation } from './adjustmentNoteValidation';
import {
  mobileReconcileInvoiceHeaders,
  reconcileIncoiceHeaders,
} from '../../../constants/invoiceconstants/reconcileConstants';
import _ from 'lodash';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';

const Reconcile = () => {
  const dispatch = useDispatch<any>();
  const cardRef = useRef<HTMLDivElement>(null);
  const calculationRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const noteRef = useRef<HTMLDivElement>(null);
  const [openAdjustmentNoteModal, setOpenAdjustmentNoteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [figureDate, setFigureDate] = useState('');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isMobile = useMediaQuery('(max-width: 620px)');

  const {
    reconcileStatementList,
    supplierList,
    statement,
    invoiceId,
    invoices,
    adjustmentNoteDetails,
  } = useSelector(
    ({ reconcileReducer }: Record<string, any>) => reconcileReducer ?? {}
  );

  const { reconcile } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const { reconciledStatementListLoaderAction } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const { docs, page, total } = useMemo(
    () => reconcileStatementList ?? {},
    [reconcileStatementList]
  );

  const data = docs?.[0];

  const { page: paramPage, limit: paramLimit } = useQueryParams();

  const { errors } = adjustmentNoteDetails || {};

  const onClickPrint = useReactToPrint({
    content: () => {
      const cards: Node | undefined = cardRef.current?.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header = `<div class='print-header'><img src="${companyLogo}" alt="foodygent" /><span>Foodygent</span></div>`;
      PrintElem.innerHTML = header;
      const bodyTitle: string = `<div class='print-body-title'>Statement Reconcilation</div>`;
      PrintElem.innerHTML += bodyTitle;
      cards && PrintElem.appendChild(cards);
      const table: Node | undefined = tableRef.current?.cloneNode(true);
      table && PrintElem.appendChild(table);
      const note: Node | undefined = noteRef.current?.cloneNode(true);
      note && PrintElem.appendChild(note);
      const total: Node | undefined = calculationRef.current?.cloneNode(true);
      total && PrintElem.appendChild(total);
      return PrintElem;
    },
    pageStyle: `@page { size: auto; margin: 0mm; } 
    @media print { 
      body { -webkit-print-color-adjust: exact; padding: 30px 40px !important; } 
    }`,
    removeAfterPrint: !isMobile,
  });

  const filters = {
    supplierId: reconcile?.supplierId?._id,
    startDate: reconcile?.startDate,
    endDate: reconcile?.endDate,
    page: paramPage ?? page ?? 1,
  };

  const exportToExcel = () => {
    dispatch(
      exportToExcelReconcileAction({
        ...filters,
      })
    );
  };

  const exportToPdf = () => {
    dispatch(
      exportToPdfReconcileAction({
        ...filters,
      })
    );
  };

  const actionItems = [
    {
      icon: exportExcelIcon,
      name: 'Export to Excel',
      onClick: exportToExcel,
    },
    {
      icon: exportPdfIcon,
      name: 'Export to PDF',
      onClick: exportToPdf,
    },
    {
      icon: printerIcon,
      name: 'Print',
      onClick: () => {
        if (data?.invoices?.length > 0) {
          onClickPrint();
        }
      },
    },
  ];

  const filterFields = [
    {
      name: 'supplierId',
      label: 'Supplier',
      placeholder: 'Select Supplier',
      type: 'autocomplete',
      options: supplierList,
    },
    {
      name: 'startDate',
      label: 'From',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
    {
      name: 'endDate',
      label: 'To',
      placeholder: 'Select Date',
      type: 'date',
      className: 'filter-field-gap',
    },
  ];

  const printCardValue = (type: string, currentData: any) => {
    switch (type) {
      case 'date':
        return currentData?.toString()?.trim()?.length
          ? format(parseISO(currentData), 'dd MMM yyyy')
          : '-';

      case 'amount':
        return currentData && currentData.toString().trim().length > 0
          ? audConverter(currentData)
          : audConverter(0);

      default:
        return currentData ?? '-';
    }
  };

  const onClickSave = () => {
    adjustmentNoteValidation(
      dispatch,
      adjustmentNoteDetails,
      errors,
      true,
      'onSubmit',
      undefined,
      data?.supplierDetails?._id,
      setOpenAdjustmentNoteModal
    );
  };

  const adjustmentNoteButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenAdjustmentNoteModal(false);
        dispatch(resetAdjustmentnoteDetailsAction());
      },
      color: 'secondary',
    },
    {
      title: 'Save',
      onClick: () => {
        onClickSave();
      },
      color: 'primary',
    },
  ];

  const handleKeyDown = (e: any) => {
    if (e.ctrlKey && e.which === 80) {
      e.preventDefault();
      onClickPrint();
      return false;
    }
  };

  const onClickofAdjustmentNote = () => {
    setOpenAdjustmentNoteModal(true);
    dispatch(getStatementsAction(data?.supplierDetails?._id));
    dispatch(getInvoiceIdsAction(data?.supplierDetails?._id));
  };

  const onPageChange = (page: any) => {
    dispatch(getReconcilestatementAction({ page }));
  };

  const onChangeCheckBox = (value: any) => {
    let newArr = [...checkedIds];
    const index = newArr.findIndex((e) => e === value);
    const reconciledInvoice = data?.invoices
      ?.filter((invoice: any) => invoice.isReconciled)
      .map((invoice: any) => invoice._id);
    if (reconciledInvoice?.includes(value)) {
      setCheckedIds(newArr);
    } else {
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(value);
      }
      dispatch(onChangeInvoiceAcion(newArr));
      setCheckedIds(newArr);
    }
  };

  const selectAllCheckBox = () => {
    const allIds = data?.invoices?.map(
      (data: Record<string, string | boolean | number>) => data._id
    );
    if (data?.invoices?.length !== 0) {
      if (checkedIds?.length === data?.invoices?.length) {
        const reconciledInvoice = data?.invoices
          ?.filter((invoice: any) => invoice.isReconciled)
          .map((invoice: any) => invoice._id);

        setCheckedIds(reconciledInvoice);
        dispatch(onChangeInvoiceAcion([]));
      } else {
        setCheckedIds(allIds);
        dispatch(onChangeInvoiceAcion(allIds));
      }
    }
  };

  const resetFilter = () => {
    resetFilterHelper(
      dispatch,
      getReconcilestatementAction,
      { page: 1 },
      'expense',
      () => setOpenFilterModal(false),
      [getSupplierListaction]
    );
  };

  const onClickReconcile = () => {
    const params = {
      page: paramPage ?? page ?? 1,
    };
    if (invoices.length > 0) {
      dispatch(
        reconcileStatementAction({ invoices }, data?.supplierDetails?._id)
      );
      dispatch(getReconcilestatementAction(params));
    } else errorNotification('Please select at least one invoice');
  };

  const onselectionchange = (name: string, value: Record<string, string>) => {
    dispatch(onAdjustmentNoteChange(name, value));
    if (isSubmitted) {
      adjustmentNoteValidation(
        dispatch,
        { ...adjustmentNoteDetails, [name]: value },
        errors,
        isSubmitted,
        'onBlur',
        name,
        data?.supplierDetails?._id,
        setOpenAdjustmentNoteModal
      );
    }
  };

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onAdjustmentNoteChange(name, value));

    if (isSubmitted) {
      adjustmentNoteValidation(
        dispatch,
        { ...adjustmentNoteDetails, [name]: value },
        errors,
        isSubmitted,
        'onBlur',
        name,
        data?.supplierDetails?._id,
        setOpenAdjustmentNoteModal
      );
    }
  };

  // for params in url
  useUrlParamsUpdate({
    page: page ?? paramPage ?? 1,
    supplierId: reconcile?.supplierId?.name,
    startDate: reconcile?.startDate
      ? new Date(reconcile?.startDate)?.toISOString()
      : undefined,
    endDate: reconcile?.endDate
      ? new Date(reconcile?.endDate)?.toISOString()
      : undefined,
  });

  useEffect(() => {
    const reconciledInvoice = data?.invoices?.map((invoice: any) => {
      if (invoice.isReconciled) return invoice._id;
    });
    setCheckedIds(reconciledInvoice);
  }, [data?.invoices]);

  useEffect(() => {
    const date = dateLableHelper(
      {
        startDate: reconcile?.startDate,
        endDate: reconcile?.endDate,
      },
      'expense',
      profileDetail.timeZoneOffSet
    );
    setFigureDate(date);
  }, [reconcile?.startDate, reconcile?.endDate]);

  useEffect(() => {
    dispatch(getReconcilestatementAction(filters));
    dispatch(getSupplierListaction());
    dispatch(resetfilterOnModuleChange('reconcile'));

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="list-page-container">
      <div className="page-header">
        <div className="page-title">Statement Reconcilation</div>
        <div className="button-container">
          {!_.isEmpty(reconcile) && (
            <Button
              className="list-icon-button desktop-button"
              variant="contained"
              color="primary"
              onClick={resetFilter}
            >
              {desktopResetFilter}
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {!_.isEmpty(reconcile) && (
            <div className="mobile-button-container">
              <Button
                className="list-icon-button"
                variant="outlined"
                color="primary"
                onClick={resetFilter}
              >
                {mobileResetFilter}
              </Button>
            </div>
          )}
          {data?.invoices?.length > 0 && (
            <Button
              variant="contained"
              className="desktop-button"
              color="primary"
              onClick={onClickofAdjustmentNote}
            >
              Add Adjustment Note
            </Button>
          )}
          {/* Mobile Buttons start here */}
          {data?.invoices?.length > 0 && (
            <div className="mobile-button-container">
              <Button
                className="form-icon-button"
                variant="outlined"
                color="primary"
                onClick={onClickofAdjustmentNote}
              >
                <Add />
              </Button>
            </div>
          )}
          {/* mobile button end here */}
          <Button
            className="list-icon-button"
            variant="outlined"
            color="primary"
            onClick={() => setOpenFilterModal(true)}
            isFilterApplied={!_.isEmpty(reconcile)}
            filterData={reconcile}
          >
            {filterIcon}
          </Button>
          <CustomMenu
            className="action-menu"
            menuTitle="Actions"
            menuIcon="more_vert"
            menuItems={actionItems}
            id="product-action"
          />
        </div>
      </div>
      <div className="reconcile-list-container">
        <div className="reconcile-white-block-row " ref={cardRef}>
          <div className="white-block">
            <div className="subtitle-text">Statement Reconcile For</div>
            <div className="main-text-primary">
              {data?.supplierDetails?.supplierName || ' - '}
            </div>
          </div>
          <div className="white-block">
            <div className={figureDate.length > 0 ? 'sales-date-lable' : ''}>
              {figureDate}
            </div>
            <div className="main-text">{`$${data?.statementTotal || 0}`}</div>
            <div className="subtitle-text">Statement Total</div>
          </div>
          <div className="white-block">
            <div className={figureDate.length > 0 ? 'sales-date-lable' : ''}>
              {figureDate}
            </div>
            <div className="main-text">{`$${
              data?.invoicePeriodTotal || 0
            }`}</div>
            <div className="subtitle-text">Invoice period total</div>
          </div>
        </div>
        {reconciledStatementListLoaderAction ? (
          <Loader />
        ) : (
          <>
            {docs?.length > 0 ? (
              <>
                <CustomTable
                  headers={reconcileIncoiceHeaders}
                  mobileHeaders={mobileReconcileInvoiceHeaders}
                  data={data?.invoices}
                  isCheckbox
                  selectAllCheckBox={selectAllCheckBox}
                  checkedIds={checkedIds}
                  onChangeCheckBox={onChangeCheckBox}
                  isPagination
                  listName="reconcile"
                />
                <div className="reconcile-total-container">
                  <div className="notes-container" ref={noteRef}>
                    {data.adjustmentNotes.map(
                      (note: Record<string, string>) => (
                        <div className="adjustment-detail-container view-details-white-container">
                          <div>
                            <div className="detail-label">Notes</div>
                            <div className="detail-value details-instruction-container">
                              {note?.note}
                            </div>
                          </div>
                          <div>
                            <div className="detail-label">Invoice Id</div>
                            <div className="detail-value">
                              {note.invoiceNumber || '-'}
                            </div>
                          </div>
                          <div>
                            <div className="detail-label">Amount</div>
                            <div className="detail-value">{`$${
                              note.amount || 0
                            }`}</div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="total-container" ref={calculationRef}>
                    <div className="sub-total-container">
                      <div>
                        <span>Invoice Total</span>
                        <span className="invoice-total-value">{`$${
                          data?.invoiceTotal || 0
                        }`}</span>
                      </div>
                      <div>
                        <span>Out of Balance</span>
                        <span className="invoice-total-value">{`$${
                          data?.outOfBalance || 0
                        }`}</span>
                      </div>
                      <div>
                        <span>Amount Reconciled</span>
                        <span className="invoice-total-value">{`$${
                          data?.amountReconciled || 0
                        }`}</span>
                      </div>
                    </div>
                    <div className="total-print-class">
                      <span>Total</span>
                      <span className="invoice-total-value">{`$${
                        data?.total || 0
                      }`}</span>
                    </div>
                  </div>
                </div>
                <div className="form-button-row button-margin">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onClickReconcile}
                  >
                    Reconcile
                  </Button>
                </div>
              </>
            ) : (
              <div className="no-record-text">No Record Found</div>
            )}
            <ReconcilePagination
              total={total}
              page={page}
              pages={total}
              pageActionClick={onPageChange}
              className="reconcile-pagination"
            />
          </>
        )}
      </div>
      {openAdjustmentNoteModal && (
        <CustomModal
          header="Adjustment note"
          buttons={adjustmentNoteButtons}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="default-gst-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setOpenAdjustmentNoteModal(false);
          }}
        >
          <div className="oreder-number-modal-body">
            <div className="default-gst-input">
              <div className="form-field-name">
                <div className="form-field-name">Statement</div>
              </div>
              <FormControl>
                <Autocomplete
                  key={Math.random()}
                  onChange={(e, value) =>
                    onselectionchange('statementId', value)
                  }
                  options={statement || []}
                  getOptionLabel={(option: any) => option?.name ?? option ?? ''}
                  value={adjustmentNoteDetails?.statementId}
                  className="autocomplete"
                  renderInput={(params) => (
                    <Input
                      variant="filled"
                      {...params}
                      placeholder={'Select Statement'}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {adjustmentNoteDetails?.errors?.statementId}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="default-gst-input">
              <div className="form-field-name">
                <div className="form-field-name">Invoice ID</div>
              </div>
              <FormControl>
                <Autocomplete
                  key={Math.random()}
                  onChange={(e, value) => onselectionchange('invoiceId', value)}
                  options={invoiceId || []}
                  getOptionLabel={(option: any) => option?.name ?? option ?? ''}
                  value={adjustmentNoteDetails?.invoiceId}
                  className="autocomplete"
                  renderInput={(params) => (
                    <Input
                      variant="filled"
                      {...params}
                      placeholder={'Select Invoice ID'}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText className="input-with-error">
                  {adjustmentNoteDetails?.errors?.invoiceId}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="default-gst-input">
              <InputLabel className="add-default-gst-label">Amount</InputLabel>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter amount"
                  className={`input-field`}
                  name="amount"
                  value={adjustmentNoteDetails?.amount}
                  type="text"
                  onChange={onHandleChangeInput}
                  helperText={adjustmentNoteDetails?.errors?.amount}
                />
              </FormControl>
            </div>
            <div>
              <InputLabel className="add-default-gst-label">Notes</InputLabel>
              <Input
                name="note"
                variant="filled"
                multiline
                placeholder="Notes"
                rows={2}
                value={adjustmentNoteDetails?.note}
                onChange={onHandleChangeInput}
                helperText={adjustmentNoteDetails?.errors?.note}
              />
            </div>
          </div>
        </CustomModal>
      )}

      {openFilterModal && (
        <FilterModal
          requestFrom="reconcile"
          closeModal={() => setOpenFilterModal(false)}
          updateListAction={getReconcilestatementAction}
          filterFields={filterFields}
          defaultParams={{
            page: 1,
          }}
          isDateFilters
          filterAction={[getSupplierListaction]}
        />
      )}

      {/* for printing table start here*/}
      <div className="print-table" ref={tableRef}>
        {data?.invoices?.length > 0 && (
          <div className="print-table-body">
            {data?.invoices?.map((data: Record<string, any>) => (
              <div className="view-details-white-container details-grid details-grid-two-columns">
                {reconcileIncoiceHeaders?.map((field) => (
                  <div>
                    <div className="detail-label">{field.label}</div>
                    <div className="detail-value">
                      {printCardValue(
                        field.type,
                        data[field.name] ?? data.statementDetails[field.name]
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* for printing table end here*/}
    </div>
  );
};

export default Reconcile;
