import { ORDERS_URLS } from './../../constants/urlConstants';
import ApiService from '../apiService/apiService';

interface OrderIdsType {
  orderIds: string;
}

export const orderApiService = {
  getOrderList: (params: Record<string, any>) =>
    ApiService.getData(ORDERS_URLS.ORDER_LIST, { params }),
  getSupplierList:()=>
    ApiService.getData(ORDERS_URLS.GET_SUPPLIER_LIST_FORORDER),  
  createOrder: (data: Record<string, any>) =>
    ApiService.postData(ORDERS_URLS.CREATE_ORDER, data),
  deleteOrders: (params: OrderIdsType) =>
    ApiService.deleteData(ORDERS_URLS.DELETE_ORDERS, { params }),
  viewOrderDetails: (orderId: string) =>
    ApiService.getData(`${ORDERS_URLS.VIEW_ORDER_DETAILS}${orderId}`),
  confirmAndSendOrder: (orderId: string, data: Record<string, any>) =>
    ApiService.putData(`${ORDERS_URLS.CONFIRM_AND_SEND_ORDER}${orderId}`, data),
  uploadSignature: (
    data: FormData,
    config: Record<string, Record<string, string>>,
    orderId: string
  ) =>
    ApiService.postData(
      `${ORDERS_URLS.UPLOAD_SIGNATURE}${orderId}`,
      data,
      config
    ),
  receivedOrder: (orderId: string) =>
    ApiService.getData(`${ORDERS_URLS.RECEIVED_ORDER}${orderId}`),
  getAllProduct: (params: Record<string, string>) =>
    ApiService.getData(ORDERS_URLS.GET_ALL_PRODUCT, { params }),
  getPantryList: (params: Record<string, string>) =>
    ApiService.getData(ORDERS_URLS.GET_PANTRY_LIST, { params }),
  addOnDisable: (orderId: string) =>
    ApiService.putData(`${ORDERS_URLS.ADD_ON_DISABLE}${orderId}`),
  downloadOrderExcelSheet: (orderId: string) =>
    ApiService.request({
      method: 'GET',
      url: `${ORDERS_URLS.EXPORT_TO_EXCEL}${orderId}/excel-download`,
      responseType: 'blob',
      params: {
        orderId,
      },
    }),
  downloadorderPdf: (orderId: string) =>
    ApiService.request({
      method: 'GET',
      url: `${ORDERS_URLS.EXPORT_TO_PDF}${orderId}/pdf-download`,
      responseType: 'blob',
      params: {
        orderId,
        userDate: new Date(),
      },
    }),
  updateOrder: (
    orderId: string,
    params: Record<string, boolean>,
    data: Record<string, any>
  ) =>
    ApiService.putData(`${ORDERS_URLS.UPDATE_ORDER}${orderId}`, data, {
      params,
    }),
  orderReorder: (orderId: string) =>
    ApiService.postData(`${ORDERS_URLS.ORDER_REORDER}${orderId}`),
  ToReceiveOrder: (orderId: string) =>
    ApiService.getData(`${ORDERS_URLS.ORDER_RECEIVE}${orderId}/order-recieve`),
};
