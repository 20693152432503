import _ from 'lodash';
import { FLOAT_NUMBER_REGEX } from '../../../../constants/regexConstants';
import { calculation } from '../../../../helpers/calculationHelper';
import {
  invoiceAcceptAction,
  mapperDatachange,
  onChangeUpdateStatusDataAction,
  resetUpdateStatusDataAction,
} from '../../../../store/actions/invoiceActions/invoiceActions';

export const inventoryProductValidation = async (
  dispatch: any,
  mapperData: Record<string, any>,
  updateStatus: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  history: any,
  invoiceId: string,
  productID?: string
) => {
  let validated = true;
  let errors: any = {};
  let orderErrors: any = updateStatus.errors ?? {};
  let errorMessages: Record<string, string> = {
    inventoryName: 'Select/Enter inventory product name',
    inventoryUOM: 'select inventory UOM ',
    weight: 'enter weight',
    orderId: 'select order Number',
    deliveredBy: 'enter delivered by',
  };

  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'inventoryName':
      case 'inventoryUOM':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;

      case 'weight':
        if (
          !value?.toString()?.trim()?.length ||
          !FLOAT_NUMBER_REGEX.test(value?.toString()?.trim())
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;

      case 'orderId':
      case 'deliveredBy':
        if (
          updateStatus?.orderId?.toString()?.trim()?.length ||
          updateStatus?.deliveredBy?.toString()?.trim()?.length
        ) {
          if (
            !updateStatus?.orderId?.toString()?.trim()?.length ||
            updateStatus?.orderId === undefined ||
            updateStatus?.orderId === null
          ) {
            validated = false;
            if (orderErrors) {
              orderErrors['orderId'] = `Please ${errorMessages['orderId']}`;
            }
            delete orderErrors['deliveredBy'];
          } else if (
            !updateStatus?.deliveredBy?.toString()?.trim()?.length ||
            updateStatus?.deliveredBy === undefined ||
            updateStatus?.deliveredBy === null
          ) {
            validated = false;
            if (orderErrors) {
              orderErrors[
                'deliveredBy'
              ] = `Please ${errorMessages['deliveredBy']}`;
            }
            delete orderErrors['orderId'];
          } else {
            delete orderErrors['orderId'];
            delete orderErrors['deliveredBy'];
          }
        }
    }
  };

  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      mapperData.forEach((element: Record<string, any>) => {
        for (const key in element) {
          const value = element[key];
          checkValidations(key, value);
        }

        dispatch(mapperDatachange(element.productId, 'errors', errors));
        errors = {};
      });

      for (const key in updateStatus) {
        const value = updateStatus[key];
        checkValidations(key, value);
      }

      dispatch(onChangeUpdateStatusDataAction('errors', orderErrors));
    } else if (productID) {
      const product = mapperData.find(
        (item: any) => item.productId === productID
      );

      for (const key in product) {
        const value = product[key];
        checkValidations(key, value);
      }
      dispatch(mapperDatachange(product.productId, 'errors', errors));
      errors = {};
    } else if (!productID && fieldName) {
      checkValidations(fieldName, updateStatus[fieldName]);
      dispatch(onChangeUpdateStatusDataAction('errors', orderErrors));
    }
  }

  if (validated && requestFrom === 'onSubmit') {
    const finalData = mapperData?.map((item: any) => {
      const name = item.inventoryName.name
        ? item.inventoryName.name
            .replace(/_/g, ' ')
            .replace(/\w\S*/g, function (txt: string) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
        : item.inventoryName
            .replace(/_/g, ' ')
            .replace(/\w\S*/g, function (txt: string) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });

      const weight = calculation('decimal', item.weight * (item.quantity ?? 1));

      return {
        name,
        inventoryId: item.inventoryName._id && item.inventoryName._id,
        unit: item.inventoryUOM,
        weight: weight && parseFloat(weight),
        productId: item.productId,
      };
    });

    let data: any = null;

    data =
      finalData.length > 0
        ? {
            ...data,
            internalInventoryProductBodyData: finalData,
          }
        : data;

    data =
      !_.isEmpty(updateStatus?.orderId) &&
      updateStatus?.deliveredBy?.toString()?.length > 0
        ? {
            ...data,
            orderId: updateStatus.orderId?._id,
            deliveredBy: updateStatus.deliveredBy,
          }
        : data;

    const success = await dispatch(
      invoiceAcceptAction(invoiceId, data, history)
    );
    if (success) {
      dispatch(resetUpdateStatusDataAction());
    }
  }
};
