export const ORDER_REDUX_CONSTANTS = {
  GET_ORDER_LIST: 'GET_ORDER_LIST',
  GET_SUPPLIER_LIST_FORORDER:'GET_SUPPLIER_LIST_FORORDER',
  CREATE_ORDER: 'CREATE_ORDER',
  CREATE_ORDER_ON_CHANGE_VALUE: 'CREATE_ORDER_ON_CHANGE_VALUE',
  ON_CHANGE_PRODUCT_QUANTITY: 'ON_CHANGE_PRODUCT_QUANTITY',
  ON_CHANGE_ORDER_TABLE_DATA: 'ON_CHANGE_ORDER_TABLE_DATA',
  DELETE_PRODUCT_FOR_DRAFT_ORDER: 'DELETE_PRODUCT_FOR_DRAFT_ORDER',
  GET_ORDER_DETAILS_BY_ID: 'GET_ORDER_DETAILS_BY_ID',
  RESET_ORDER_DETAILS: 'RESET_ORDER_DETAILS',
  UPLOAD_SIGNATURE: 'UPLOAD_SIGNATURE',
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  ON_CHANGE_UNIT: 'ON_CHANGE_UNIT',
  CREATE_ORDER_QUANTITY_CHANGE: 'CREATE_ORDER_QUANTITY_CHANGE',
  GET_SELECTED_PRODUCT: 'GET_SELECTED_PRODUCT',
  GET_PANTRY_LIST: 'GET_PANTRY_LIST',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  EDIT_PRODUCT_NOTE: 'EDIT_PRODUCT_NOTE',
  RESET_ALL_PRODUCTS: 'RESET_ALL_PRODUCTS',
};
