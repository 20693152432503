import { accountSettingsIcon } from '../assets/images/sidemenu/accountSettings';
import { dashboardIcon } from '../assets/images/sidemenu/dashboard';
import { invoicesIcon } from '../assets/images/sidemenu/invoices';
import { ordersIcon } from '../assets/images/sidemenu/orders';
import { planIcon } from '../assets/images/sidemenu/plan';
import { productsIcon } from '../assets/images/sidemenu/products';
import { recipeCostIcon } from '../assets/images/sidemenu/recipeCost';
import { reportsIcon } from '../assets/images/sidemenu/reports';
import { salesIcon } from '../assets/images/sidemenu/sales';
import { softwareIntegrationIcon } from '../assets/images/sidemenu/softwareIntegration';
import { subscriberIcon } from '../assets/images/sidemenu/subscriber';
import { suppliersIcon } from '../assets/images/sidemenu/suppliers';
import { supportIcon } from '../assets/images/sidemenu/supportIcon';
import { uploadIcon } from '../assets/images/sidemenu/uploadTAndCIcon';
import {
  ADMIN_ROUTE_CONST,
  ROUTE_CONSTANTS_VARIABLE,
} from './../routes/RouteConstants';

const SIDEBAR_MENU = {
  DASHBOARD: 'Dashboard',
  ORDERS: 'Orders',
  SUPPLIERS: 'Suppliers',
  INVOICES: 'Invoices',
  PRODUCTS: 'Product Inventory',
  RECIPE_COST: 'Recipe Cost',
  SALES: 'Sales',
  REPORTS: 'Reports',
  ACCOUNT_SETTINGS: 'Account Settings',
  SUPPORT: 'Support',
  SUBSCRIBERS: 'Subscribers',
  GST: 'GST(%)',
  PLAN: 'Plan',
  UPLOAD_TERMS_AND_CONDITION: 'Upload T & C',
  SOFTWARE_INTEGRATION: 'Software Integration',
  UPLOAD_INVOICE: 'Upload Options',
};

export const SIDEBAR_CONSTANTS = [
  {
    icon: dashboardIcon,
    name: 'dashboard',
    label: SIDEBAR_MENU.DASHBOARD,
    url: ROUTE_CONSTANTS_VARIABLE.DASHBOARD,
  },
  {
    icon: invoicesIcon,
    name: 'upload-options',
    label: SIDEBAR_MENU.UPLOAD_INVOICE,
    url: ROUTE_CONSTANTS_VARIABLE.UPLOAD_INVOICE,
  },
  {
    icon: ordersIcon,
    name: 'order',
    label: SIDEBAR_MENU.ORDERS,
    url: ROUTE_CONSTANTS_VARIABLE.ORDER,
  },
  {
    icon: suppliersIcon,
    name: 'supplier',
    label: SIDEBAR_MENU.SUPPLIERS,
    subMenu: [
      {
        name: 'supplier',
        label: SIDEBAR_MENU.SUPPLIERS,
        url: ROUTE_CONSTANTS_VARIABLE.SUPPLIER,
      },
      {
        name: 'product',
        label: 'Products',
        url: ROUTE_CONSTANTS_VARIABLE.PRODUCT,
      },
    ],
  },
  {
    icon: invoicesIcon,
    name: 'invoice',
    label: SIDEBAR_MENU.INVOICES,
    subMenu: [
      {
        name: 'invoice',
        label: SIDEBAR_MENU.INVOICES,
        url: ROUTE_CONSTANTS_VARIABLE.INVOICE,
      },
      {
        name: 'credit-note',
        label: 'Credit Notes',
        url: ROUTE_CONSTANTS_VARIABLE.CREDIT_NOTE,
      },
      {
        name: 'statement',
        label: 'Statements',
        url: ROUTE_CONSTANTS_VARIABLE.STATEMENT,
      },
      {
        name: 'reconcile',
        label: 'Reconcile',
        url: ROUTE_CONSTANTS_VARIABLE.RECONCILE,
      },
      {
        name: 'expense',
        label: 'Expenses',
        url: ROUTE_CONSTANTS_VARIABLE.EXPENSE,
      },
    ],
  },
  {
    icon: productsIcon,
    name: 'product-inventory',
    label: SIDEBAR_MENU.PRODUCTS,
    url: ROUTE_CONSTANTS_VARIABLE.PRODUCT_INVENTORY,
  },
  {
    icon: recipeCostIcon,
    name: 'recipe',
    label: SIDEBAR_MENU.RECIPE_COST,
    url: ROUTE_CONSTANTS_VARIABLE.RECIPE,
  },
  {
    icon: salesIcon,
    name: 'sale',
    label: SIDEBAR_MENU.SALES,
    url: ROUTE_CONSTANTS_VARIABLE.SALE,
  },
  {
    icon: reportsIcon,
    name: 'report',
    label: SIDEBAR_MENU.REPORTS,
    // url: ROUTE_CONSTANTS_VARIABLE.REPORTS,
    subMenu: [
      {
        name: 'reports',
        label: 'Reports',
        url: ROUTE_CONSTANTS_VARIABLE.REPORTS,
      },
      {
        name: 'reconciled-statements',
        label: 'Reconciled Statements',
        url: ROUTE_CONSTANTS_VARIABLE.RECONCILED_STATEMENTS,
      },
    ],
  },
  {
    icon: accountSettingsIcon,
    name: 'account-settings',
    label: SIDEBAR_MENU.ACCOUNT_SETTINGS,
    subMenu: [
      {
        name: 'billing-history',
        label: 'Billing History',
        url: ROUTE_CONSTANTS_VARIABLE.BILLING_HISTORY,
      },
      {
        name: 'billing-overview',
        label: 'Billing Overview',
        url: ROUTE_CONSTANTS_VARIABLE.BILLING_OVERVIEW,
      },
      {
        name: 'software-integration',
        label: 'Software Integration',
        url: ROUTE_CONSTANTS_VARIABLE.SOFTWARE_INTEGRATION,
      },
      {
        name: 'user',
        label: 'Users',
        url: ROUTE_CONSTANTS_VARIABLE.USER,
      },
    ],
  },
  {
    icon: supportIcon,
    name: 'support',
    label: SIDEBAR_MENU.SUPPORT,
    url: ROUTE_CONSTANTS_VARIABLE.SUPPORT,
  },
];

export const ADMIN_PANEL_CONSTANTS = [
  {
    icon: subscriberIcon,
    name: 'subscribers',
    label: SIDEBAR_MENU.SUBSCRIBERS,
    url: ADMIN_ROUTE_CONST.SUBSCRIBERS,
  },
  {
    icon: uploadIcon,
    name: 'uploadTAndC',
    label: SIDEBAR_MENU.UPLOAD_TERMS_AND_CONDITION,
    url: ADMIN_ROUTE_CONST.TERMS_AND_CONDITION,
  },
  {
    icon: planIcon,
    name: 'plan',
    label: SIDEBAR_MENU.PLAN,
    url: ADMIN_ROUTE_CONST.PLAN,
  },
  {
    icon: salesIcon,
    name: 'gst',
    label: SIDEBAR_MENU.GST,
    url: ADMIN_ROUTE_CONST.GST,
  },
  {
    icon: softwareIntegrationIcon,
    name: 'softwareIntegraion',
    label: SIDEBAR_MENU.SOFTWARE_INTEGRATION,
    url: ADMIN_ROUTE_CONST.SOFTWARE_INTEGRATION,
  },
];
