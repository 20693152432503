import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';
import { REGISTER_REDUX_CONSTANTS } from './../reduxConstants/registerReduxConstants';
interface InitialPlanProps {
  planList: Record<string, Record<string, string| number | string[]>>;
}

const inititalPlan: InitialPlanProps = {
  planList: {},
};

export const planReducer = (
  state = inititalPlan,
  action: { type: string; data: Record<string, any> }
) => {
  switch (action?.type) {
    case REGISTER_REDUX_CONSTANTS.PLAN.GET_PLANS:
      return {
        ...state,
        planList: action.data,
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...inititalPlan,
      };

    default:
      return state;
  }
};
