import { FormControl, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dummy from '../../../../src/assets/images/user-profile.svg';
import BackButton from '../../../components/backButton/BackButton';
import Button from '../../../components/button/Button';
import FileUpload from '../../../components/fileUpload/FileUpload';
import Input from '../../../components/input/Input';
import { errorNotification } from '../../../components/notifyHelper';
import { MOBILE_NUMBER_REGEX } from '../../../constants/regexConstants';
import { isEmail, requireValidate } from '../../../helpers/validationHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import {
  onChangeProfileDetail,
  updateProfileDetail,
  uploadProfilePicture,
} from '../../../store/actions/ProfileAction';
import { PROFILE_REDUX_CONSTANTS } from '../../../store/reduxConstants/profileReduxConstant';
import { editProfileValidation } from './editProfileValidation';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

export type profileDetailType = {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  profileURL?: string | null;
};

const EditProfile = (): JSX.Element => {
  const history = useHistory();
  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );
  const { updateProfileLoader } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const [fileName, setFileName] = useState('No photo attached');
  const [file, setFile] = useState<any>('');
  const dispatch = useDispatch();

  const { errors } = profileDetail || {};

  useEffect(() => {
    if (profileDetail?.profileURL) {
      setFileName('');
    }
  }, []);

  useEffect(() => {
    return () => {
      // to do change this - optimization
      if (
        history.location?.pathname !== '/profile/edit' &&
        profileDetail.profileURL !== profileDetail.tempProfileURL
      ) {
        dispatch(
          onChangeProfileDetail('profileURL', profileDetail.tempProfileURL)
        );
      }
    };
  }, [profileDetail.profileURL, profileDetail.tempProfileURL]);
  const cancelButtonClick = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE_DETAILS);
    dispatch({
      type: PROFILE_REDUX_CONSTANTS.PROFILE_PICTURE,
      data: null,
    });
  };

  const saveEditedProfile = async () => {
    setIsSubmittedOnce(true);
    editProfileValidation(
      dispatch,
      profileDetail,
      errors,
      true,
      'onSubmit',
      undefined,
      history
    );
  };

  const handleUpdateImage = async (croppedFile: any) => {
    if (file && croppedFile) {
      const formData = new FormData();
      formData.append('user-image', croppedFile);
      const config: any = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      await dispatch(uploadProfilePicture(formData, config));
      setFile('');
    }
  };

  const handleDpChange = async (event: any) => {
    event.persist();
    if (event?.target?.files && event.target.files.length > 0) {
      const fileExtension = ['jpeg', 'jpg', 'png'];
      const mimeType = ['image/jpeg', 'image/jpg', 'image/png'];

      const checkExtension =
        fileExtension.indexOf(
          event.target.files[0].name.split('.').splice(-1)[0]
        ) !== -1;
      const checkMimeTypes =
        mimeType.indexOf(event.target.files[0].type) !== -1;
      const checkFileSize = event.target.files[0].size > 4194304;

      if (!(checkExtension || checkMimeTypes)) {
        errorNotification('That file type is not accepted');
      } else if (checkFileSize) {
        errorNotification('File size should be less than 4 mb');
      } else {
        setFileName(
          event.target.files[0].name
            ? event.target.files[0].name
            : 'No photo attached'
        );
        const file = event.target.files[0];
        setFile(file);
      }
    }
  };

  const onHandleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChangeProfileDetail(name, value));
    editProfileValidation(
      dispatch,
      { ...profileDetail, [name]: value },
      errors,
      isSubmittedOnce,
      'onblur',
      name,
      history
    );
  };

  const removeFile = () => {
    setFile(undefined);
    setFileName('No photo attached');
    dispatch({
      type: PROFILE_REDUX_CONSTANTS.PROFILE_PICTURE,
      data: null,
    });
  };

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton
          onClick={() =>
            history.push(ROUTE_CONSTANTS_VARIABLE.VIEW_PROFILE_DETAILS)
          }
        />

        <span className="form-title">Edit Profile</span>
      </div>

      <div className="form-container">
        <FileUpload
          profilePictureUrl={
            profileDetail?.profileURL !== '' ? profileDetail.profileURL : dummy
          }
          fileName={fileName}
          file={file}
          handleChange={handleDpChange}
          handleUpdate={handleUpdateImage}
          removeFile={removeFile}
        />

        <div className="edit-profile-form">
          <div className="input-field-margin-bottom">
            <InputLabel className="field-name">First Name</InputLabel>
            <FormControl>
              <Input
                variant="filled"
                placeholder="First Name"
                className={`input-field ${
                  profileDetail?.errors?.firstName ? 'input-with-error' : ''
                }`}
                name="firstName"
                value={profileDetail?.firstName}
                type="text"
                helperText={profileDetail?.errors?.firstName}
                onChange={onHandleChangeInput}
                onKeyUp={(event) => enterKeyHelper(event, saveEditedProfile)}
              />
            </FormControl>
          </div>

          <div className="input-field-margin-bottom">
            <InputLabel className="field-name">Last Name</InputLabel>
            <FormControl>
              <Input
                variant="filled"
                placeholder="Last Name"
                className={`input-field ${
                  profileDetail?.errors?.lastName ? 'input-with-error' : ''
                }`}
                name="lastName"
                value={profileDetail.lastName}
                type="text"
                helperText={profileDetail?.errors?.lastName}
                onChange={onHandleChangeInput}
                onKeyUp={(event) => enterKeyHelper(event, saveEditedProfile)}
              />
            </FormControl>
          </div>

          <div className="input-field-margin-bottom">
            <InputLabel className="field-name">Email</InputLabel>
            <FormControl>
              <Input
                variant="filled"
                placeholder="Email"
                className={`input-field ${
                  profileDetail?.errors?.email ? 'input-with-error' : ''
                }`}
                name="email"
                value={profileDetail.email}
                type="email"
                helperText={profileDetail?.errors?.email}
                onKeyUp={(event) => enterKeyHelper(event, saveEditedProfile)}
                disabled
              />
            </FormControl>
          </div>

          <div className="input-field-margin-bottom">
            <InputLabel className="field-name">Contact Number</InputLabel>
            <FormControl>
              <Input
                variant="filled"
                placeholder="Contact number"
                className={`input-field ${
                  profileDetail?.errors?.contactNumber ? 'input-with-error' : ''
                }`}
                name="contactNumber"
                value={profileDetail.contactNumber}
                type="tel"
                helperText={profileDetail?.errors?.contactNumber}
                onChange={onHandleChangeInput}
                onKeyUp={(event) => enterKeyHelper(event, saveEditedProfile)}
              />
            </FormControl>
          </div>
        </div>

        <div className="form-button-row">
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelButtonClick}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveEditedProfile}
            loader={updateProfileLoader}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};
export default EditProfile;
