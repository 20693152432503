export const invoicesIcon = (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95688 9.46466H8.56213C8.87325 9.46466 9.12545 9.21246 9.12545 8.90134C9.12545 8.59021 8.87325 8.33801 8.56213 8.33801H4.95688C4.64575 8.33801 4.39355 8.59021 4.39355 8.90134C4.39355 9.21246 4.64581 9.46466 4.95688 9.46466Z"
      fill="#666666"
    />
    <path
      d="M11.9421 11.9996H4.95688C4.64575 11.9996 4.39355 12.2518 4.39355 12.563C4.39355 12.8741 4.64575 13.1263 4.95688 13.1263H11.9421C12.2532 13.1263 12.5054 12.8741 12.5054 12.563C12.5054 12.2518 12.2532 11.9996 11.9421 11.9996Z"
      fill="#666666"
    />
    <path
      d="M11.9421 15.6613H4.95688C4.64575 15.6613 4.39355 15.9135 4.39355 16.2246C4.39355 16.5357 4.64575 16.7879 4.95688 16.7879H11.9421C12.2532 16.7879 12.5054 16.5357 12.5054 16.2246C12.5054 15.9135 12.2532 15.6613 11.9421 15.6613Z"
      fill="#666666"
    />
    <path
      d="M19.998 19.8862V2.14144C20.0451 1.00686 19.1635 0.0489046 18.0289 0.00178561C17.9812 -0.000209126 17.9333 -0.000532597 17.8855 0.0008152H5.77401C4.53469 0.0008152 3.3799 0.902114 3.3799 2.14144V3.09908H2.39412C1.10541 3.12738 0.0589257 4.14923 0 5.43686V23.2097C0.0366601 23.5531 0.250367 23.8523 0.563325 23.9984C0.887282 24.1415 1.26558 24.0747 1.52096 23.8294L3.23908 22.2802L5.29522 24.111C5.4486 24.2533 5.64937 24.3336 5.85855 24.3364C6.0667 24.3286 6.2658 24.249 6.42187 24.111L8.44982 22.2802L10.4778 24.111C10.7985 24.3979 11.2836 24.3979 11.6044 24.111L13.6606 22.2802L15.3505 23.8294C15.5551 24.0812 15.9098 24.1521 16.1955 23.9984C16.4754 23.8377 16.6393 23.5317 16.618 23.2097V19.2101L16.956 18.9566L18.6741 20.5058C18.833 20.6573 19.0461 20.7386 19.2656 20.7311C19.3629 20.7399 19.4607 20.7203 19.5472 20.6748C19.8433 20.5274 20.0213 20.216 19.998 19.8862ZM15.4914 22.5901L14.1676 21.2944C13.8693 20.9895 13.3826 20.9771 13.069 21.2663L11.0129 23.0971L9.01309 21.2663C8.6838 21.0027 8.21574 21.0027 7.88644 21.2663L5.85849 23.0971L3.80235 21.2663C3.44713 20.9752 2.93254 20.9871 2.59122 21.2944L1.1266 22.5901V5.43691C1.18293 4.77121 1.72647 4.25182 2.39406 4.22578H14.5055C15.1252 4.22578 15.4914 4.81725 15.4914 5.43691V22.5901ZM18.8713 2.14144V19.2665L17.463 17.9708C17.2377 17.7455 17.1814 17.6892 16.6181 17.7737V5.43691C16.6181 4.19758 15.7449 3.09913 14.5056 3.09913H4.50655V2.14144C4.50655 1.52178 5.15435 1.12747 5.77401 1.12747H17.8855C18.3977 1.09415 18.84 1.48237 18.8734 1.99464C18.8766 2.04354 18.8759 2.09265 18.8713 2.14144Z"
      fill="#666666"
    />
  </svg>
);
