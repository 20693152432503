import { async } from '@firebase/util';
import { Dispatch } from 'react';
import { successNotification } from '../../components/notifyHelper';
import { downloadAll } from '../../helpers/downloadHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../routes/RouteConstants';
import { recipeApiServices } from '../../services/pageServices/recipeApiServices';
import { RECIPE_REDUX_CONSTANTS } from '../reduxConstants/recipeReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const addRecipe = (
  data: Record<string, string | string[] | number | Record<string, string>[]>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('addRecipeLoaderAction');
      const response = await recipeApiServices.addRecipe(data);
      if (response?.status === 201) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addRecipeLoaderAction');
    }
  };
};

export const onChangeRecipeData = (
  name: string,
  value: string | Record<string, string | undefined> | undefined | null | any
) => {
  return (dispatch: any) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.ON_CHANGE_RECIPE_DATA,
      data: {
        name,
        value,
      },
    });
  };
};

export const onChangeProductQuantity = (data: Record<string, string>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.ON_CHANGE_RECIPE_PRODUCT_QUANTITY,
      data,
    });
  };
};
export const deleteProductFromRecipe = (productId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.DELETE_PRODUCT_FOR_RECIPE,
      data: { productId },
    });
  };
};

export const uploadRecipeCoverPicture = (
  data: FormData,
  config: Record<string, Record<string, string>>
) => {
  return async (dispatch: any) => {
    try {
      const response = await recipeApiServices.uploadRecipePhoto(data, config);
      if (response?.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.UPLOAD_RECIPE_PHOTO,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

// const optionalPhotosURL: string[] = [];
export const uploadOptionalPhotos = (
  data: FormData,
  config: Record<string, Record<string, string>>
) => {
  return async (dispatch: any) => {
    try {
      const response = await recipeApiServices.uploadRecipePhoto(data, config);
      // optionalPhotosURL.push(response.data.data);

      if (response?.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.OPTIONAL_RECIPE_PHOTOS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetRecipeData = () => {
  return (dispatch: any) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.RESET_RECIPE_DATA,
    });
  };
};

export const getSelectedRecipeDetails = (recipeId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      startGeneralLoaderOnRequest('getSelectedRecipeDetailLoaderAction');
      const response = await recipeApiServices.getRecipeDetailsById(recipeId);
      if (response?.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.GET_SELECTED_RECIPE_DETAIL,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getSelectedRecipeDetailLoaderAction');
    }
  };
};

export const updateSelectedRecipeDetails = (
  data: Record<string, string | number | undefined>,
  recipeId: string,
  cb: () => void
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('updateRecipeDetailsLoaderAction');
      const response = await recipeApiServices.updateProductDetailsById(
        data,
        recipeId
      );
      if (response.status === 200) {
        successNotification(`${data?.name}'s details updated successfully`);
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateRecipeDetailsLoaderAction');
    }
  };
};

export const deleteRecipeAction = (id: string, cb: () => void) => {
  return async (dispatch: any) => {
    try {
      const params = {
        recipeId: id,
      };
      const response = await recipeApiServices.deleteRecipe(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateRecipeDetailsLoaderAction');
    }
  };
};

export const getRecipeListAction = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('recipeListLoaderAction');
      const response = await recipeApiServices.recipeList(params);
      if (response?.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.RECIPE_LIST,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('recipeListLoaderAction');
    }
  };
};

export const exportRecipeExcelsheet = (params: Record<string, string>) => {
  return async () => {
    try {
      const response = await recipeApiServices.downloadRecipeExcelSheet(params);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportRecipePdf = (params: Record<string, any>) => {
  return async () => {
    try {
      const response = await recipeApiServices.downloadRecipePdf(params);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const productDetailsAction = (product: any) => {
  return (dispatch: any) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.PRODUCT_DETAILS,
      data: product,
    });
  };
};

export const exportSingleRecipeExcelsheet = (recipeId: string) => {
  return async () => {
    try {
      const response = await recipeApiServices.downloadSingleRecipeExcelSheet(
        recipeId
      );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportSingleRecipePdf = (recipeId: string) => {
  return async () => {
    try {
      const response = await recipeApiServices.downloadSingleRecipePdf(
        recipeId
      );
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getRecipeCategoryList = () => {
  return async (dispatch: any) => {
    try {
      const response = await recipeApiServices.getRecipeCategoryList();
      if (response.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.GET_RECIPE_CATEGORY_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onAddCategoryAction = (
  data: Record<string, Record<string, string> | string>
) => {
  return (dispatch: any) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.ON_ADD_RECIPE_CATEGORY,
      data,
    });
  };
};

export const updateRecipeCategoryAction = (
  data: Record<string, any>,
  history: any
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('updateRecipeCategoryLoaderAction');
      const response = await recipeApiServices.updateRecipeCategory(data);
      if (response.status === 200) {
        successNotification(response.data.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.RECIPE);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateRecipeCategoryLoaderAction');
    }
  };
};

export const deleteCategoryAction = (data: Record<string, string>) => {
  return (dispatch: any) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.ON_DELETE_CATEGORY,
      data,
    });
  };
};

export const getRecipeCategoryDropdown = () => {
  return async (dispatch: any) => {
    try {
      const response = await recipeApiServices.getRecipeCategoryDropdown();
      if (response.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.RECIPE_CATEGORY_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getRecipeProductDropdown = () => {
  return async (dispatch: any) => {
    try {
      const response = await recipeApiServices.getProductRecipeList();
      if (response.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.RECIPE_PRODUCT_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getPreviousRecipeList = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      const response = await recipeApiServices.getPreviousRecipeDropdown(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: RECIPE_REDUX_CONSTANTS.PREVIOUS_RECIPE_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onRecipeProductPriceChange = (
  data: Record<string, string | number>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.ON_CHANGE_TABLE_DATA,
      data,
    });
  };
};

export const recipeCalculationAction = (
  data: Record<string, number | string>
) => {
  return (dispatch: any) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.RECIPE_CALCULATION,
      data,
    });
  };
};

export const deletePreviouseRecipe = (recipeId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: RECIPE_REDUX_CONSTANTS.DELETE_PREVIOUS_RECIPE,
      data: recipeId,
    });
  };
};

export const saveAsDraftAction = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await recipeApiServices.saveAsDraft(data);
      if (response.status === 201) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const updateDtaftRecipeAction = (data: Record<string, any>, cb: any) => {
  return async () => {
    try {
      const response = await recipeApiServices.updateDraftRecipe(data);
      if (response.status === 200) {
        successNotification(response.data.message);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
