import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { ROUTE_CONSTANTS } from './RouteConstants';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { NonAuthenticatedRoute } from './NonAuthenticatedRoute';
import { AUTH_REDUX_CONSTANTS } from '../store/reduxConstants/authReduxConstants';
import { getAuthTokenFromLocalStorage } from '../helpers/localstoragehelper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Layout from '../components/layout/Layout';
import { urlManagementHelper } from '../helpers/urlManagementHelper';
import { initialReducerProps } from '../store/reducers/authReducer';

const Routes = () => {
  const dispatch = useDispatch();

  const { accessModuleList } = useSelector(
    ({ authReducer }: Record<string, initialReducerProps>) => authReducer ?? {}
  );

  const routes = urlManagementHelper(accessModuleList, ROUTE_CONSTANTS);

  useEffect(() => {
    const cb = () => {
      const isToken = getAuthTokenFromLocalStorage();
      if (!isToken) {
        dispatch({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
          status: false,
        });
      }
    };

    window.addEventListener('storage', cb);

    return () => {
      window.removeEventListener('storage', cb);
    };
  }, []);

  return (
    <Router>
      <Layout>
        <Switch>
          {routes.map(
            ({ path, component, authenticated, isEscapeRedirect }, index) => {
              const Component: React.ComponentType<any> = authenticated
                ? AuthenticatedRoute
                : NonAuthenticatedRoute;

              return (
                <Component
                  key={`${path}-${index}`}
                  exact
                  path={path}
                  component={component}
                  isEscapeRedirect={isEscapeRedirect}
                />
              );
            }
          )}
          <Redirect to={'/'} />
        </Switch>
      </Layout>
    </Router>
  );
};
export default Routes;
