export const DASHBOARD_REDUX_CONSTANT = {
  GET_PENDING_INVOICES: 'GET_PENDING_INVOICES',
  GET_FOOD_AND_BEVERAGES_DETAILS: 'GET_FOOD_AND_BEVERAGES_DETAILS',
  GET_FOOD_AND_BEVERAGES_CHART: 'GET_FOOD_AND_BEVERAGES_CHART',
  GET_TOP_TEN_ORDERS: 'GET_TOP_TEN_ORDERS',
  GET_TOP_TEN_SUPPLIERS: 'GET_TOP_TEN_SUPPLIERS',
  GET_PRICE_CHANGES_DETAILS: 'GET_PRICE_CHANGES_DETAILS',
  GET_SALE_FOOD_AND_BEVERAGE_CHART: 'GET_SALE_FOOD_AND_BEVERAGE_CHART',
  GET_SALE_FOOD_AND_BEVERAGE_DETAIL: 'GET_SALE_FOOD_AND_BEVERAGE_DETAIL',
  GET_SALE_SPEND_PER_HEAD_DETAIL: 'GET_SALE_SPEND_PER_HEAD_DETAIL',
  GET_DAILY_SALE_DETAIL: 'GET_DAILY_SALE_DETAIL',
};
