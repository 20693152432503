import dayjs from 'dayjs';
import { FLOAT_NUMBER_REGEX } from '../../../constants/regexConstants';
import {
  addAdjustmentNoteAction,
  getReconcilestatementAction,
  onAdjustmentNoteChange,
  resetAdjustmentnoteDetailsAction,
} from '../../../store/actions/invoiceActions/reconcileAction';

export const adjustmentNoteValidation = async (
  dispatch: any,
  adjustmentNoteDetails: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  supplierId: string,
  setOpenAdjustmentNoteModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    statementId: 'select statement',
    invoiceId: 'select invoice',
    amount: 'enter amount',
    note: 'enter adjustment note',
  };

  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'statementId':
      case 'invoiceId':
      case 'note':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
      case 'amount':
        if (
          !value?.toString()?.trim()?.length ||
          !FLOAT_NUMBER_REGEX.test(value?.toString()?.trim())
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
    }
  };

  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in adjustmentNoteDetails) {
        const value = adjustmentNoteDetails[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, adjustmentNoteDetails[fieldName]);
    }
    dispatch(onAdjustmentNoteChange('errors', errors));
  }

  if (validated && requestFrom === 'onSubmit') {
    const finalData = adjustmentNoteDetails;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'statementId':
          case 'invoiceId':
            finalData[key] = finalData[key]._id;
            break;

          case 'amount':
            finalData['amount'] = parseFloat(finalData['amount']);
            break;

          default:
            finalData[key] = finalData[key].toString().trim();
            break;
        }
      }
    }
    delete adjustmentNoteDetails?.errors;
    const success = await dispatch(
      addAdjustmentNoteAction(
        { ...finalData, date: dayjs().format('YYYY-MM-DD') },
        supplierId
      )
    );

    if (success) {
      setOpenAdjustmentNoteModal(false);
      dispatch(getReconcilestatementAction({ page: '1' }));
      dispatch(resetAdjustmentnoteDetailsAction());
    }
  }
};
